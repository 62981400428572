.channel-tag-injection {
  &__header {
    display: flex;
    align-items: center;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 0 10px;
  }

  &__sub-title {
    font-size: 14px;
    color: rgba($color-black, 0.56);
    margin: 0 0 0 10px;
  }

  &__editor {
    display: flex;
    flex-direction: column;
  }

  &__zero {
    padding: 20px;
    color: rgba(0, 0, 0, 0.3);
  }

  &__select-wrapper {
    max-width: 200px;
  }

  &__least-fill {
    display: flex;
    align-items: center;
    margin: 0 0 8px;
  }

  &__least-fill-select-container {
    display: flex;
    flex: 1;
  }

  &__least-fill-select-wrapper {
    width: 50%;
    margin: 0 8px 0 0;
  }

  &__least-fill-remove {
    margin: 0 8px;
  }

  &__least-fill-add {
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    span {
      font-size: 14px;
    }
  }
}
