.resource-summary {
  display: flex;
  align-items: flex-start;

  &__category-img {
    max-width: 30px;
    margin: 0 10px 0 0;
  }

  &__info {
    flex-grow: 1;
  }

  &__name {
    font-size: 14px;
    line-height: 1.2;
    margin: 0;
  }

  &__category {
    font-size: 12px;
    color: rgba($color-black, 0.36);
  }

  &__action {
    margin: 0 0 0 5px;
  }
}
