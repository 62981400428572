.sp-footer {
  align-items: center;
  background: #404c52;
  color: $color-white;
  display: flex;
  justify-content: center;
  padding: 40px 0;

  > div {
    padding: 0 10px;
  }

  .link {
    color: $color-white;
    margin: 0 10px;
  }

  .gi {
    color: $color-orange-neon-carrot;
    font-size: 24px;
    height: 24px;
    margin: 0 10px;
  }

  .copyright {
    margin: 10px 0;
    opacity: 0.5;
  }

  @media all and (max-width: 620px) {
    flex-direction: column;
    padding: 10px 0;
  }
}
