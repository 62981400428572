.filter {
  display: block;

  &__title {
    font-weight: 500;
    font-size: 13px;
  }

  &__text {
    font-size: 13px;
    color: rgba($color-black, 0.4);
  }

  &__mood {
    display: inline-block;
    width: 24px;
    height: 24px;
    font-size: 24px;
    line-height: 1;
    text-align: center;
    margin: 0 5px 0 -4px;
  }

  &__actions {
    float: right;
  }

  &__action {
    margin-left: 4px;
  }
}
