.projects {
  &__head {
    display: flex;
    flex-direction: column;
    margin: 0 0 32px;

    &-upper {
      display: flex;
      align-items: center;
    }
  }

  &__title {
    flex-grow: 1;
  }

  &__action {
    margin: 0 0 0 8px;
  }

  &__sub-title {
    font-size: 16px;
  }

  &__content {
    display: flex;
    align-items: flex-start;

    &-side {
      flex-basis: 290px;
      margin: 0 0 0 30px;
      flex-shrink: 0;
    }

    &-main {
      flex-grow: 1;
    }

    @media all and (max-width: 960px) {
      &-side {
        display: none;
      }
    }
  }

  &__archive-head {
    display: flex;
    align-items: center;
    margin: 20px 8px;
  }

  &__archive-title {
    margin: 0;
    line-height: 1;
  }

  &__archive-toggle {
    margin: 0 0 0 20px;
    font-size: 14px;
  }

  &__estimate-cta {
    display: flex;
    align-items: center;
    background-color: $color-white;
    padding: 15px 25px;
    margin: 20px auto;
    border-radius: 4px;
    max-width: 570px;

    g-btn {
      margin: 0 0 0 5px;
    }
  }

  &__estimate-message {
    flex-grow: 1;
    font-size: 14px;
  }

  &__public {
    margin: 30px 0 0;

    &-text {
      font-size: 16px;
      text-align: center;
      margin: 0 0 10px;
    }

    &-actions {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-action {
      margin: 0 4px;
    }
  }

  &__zero {
    display: block;
    margin: 100px auto 50px;
    max-width: 380px;

    &-actions {
      margin: 30px 0 0 0;
      text-align: center;
    }
  }

  &__zero-register {
    margin-top: 30px;
  }

  @media all and (max-width: 490px) {
    &__estimate-cta {
      flex-direction: column;

      g-btn {
        margin: 10px 0 0;
      }
    }
  }
}
