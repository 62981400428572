.login {
  max-width: 850px;
  width: 100%;
  padding: 20px;
  margin: 0 auto 30px;

  &__header {
    display: flex;
    align-items: flex-end;
    margin: 25px 0;
  }

  &__logo {
    flex-grow: 1;
    max-width: 130px;
    height: auto;
  }

  &__signup-cta {
    font-size: 14px;
    padding: 5px 0;
    text-align: right;
  }

  &__box {
    box-shadow: 0 2px 10px rgba($color-black, 0.2);
    border-radius: 4px;
  }

  &__form {
    display: block;
    max-width: 400px;
  }

  &__overview {
    border-top: 3px solid $color-orange-blaze;
    padding: 20px 0 36px;
  }
}
