.omni-importer {
  &__crumbs {
    margin: 0 0 16px;
    display: flex;
    align-items: center;
  }

  &__crumb {
    font-weight: 500;
    padding: 0 8px;
    cursor: pointer;

    &:hover {
      color: $color-blue-clear;
    }
  }

  &__bar {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    background: white;
    border-radius: 4px;
    padding: 16px;
  }

  &__bar-field {
    flex: 1;
  }

  &__bar-btn {
    margin-left: 10px;
  }

  &__subscriber {
    background: white;
    padding: 16px;
    cursor: pointer;
    margin: 0 0 4px;
    border-radius: 4px;
  }

  &__grid {
    margin: 0 -8px;
  }

  &__grid-item {
    display: inline-block;
    vertical-align: top;
    padding: 0 8px 16px;
    width: 25%;
  }
}
