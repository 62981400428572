// override .gl-sidenav styles
.gl-sidenav {
  z-index: $z-index-header - 1;
}

.survey-filters {
  display: flex;
  margin: 12px 0;
}

.survey-date-filters {
  margin-left: auto;
  position: relative;
}

// Remove silly chrome focus
:focus {
  outline: none;
}
