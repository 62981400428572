// Page tabs
// ------------------------------------
.tabs-nav {
  display: flex;
  padding: 15px 0 0;
}

.tabs-nav-tab {
  border: 1px solid transparent;
  color: $base-font-color;
  display: inline-block;
  font-size: em(18);
  line-height: 24px;
  margin-bottom: -1px;
  padding: 8px 16px;
  position: relative;

  &:hover {
    color: $color-blue-clear;
  }

  &.active {
    background-color: $color-white;
    border: 1px solid $color-grey-soft;
    border-bottom-color: $color-white;
    border-radius: 2px 2px 0 0;
    color: $base-font-color;

    &:hover {
      color: $base-font-color;
      cursor: default;
    }
  }

  .nav-icon {
    margin-right: 5px;
    vertical-align: middle;
  }
}
