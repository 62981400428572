.response-delete-dialog {
  &__dialog {
    max-width: 420px;
  }

  &__content {
    padding: 0 24px 24px;
  }

  &__error {
    font-size: 12px;
    line-height: 1.3;
    color: $color-red-candy;
    margin: 0 0 20px;
  }
}
