.panel-target-preset-editor {
  padding: 24px;

  &__dialog {
    max-width: 400px;
  }

  &__btns {
    display: flex;
    justify-content: flex-end;
    margin: 40px 0 0;
  }

  &__btn {
    margin: 0 8px 0 0;
  }
}
