.publication-card {
  display: block;

  &__front {
    padding: 5px;
  }

  &__image-box {
    position: relative;
    padding-bottom: 75%; // enforce 4:3 ratio
    background: rgba($color-black, 0.01);
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // border-radius: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    // IMPORTANT:
    // - publication images are ALWAYS displayed in a 4:3 ratio box.
    // - There are no restrictions on image upload size, but 800 x 600 or 4:3 is recommended.
    // - If users want text to be visible in every situation (and not cropped) you MUST use the ratio
    // - Non-ratio images will scale up to fill the 4:3 size box, to help aesthetics.
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__no-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba($color-black, 0.1);
  }

  &__name {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3;
    padding: 0 15px;
    margin: 15px 0 5px;
    text-align: center;
    height: 60px;
    color: rgba($color-black, 0.8);
    // line clamp
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__type {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__type-text {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
    margin: 0 0 0 10px;
  }

  &__logo {
    max-height: 18px;
    max-width: 75px;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 15px 5px;
    border-top: 1px solid rgba($color-black, 0.05);
    margin: 10px -5px 0;
  }

  &__rating {
    padding: 0 15px;
    text-align: center;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
    min-height: 26px;
  }

  &__rating-cta {
    margin-top: 4px;
    background: $color-white;
    border-radius: 4px;
    padding: 5px;
  }

  &__rating-info {
    text-align: center;
    font-size: 11px;
  }
}
