.publisher-newsletter {
  &__text {
    font-size: 12px;
    line-height: 1.2;
    margin: 0 0 10px;
  }

  &__form {
    display: flex;
  }

  &__input {
    flex-grow: 1;
  }

  &__btn {
    margin: 0 0 0 5px;
  }

  &__joined {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    border: 1px solid rgba($color-black, 0.1);
    border-radius: 4px;
    font-size: 12px;
  }
}
