.g-form-footer {
  display: flex;
  align-items: center;
  padding: 10px 24px;
  border-top: 1px solid rgba($color-black, 0.05);

  &__progress {
    display: flex;
  }

  &__step {
    border-radius: 50%;
    height: 20px;
    width: 20px;
    margin: 0 5px 0 0;

    &.-full {
      background-color: $color-orange-blaze;
    }

    &.-empty {
      border: 2px solid rgba($color-black, 0.2);
    }
  }

  &__actions {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }

  &__spinner {
    height: 50px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__actions-buttons {
    display: flex;
  }

  &__back,
  &__next {
    border-radius: 4px;
    font-size: 14px;
    text-transform: uppercase;
    height: 50px;
    width: 120px;
    line-height: 50px;
    text-align: center;
    cursor: pointer;

    &[disabled] {
      cursor: default;
      pointer-events: none;
    }
  }

  &__back {
    &[disabled] {
      color: rgba($color-black, 0.2);
    }
  }

  &__next {
    background: $color-orange-blaze;
    color: $color-white;
    margin: 0 0 0 5px;

    &[disabled] {
      background: rgba($color-black, 0.2);
    }
  }
}
