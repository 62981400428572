.g-tag {
  display: inline-flex;
  align-items: center;
  margin: 0 3px 3px 0;
  height: 26px;
  border-radius: 4px;
  background: rgba($color-black, 0.05);
  padding: 0 10px;

  &__label {
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
  }

  &__remove {
    margin: 2px 0 0 5px;
    line-height: 0;
  }
}
