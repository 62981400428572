.restricted-analysis {
  &__title {
    font-size: 18px;
    font-weight: 500;
  }

  &__message {
    font-size: 14px;
    font-weight: 300;
    color: rgba($color-black, 0.4);
  }
}
