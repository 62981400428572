.origin-viewer {
  &__dialog {
    max-width: 400px;
  }

  &__content {
    padding: 20px;
  }

  &__item {
    margin: 0 0 20px;
  }
}
