.status-bar {
  background: $color-orange-blaze;
  color: $color-white;
  text-align: center;
  padding: 4px;
  font-size: 12px;

  a {
    font-weight: 500;
    color: white;
  }
}
