.signup-form {
  padding: 30px;

  &__field {
    display: block;
    margin: 0 0 10px;
  }

  &__label {
    font-size: 14px;
    font-weight: 300;
  }

  &__input {
    border: 1px solid rgba($color-black, 0.1);
    border-radius: 4px;
    background: 0;
    outline: 0;
    box-shadow: 0;
    height: 40px;
    // max-width: 300px;
    width: 100%;
    padding: 0 10px;
    transition: border-color 0.15s ease-out;

    &:hover,
    &:focus {
      border-color: rgba($color-black, 0.26);
    }
  }

  &__sidebyside {
    display: flex;
    align-items: flex-start;
    margin: -24px -5px -24px;

    > div {
      padding: 0 5px;
      flex-basis: 50%;
    }
  }

  &__inline-error {
    margin-top: -21px;
  }

  &__invalid-email {
    margin-top: -21px;
    font-size: 14px;
    font-style: italic;
  }

  &__errors {
    margin: 0 0 20px;
  }
}
