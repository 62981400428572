$offset: 187;
$duration: 1.4s;

%base-spinner {
  animation: rotator $duration linear infinite;

  .gl-spinner-path {
    animation: dash $duration ease-in-out infinite;
    stroke: $color-grey-silver;
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;

    &.primary {
      stroke: $color-green-seafoam;
    }

    &.secondary {
      stroke: $color-blue-clear;
    }

    &.background {
      stroke: lighten($color-grey-severe, 15);
    }

    &.white {
      stroke: $color-white;
    }
  }

  @keyframes rotator {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: $offset;
    }

    50% {
      stroke-dashoffset: $offset / 4;
      transform: rotate(135deg);
    }

    100% {
      stroke-dashoffset: $offset;
      transform: rotate(450deg);
    }
  }
}

.gl-spinner {
  @extend %base-spinner;
  height: 30px;
  width: 30px;
}

gl-spinner {
  line-height: 1;
}
