// sass-lint:disable-all
// important needs to be used to overide the inline styles set by the wordcloud d3 directive
wordcloud {
  text {
    &:hover {
      cursor: pointer;
      fill: lighten($color-orange-neon-carrot, 10) !important;
      opacity: 0.8;
    }

    &.active {
      fill: $color-orange-neon-carrot !important;
    }
  }
}
