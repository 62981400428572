.survey-templates {
  &__survey {
    cursor: pointer;
  }

  &__survey-content {
    margin: 0 auto 0 0;
    width: 400px;
  }

  &__survey-questions {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 1.6rem;
    margin-left: 24px;
  }

  &__survey-question-count {
    margin: 0 5px 0 0;
  }
}
