.account-purchase {
  &__link {
    color: $color-orange-blaze;

    &:hover,
    &:active,
    &:focus {
      color: lighten($color-orange-blaze, 5%);
    }
  }

  &__dialog {
    max-width: 400px;
  }

  &__content {
    padding: 1px 25px 25px;
  }

  &__spinner {
    padding: 25px;
  }

  &__billing-info {
    font-size: 14px;
    background: rgba($color-black, 0.05);
    border-radius: 4px;
    padding: 10px;
  }

  &__message {
    text-align: center;
    margin: 20px 0;
  }

  &__sidebyside {
    display: flex;
    align-items: flex-start;
    margin: -24px -5px -24px;

    > div {
      padding: 0 5px;
      flex-basis: 50%;
    }
  }

  &__section {
    font-size: 17px;
    font-weight: 500;
    margin: 30px 0 20px;
    line-height: 1;
  }

  &__features {
    font-size: 16px;
    margin: 20px 0;
    padding: 0 18px;

    > li {
      list-style-type: disc;
      color: $color-orange-blaze;
    }

    span {
      color: rgba($color-black, 0.7);
    }
  }

  &__summary {
    margin: 20px 0;
    font-size: 14px;
    line-height: 1.4;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 0 0 0;
  }

  &__action {
    margin: 0 10px 0 0;
  }
}
