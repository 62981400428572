.report-link-list {
  &__dialog {
    max-width: 530px;
    border: 0;
  }

  &__content {
    padding: 20px;

    &.-block {
      height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__message {
    // padding: 40px 0;
    // text-align: center;
    font-size: 14px;
    color: rgba($color-black, 0.4);
  }

  &__item {
    display: flex;
    align-items: center;
    border: 1px solid rgba($color-black, 0.1);
    border-radius: 4px;
    padding: 16px;
    margin: 0 0 16px;

    &-content {
      flex: 1;
      min-width: 0;
      margin-right: 16px;
    }

    &-title {
      display: flex;
      align-items: center;

      g-icon {
        margin-left: -3px;
      }

      span {
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
      }
    }

    &-link {
      font-size: 14px;
      font-weight: 600;
      color: $color-blue-clear;
      line-height: 1;

      span {
        @extend %ellipsis;
        display: block;
      }
    }

    &-copy {
      display: flex;
      align-items: center;
      margin-right: 16px;

      span {
        font-size: 12px;
        color: rgba($color-black, 0.7);
        cursor: pointer;
      }
    }

    &-btn {
      margin-left: 8px;
    }
  }
}
