.channel-instructions {
  &__show {
    text-align: right;
    font-size: 14px;
  }

  &__hide {
    text-align: right;
    font-size: 14px;
    padding: 5px 15px 0 0;
  }

  &__content-wrapper {
    display: flex;
    font-size: 14px;
    background-color: rgba($color-blue-clear, 0.15);
    border-radius: 4px;
    margin: 0 0 20px;
  }

  &__content {
    flex-grow: 1;
  }
}
