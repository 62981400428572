%question-library-button {
  background: $color-blue-clear;
  padding: 5px 15px;
  border-radius: 4px;
  color: $color-white;
  font-size: 15px;
  font-weight: 500;

  &:hover {
    cursor: pointer;
    background: darken($color-blue-clear, 5%);
  }
}

@import './filters/question-library-filters';
@import './variations/question-library-variations';

.question-library {
  $root: &;

  &__search {
    padding: 20px 20px 10px;
  }

  &__search-box {
    display: flex;
    align-items: center;
    padding: 0 8px;
    border: 1px solid rgba($color-black, 0.15);
    border-radius: 4px;
  }

  &__search-field {
    flex-grow: 1;
    outline: 0;
    border: 0;
    height: 44px;
    margin-left: 5px;
  }

  &__search-no-luck {
    text-align: center;
    font-size: 11px;
    margin: 5px 0;
  }

  &__main {
    display: flex;
    height: 500px;
  }

  &__main-left {
    @include gl-scroll-class-mixin;
    border-right: 1px solid rgba($color-black, 0.1);
    flex-basis: 220px;
    flex-shrink: 0;
    padding: 0 20px 20px;
  }

  &__main-left-clear {
    text-align: center;
    border: 1px solid rgba($color-black, 0.15);
    border-radius: 4px;
    padding: 3px 10px;
    font-size: 14px;
    margin: 0 0 10px;

    &:hover {
      cursor: pointer;
    }
  }

  &__main-right {
    @include gl-scroll-class-mixin;
    padding: 27px 20px 20px;
    flex-grow: 1;
  }

  &__results {
    margin-right: -10px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
  }

  &__results-item {
    display: inline-block;
    vertical-align: top;
    flex-basis: 33.333333333%;
    padding: 0 10px 10px 0;
  }

  &__loading {
    display: block;
    width: 100%;
    margin: 200px 0 0;
    text-align: center;
  }

  &__no-results {
    font-size: 30px;
    color: rgba($color-black, 0.15);
    text-align: center;
    margin: 200px 0 0;
  }

  &__template {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba($color-black, 0.15);
    border-radius: 4px;
    padding: 15px;

    &:hover {
      cursor: pointer;
    }
  }

  &__template-name {
    flex-basis: 110px;
    font-weight: 500;
    line-height: 1.3;
  }

  &__template-info {
    display: flex;
    align-items: center;
  }

  &__template-button {
    @extend %question-library-button;
    margin: 0 0 0 10px;
  }

  &__template-text {
    flex-grow: 1;
    font-size: 14px;
    color: rgba($color-black, 0.5);
  }

  &__template-variations {
    color: rgba($color-black, 0.5);
    flex-grow: 1;
    font-size: 14px;
    text-align: right;
  }
}
