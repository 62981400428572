.omni-tag {
  display: inline-flex;
  align-items: center;
  height: 20px;
  border-radius: 10px;
  padding: 4px 8px;

  &__label {
    font-size: 11px;
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
    text-decoration: none;
    color: $color-white;
    margin-right: 4px;
  }

  &__icon {
    color: rgba($color-white, 0.4);
  }

  &:hover &__icon {
    color: $color-white;
  }
}
