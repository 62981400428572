.channel-type-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;

  &__item {
    display: flex;
    flex: 0 1 50%;
    padding: 0 8px 16px;
  }

  &__item-inner {
    display: flex;
  }

  @media all and (max-width: 700px) {
    &__item {
      flex: 0 1 100%;
    }
  }
}
