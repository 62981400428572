.error-page {
  @extend %grow-vertical;

  background-color: $color-white;
  height: 100%;
  overflow: auto;
  width: 100%;
}

.error-wrapper {
  margin: 0 auto;
  max-width: 640px;
  padding: 0 12px;
  text-align: center;
}

.error-image {
  margin: 30px auto 0;
  max-height: 400px;
  max-width: 40%;
  transform: rotate(135deg);
}

.error-header {
  color: $color-orange-neon-carrot;
  margin-top: -20px;
}

.error-content {
  font-weight: 300;
  line-height: 1.6em;
  margin: 24px 0;
}

.error-code {
  color: $color-green-seafoam;
}

@media (max-width: 450px) {
  .error-image {
    margin-top: 0;
  }

  .error-header {
    font-size: 1.6em;
  }

  .error-content {
    font-size: 1em;
  }
}
