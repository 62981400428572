.hosting-credit-purchase {
  &__dialog {
    max-width: 800px;

    .hosting-credit-purchase__content {
      padding: 1px 30px 30px;
    }

    .hosting-credit-purchase__enterprise {
      margin: 40px 0 0;
    }
  }

  &__content {
    padding: 0;

    > g-spinner {
      margin: 20px 0 0;
    }
  }

  &__message {
    margin: 20px 0 30px;
  }

  &__toolbar {
    display: flex;
    margin: 0 0 40px;
  }

  &__currencies {
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 4px 0 4px 4px;
    display: flex;
    align-items: center;
    margin: 0 2px;
  }

  &__currency {
    background: transparent;
    border: 1px solid transparent;
    padding: 11px 18px;
    border-radius: 6px;
    margin-right: 4px;
    &:not(.-selected) {
      cursor: pointer;
      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }
    }
    &.-selected {
      background: #fd6600;
      color: white;
      cursor: default;
    }
  }

  &__selected {
    text-align: center;

    &-title {
      font-size: 24px;
      font-weight: 500;
    }

    &-subtitle {
      font-size: 16px;
      font-weight: 400;
      line-height: 1;
    }
  }

  &__slider {
    --slider-percentage: 0%;
    width: 100%;
    margin: 10px 0 20px;

    &-labels {
      display: flex;
      justify-content: space-between;
      font-size: 13px;
      color: rgba($color-black, 0.7);

      > span:first-child {
        margin-right: 10px;
      }
    }

    input[type='range'] {
      -webkit-appearance: none;
      width: 100%;
      height: 8px;
      border-radius: 5px;
      background: linear-gradient(
        to right,
        $color-orange-blaze 0%,
        $color-orange-blaze var(--slider-percentage),
        #ddd var(--slider-percentage),
        #ddd 100%
      );
      outline: none;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: $color-orange-blaze;
        cursor: pointer;
      }

      &::-moz-range-thumb {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: $color-orange-blaze;
        border: none;
        cursor: pointer;
      }

      &:hover {
        &::-webkit-slider-thumb {
          box-shadow: 0px 1px 5px 1px rgba($color-black, 0.2);
        }
        &::-moz-range-thumb {
          box-shadow: 0px 1px 5px 1px rgba($color-black, 0.2);
        }
      }

      // Remove Firefox focus outline
      &::-moz-focus-outer {
        border: 0;
      }
    }
  }

  &__recharge {
    border: 1px solid rgba($color-black, 0.2);
    padding: 14px 18px;
    border-radius: 10px;
    display: flex;

    &-text {
      margin: 0 10px;
      flex: 1;
    }

    &-title {
      font-size: 16px;
      font-weight: 500;
    }

    &-subtitle {
      font-size: 14px;
      color: rgba($color-black, 0.56);
    }

    &-action {
      text-align: center;
      align-self: center;
    }

    &-subtotal {
      font-size: 16px;
      font-weight: 500;
      margin: 0 0 7px 0;
    }
  }

  &__enterprise {
    background: #ebf5fc;
    padding: 20px 30px;
    margin: 40px 0;

    &-title {
      font-size: 24px;
      font-weight: 500;
      color: #339adf;
    }

    &-desc {
      font-size: 18px;
    }
  }
}
