.question-chart-resizer {
  $root: &;
  display: flex;
  justify-content: center;
  &__content {
    position: relative;
  }
  &__chart {
    // ...
  }
  &__edge-x {
    position: absolute;
    top: 0;
    right: -20px;
    bottom: 0;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-handle {
      height: 40px;
      width: 3px;
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.05);
      transition: height 0.15s ease-out;
    }
    &:hover {
      cursor: grab;
      #{$root}__edge-x-handle {
        height: 60px;
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }
  &__edge-y {
    position: absolute;
    left: 0;
    bottom: -20px;
    right: 0;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-handle {
      width: 40px;
      height: 3px;
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.05);
      transition: width 0.15s ease-out;
    }
    &:hover {
      cursor: grab;
      #{$root}__edge-y-handle {
        width: 60px;
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }
}
