.publication-search {
  display: flex;
  align-items: center;
  background: rgba($color-black, 0.03);
  border-radius: 4px;
  height: 36px;
  padding: 0 7px;

  &__icon {
    margin: 0 3px 0 0;
  }

  &__input {
    flex-grow: 1;
    display: block;
    width: 100%;
    outline: 0;
    background: none;
    border: 0;
    margin: 0;
    font-size: 14px;

    &::placeholder {
      color: rgba($color-black, 0.4);
    }
  }
}
