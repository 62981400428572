.text-filter {
  border: 1px solid rgba($color-black, 0.1);
  border-radius: 4px;

  &__search {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    padding: 10px 10px 0;
  }

  &__search-word {
    border-radius: 4px;
    background: $color-blue-clear;
    display: flex;
    align-items: center;
    margin: 0 5px 10px 0;
  }

  &__search-word-text {
    color: $color-white;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    margin: 0 10px;
  }

  &__search-word-btn {
    color: rgba($color-white, 0.5);
    width: 34px;
    height: 34px;
    text-align: center;
    line-height: 34px;
    border-left: 1px solid rgba($color-white, 0.2);
    font-size: 16px;

    &:hover {
      color: $color-white;
      cursor: pointer;
    }
  }

  &__search-icon {
    width: 26px;
    height: 34px;
    text-align: center;
    line-height: 34px;
    font-size: 24px;
    margin: 0 5px 0 0;
  }

  &__search-input {
    border: 0;
    outline: 0;
    padding: 0;
    margin: 0 0 10px;
    height: 34px;
    width: 200px;
  }

  &__suggestions {
    max-height: 300px;
    overflow-y: auto;
    border-top: 1px solid lighten($color-black, 90%);

    &.-scrollable {
      box-shadow: inset 0 -2px 5px rgba($color-black, 0.1);
    }
  }

  &__suggestion {
    border-bottom: 1px solid lighten($color-black, 90%);
    padding: 10px;
    color: rgba($color-black, 0.6);

    &.-focus {
      background-color: rgba($color-black, 0.05);
      color: rgba($color-black, 0.8);
    }

    &:hover {
      cursor: pointer;
      color: rgba($color-black, 0.8);
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}
