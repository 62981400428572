g-flag {
  display: inline-block;
  line-height: 0;
}

.g-flag {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: url('/img/flags.png') no-repeat;
}

.g-flag.-sv {
  background-position: -320px -384px;
}

.g-flag.-au {
  background-position: -384px 0;
}

.g-flag.-gl {
  background-position: -480px -128px;
}

.g-flag.-vc {
  background-position: -96px -448px;
}

.g-flag.-cg {
  background-position: -192px -64px;
}

.g-flag.-qa {
  background-position: -128px -352px;
}

.g-flag.-af {
  background-position: -64px 0;
}

.g-flag.-it {
  background-position: -256px -192px;
}

.g-flag.-mr {
  background-position: -32px -288px;
}

.g-flag.-se {
  background-position: -448px -352px;
}

.g-flag.-cd {
  background-position: -128px -64px;
}

.g-flag.-hk {
  background-position: -320px -160px;
}

.g-flag.-ye {
  background-position: -352px -448px;
}

.g-flag.-gp {
  background-position: -64px -160px;
}

.g-flag.-sh {
  background-position: 0 -384px;
}

.g-flag.-do {
  background-position: -256px -96px;
}

.g-flag.-ru {
  background-position: -256px -352px;
}

.g-flag.-ky {
  background-position: -192px -224px;
}

.g-flag.-at {
  background-position: -352px 0;
}

.g-flag.-wf {
  background-position: -288px -448px;
}

.g-flag.-sk {
  background-position: -64px -384px;
}

.g-flag.-cl {
  background-position: -320px -64px;
}

.g-flag.-ge {
  background-position: -352px -128px;
}

.g-flag.-nu {
  background-position: -128px -320px;
}

.g-flag.-la {
  background-position: -256px -224px;
}

.g-flag.-gq {
  background-position: -96px -160px;
}

.g-flag.-cz {
  background-position: -96px -96px;
}

.g-flag.-pr {
  background-position: -480px -320px;
}

.g-flag.-ls {
  background-position: -448px -224px;
}

.g-flag.-no {
  background-position: -32px -320px;
}

.g-flag.-bf {
  background-position: -128px -32px;
}

.g-flag.-pa {
  background-position: -224px -320px;
}

.g-flag.-mn {
  background-position: -416px -256px;
}

.g-flag.-fo {
  background-position: -192px -128px;
}

.g-flag.-dj {
  background-position: -160px -96px;
}

.g-flag.-st {
  background-position: -288px -384px;
}

.g-flag.-er {
  background-position: -448px -96px;
}

.g-flag.-us {
  background-position: -480px -416px;
}

.g-flag.-zm {
  background-position: -448px -448px;
}

.g-flag.-by {
  background-position: -32px -64px;
}

.g-flag.-cm {
  background-position: -352px -64px;
}

.g-flag.-tw {
  background-position: -352px -416px;
}

.g-flag.-vg {
  background-position: -160px -448px;
}

.g-flag.-zw {
  background-position: -480px -448px;
}

.g-flag.-pl {
  background-position: -416px -320px;
}

.g-flag.-tk {
  background-position: -96px -416px;
}

.g-flag.-ga {
  background-position: -256px -128px;
}

.g-flag.-ba {
  background-position: 0 -32px;
}

.g-flag.-uy {
  background-position: 0 -448px;
}

.g-flag.-km {
  background-position: -32px -224px;
}

.g-flag.-fk {
  background-position: -128px -128px;
}

.g-flag.-mp {
  background-position: -480px -256px;
}

.g-flag.-lv {
  background-position: -32px -256px;
}

.g-flag.-co {
  background-position: -416px -64px;
}

.g-flag.-rs {
  background-position: -224px -352px;
}

.g-flag.-sb {
  background-position: -352px -352px;
}

.g-flag.-in {
  background-position: -128px -192px;
}

.g-flag.-bj {
  background-position: -256px -32px;
}

.g-flag.-ck {
  background-position: -288px -64px;
}

.g-flag.-im {
  background-position: -96px -192px;
}

.g-flag.-tj {
  background-position: -64px -416px;
}

.g-flag.-jp {
  background-position: -384px -192px;
}

.g-flag.-mf {
  background-position: -224px -256px;
}

.g-flag.-pt {
  background-position: -32px -352px;
}

.g-flag.-fm {
  background-position: -160px -128px;
}

.g-flag.-nf {
  background-position: -416px -288px;
}

.g-flag.-mk {
  background-position: -320px -256px;
}

.g-flag.-ad {
  background-position: 0 0;
}

.g-flag.-ni {
  background-position: -480px -288px;
}

.g-flag.-ps {
  background-position: 0 -352px;
}

.g-flag.-gs {
  background-position: -160px -160px;
}

.g-flag.-fj {
  background-position: -96px -128px;
}

.g-flag.-ci {
  background-position: -256px -64px;
}

.g-flag.-es {
  background-position: -480px -96px;
}

.g-flag.-cf {
  background-position: -160px -64px;
}

.g-flag.-th {
  background-position: -32px -416px;
}

.g-flag.-ma {
  background-position: -96px -256px;
}

.g-flag.-kn {
  background-position: -64px -224px;
}

.g-flag.-re {
  background-position: -160px -352px;
}

.g-flag.-cv {
  background-position: 0 -96px;
}

.g-flag.-dk {
  background-position: -192px -96px;
}

.g-flag.-za {
  background-position: -416px -448px;
}

.g-flag.-ss {
  background-position: -256px -384px;
}

.g-flag.-hn {
  background-position: -352px -160px;
}

.g-flag.-om {
  background-position: -192px -320px;
}

.g-flag.-ug {
  background-position: -448px -416px;
}

.g-flag.-bm {
  background-position: -320px -32px;
}

.g-flag.-gg {
  background-position: -384px -128px;
}

.g-flag.-mu {
  background-position: -128px -288px;
}

.g-flag.-bn {
  background-position: -352px -32px;
}

.g-flag.-ch {
  background-position: -224px -64px;
}

.g-flag.-ar {
  background-position: -288px 0;
}

.g-flag.-li {
  background-position: -352px -224px;
}

.g-flag.-yt {
  background-position: -384px -448px;
}

.g-flag.-ml {
  background-position: -352px -256px;
}

.g-flag.-mc {
  background-position: -128px -256px;
}

.g-flag.-ve {
  background-position: -128px -448px;
}

.g-flag.-sd {
  background-position: -416px -352px;
}

.g-flag.-bw {
  background-position: 0 -64px;
}

.g-flag.-ne {
  background-position: -384px -288px;
}

.g-flag.-ag {
  background-position: -96px 0;
}

.g-flag.-md {
  background-position: -160px -256px;
}

.g-flag.-ir {
  background-position: -192px -192px;
}

.g-flag.-kp {
  background-position: -96px -224px;
}

.g-flag.-fi {
  background-position: -64px -128px;
}

.g-flag.-ly {
  background-position: -64px -256px;
}

.g-flag.-nc {
  background-position: -352px -288px;
}

.g-flag.-va {
  background-position: -64px -448px;
}

.g-flag.-dm {
  background-position: -224px -96px;
}

.g-flag.-il {
  background-position: -64px -192px;
}

.g-flag.-kw {
  background-position: -160px -224px;
}

.g-flag.-sc {
  background-position: -384px -352px;
}

.g-flag.-tv {
  background-position: -320px -416px;
}

.g-flag.-tf {
  background-position: -480px -384px;
}

.g-flag.-is {
  background-position: -224px -192px;
}

.g-flag.-mw {
  background-position: -192px -288px;
}

.g-flag.-bb {
  background-position: -32px -32px;
}

.g-flag.-tt {
  background-position: -288px -416px;
}

.g-flag.-dz {
  background-position: -288px -96px;
}

.g-flag.-eg {
  background-position: -384px -96px;
}

.g-flag.-na {
  background-position: -320px -288px;
}

.g-flag.-jo {
  background-position: -352px -192px;
}

.g-flag.-bi {
  background-position: -224px -32px;
}

.g-flag.-me {
  background-position: -192px -256px;
}

.g-flag.-my {
  background-position: -256px -288px;
}

.g-flag.-nr {
  background-position: -96px -320px;
}

.g-flag.-mq {
  background-position: 0 -288px;
}

.g-flag.-lr {
  background-position: -416px -224px;
}

.g-flag.-ax {
  background-position: -448px 0;
}

.g-flag.-as {
  background-position: -320px 0;
}

.g-flag.-sn {
  background-position: -160px -384px;
}

.g-flag.-hr {
  background-position: -384px -160px;
}

.g-flag.-mh {
  background-position: -288px -256px;
}

.g-flag.-py {
  background-position: -96px -352px;
}

.g-flag.-aw {
  background-position: -416px 0;
}

.g-flag.-fr {
  background-position: -224px -128px;
}

.g-flag.-uz {
  background-position: -32px -448px;
}

.g-flag.-gd {
  background-position: -320px -128px;
}

.g-flag.-ws {
  background-position: -320px -448px;
}

.g-flag.-bt {
  background-position: -480px -32px;
}

.g-flag.-tr {
  background-position: -256px -416px;
}

.g-flag.-tz {
  background-position: -384px -416px;
}

.g-flag.-mm {
  background-position: -384px -256px;
}

.g-flag.-td {
  background-position: -448px -384px;
}

.g-flag.-vu {
  background-position: -256px -448px;
}

.g-flag.-bd {
  background-position: -64px -32px;
}

.g-flag.-cu {
  background-position: -480px -64px;
}

.g-flag.-gw {
  background-position: -256px -160px;
}

.g-flag.-np {
  background-position: -64px -320px;
}

.g-flag.-gb {
  background-position: -288px -128px;
}

.g-flag.-lc {
  background-position: -320px -224px;
}

.g-flag.-nz {
  background-position: -160px -320px;
}

.g-flag.-cy {
  background-position: -64px -96px;
}

.g-flag.-ms {
  background-position: -64px -288px;
}

.g-flag.-eu {
  background-position: -32px -128px;
}

.g-flag.-vi {
  background-position: -192px -448px;
}

.g-flag.-kz {
  background-position: -224px -224px;
}

.g-flag.-al {
  background-position: -160px 0;
}

.g-flag.-cw {
  background-position: -32px -96px;
}

.g-flag.-jm {
  background-position: -320px -192px;
}

.g-flag.-ke {
  background-position: -416px -192px;
}

.g-flag.-an {
  background-position: -224px 0;
}

.g-flag.-so {
  background-position: -192px -384px;
}

.g-flag.-lk {
  background-position: -384px -224px;
}

.g-flag.-mv {
  background-position: -160px -288px;
}

.g-flag.-be {
  background-position: -96px -32px;
}

.g-flag.-pk {
  background-position: -384px -320px;
}

.g-flag.-bg {
  background-position: -160px -32px;
}

.g-flag.-az {
  background-position: -480px 0;
}

.g-flag.-gm {
  background-position: 0 -160px;
}

.g-flag.-tc {
  background-position: -416px -384px;
}

.g-flag.-ie {
  background-position: -32px -192px;
}

.g-flag.-mx {
  background-position: -224px -288px;
}

.g-flag.-sm {
  background-position: -128px -384px;
}

.g-flag.-bs {
  background-position: -448px -32px;
}

.g-flag.-iq {
  background-position: -160px -192px;
}

.g-flag.-gt {
  background-position: -192px -160px;
}

.g-flag.-kh {
  background-position: -480px -192px;
}

.g-flag.-ht {
  background-position: -416px -160px;
}

.g-flag.-gu {
  background-position: -224px -160px;
}

.g-flag.-bz {
  background-position: -64px -64px;
}

.g-flag.-bh {
  background-position: -192px -32px;
}

.g-flag.-ph {
  background-position: -352px -320px;
}

.g-flag.-sg {
  background-position: -480px -352px;
}

.g-flag.-kr {
  background-position: -128px -224px;
}

.g-flag.-gn {
  background-position: -32px -160px;
}

.g-flag.-sy {
  background-position: -352px -384px;
}

.g-flag.-ca {
  background-position: -96px -64px;
}

.g-flag.-ua {
  background-position: -416px -416px;
}

.g-flag.-vn {
  background-position: -224px -448px;
}

.g-flag.-je {
  background-position: -288px -192px;
}

.g-flag.-hu {
  background-position: -448px -160px;
}

.g-flag.-cr {
  background-position: -448px -64px;
}

.g-flag.-am {
  background-position: -192px 0;
}

.g-flag.-tl {
  background-position: -128px -416px;
}

.g-flag.-bo {
  background-position: -384px -32px;
}

.g-flag.-ki {
  background-position: 0 -224px;
}

.g-flag.-id {
  background-position: 0 -192px;
}

.g-flag.-gh {
  background-position: -416px -128px;
}

.g-flag.-tn {
  background-position: -192px -416px;
}

.g-flag.-lb {
  background-position: -288px -224px;
}

.g-flag.-br {
  background-position: -416px -32px;
}

.g-flag.-pn {
  background-position: -448px -320px;
}

.g-flag.-de {
  background-position: -128px -96px;
}

.g-flag.-sl {
  background-position: -96px -384px;
}

.g-flag.-sr {
  background-position: -224px -384px;
}

.g-flag.-to {
  background-position: -224px -416px;
}

.g-flag.-gi {
  background-position: -448px -128px;
}

.g-flag.-gr {
  background-position: -128px -160px;
}

.g-flag.-ng {
  background-position: -448px -288px;
}

.g-flag.-sa {
  background-position: -320px -352px;
}

.g-flag.-ae {
  background-position: -32px 0;
}

.g-flag.-ic {
  background-position: -480px -160px;
}

.g-flag.-ao {
  background-position: -256px 0;
}

.g-flag.-pe {
  background-position: -256px -320px;
}

.g-flag.-cn {
  background-position: -384px -64px;
}

.g-flag.-mg {
  background-position: -256px -256px;
}

.g-flag.-si {
  background-position: -32px -384px;
}

.g-flag.-lu {
  background-position: 0 -256px;
}

.g-flag.-gy {
  background-position: -288px -160px;
}

.g-flag.-pf {
  background-position: -288px -320px;
}

.g-flag.-ai {
  background-position: -128px 0;
}

.g-flag.-nl {
  background-position: 0 -320px;
}

.g-flag.-mz {
  background-position: -288px -288px;
}

.g-flag.-tg {
  background-position: 0 -416px;
}

.g-flag.-mo {
  background-position: -448px -256px;
}

.g-flag.-mt {
  background-position: -96px -288px;
}

.g-flag.-rw {
  background-position: -288px -352px;
}

.g-flag.-et {
  background-position: 0 -128px;
}

.g-flag.-eh {
  background-position: -416px -96px;
}

.g-flag.-bl {
  background-position: -288px -32px;
}

.g-flag.-ee {
  background-position: -352px -96px;
}

.g-flag.-tm {
  background-position: -160px -416px;
}

.g-flag.-lt {
  background-position: -480px -224px;
}

.g-flag.-pw {
  background-position: -64px -352px;
}

.g-flag.-pg {
  background-position: -320px -320px;
}

.g-flag.-ro {
  background-position: -192px -352px;
}

.g-flag.-kg {
  background-position: -448px -192px;
}

.g-flag.-ec {
  background-position: -320px -96px;
}

.g-flag.-sz {
  background-position: -384px -384px;
}
