$reportMapChart-color-bg: #fff;

.reportMapChart {
  display: block;
  width: 100%;

  &-map {
    width: 100%;
    height: 100%;

    > div {
      background-color: $reportMapChart-color-bg !important; // overrides googles inline styles
    }
  }
}
