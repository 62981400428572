.project-card {
  &__front {
    // ...
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
    color: rgba($color-blue-slate, 0.25);
  }

  &__name {
    @extend %ellipsis;
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    line-height: 1.2;
    margin: 0 0 4px;
    padding: 0 8px;
  }

  &__info {
    font-size: 12px;
    line-height: 1;
    font-weight: 400;
    text-align: center;
    margin: 0 0 20px;
  }

  &__actions {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    align-items: center;
  }

  &__action {
    margin: 0 0 0 4px;
    color: rgba($color-blue-slate, 0.25);

    &:hover {
      color: rgba($color-blue-slate, 0.5);
      cursor: pointer;
    }
  }

  &__back {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__back-close {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  &__back-info {
    font-size: 13px;
    color: rgba($color-black, 0.5);
  }

  &__back-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }

  &__back-action {
    margin: 0 0 8px;
    font-size: 14px;
  }
}
