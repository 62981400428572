.product-list-item {
  background: $color-white;
  border-radius: 4px;

  &__view {
    display: flex;
    align-items: center;
    padding: 15px;

    &.-clickable {
      cursor: pointer;
    }
  }

  &__view-btns {
    display: flex;
    align-items: center;
  }

  &__view-btn {
    margin: 0 0 0 10px;
  }

  &__remove {
    padding: 20px;

    &-title {
      font-weight: 500;
      margin: 0 0 5px;
    }

    &-text {
      margin: 0 0 15px;
    }

    &-actions {
      display: flex;
      align-items: center;
    }

    &-action {
      margin: 0 10px 0 0;
    }
  }

  &__select {
    display: flex;
    justify-content: center;
    width: 26px;
    margin: 0 10px 0 0;
  }

  &__info {
    flex-grow: 1;

    &-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 1;
      margin: 0 0 4px;
    }

    &-desc {
      font-size: 14px;
      font-weight: 400;
      color: rgba($color-black, 0.4);
      line-height: 1;
    }

    &-channel {
      display: flex;
      align-items: center;

      &-status-circle {
        width: 8px;
        height: 8px;
        min-width: 8px;
        min-height: 8px;
        border-radius: 4px;
        flex-shrink: 0;
        flex-grow: 0;
        margin-right: 8px;

        &.-enabled {
          background: $color-green-seafoam;
        }

        &.-disabled {
          background: $color-red-candy;
        }
      }

      &-name {
        @extend %ellipsis;
        font-size: 13px;
        margin-right: 5px;
      }
    }
  }

  &__edit {
    padding: 15px;
    max-width: 500px;
  }

  &__edit-title {
    font-size: 20px;
    margin: 0 0 15px;
    line-height: 1;
  }

  &__edit-btns {
    display: flex;
    align-items: center;
  }

  &__edit-btn {
    margin: 0 10px 0 0;
  }

  &__error {
    font-size: 14px;
    color: $color-red-candy;
    font-weight: 500;
    line-height: 1.3;
  }

  @media all and (max-width: 450px) {
    &__remove {
      flex-direction: column;
    }
  }
}
