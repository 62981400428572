.channel-form-places {
  &__instructions {
    padding: 15px 0 15px 35px;

    li {
      list-style: decimal;
    }
  }

  &__label {
    display: inline-flex;
    align-items: center;

    span {
      line-height: 0;
    }
  }

  &__label-text {
    font-size: 14px;
    font-weight: 500;
  }

  &__input-wrapper {
    display: flex;
    align-items: center;
    background: rgba($color-black, 0.05);
    border-radius: 4px;
    margin: 0 0 20px;

    &.-focus {
      background: rgba($color-black, 0.07);
    }
  }

  &__input {
    display: block;
    width: 100%;
    outline: 0;
    background: none;
    border: 0;
    margin: 0;
    height: 44px;
    padding: 0 12px;

    &::placeholder {
      color: rgba($color-black, 0.4);
    }
  }

  &__checkbox {
    display: inline-flex;
    align-items: center;
    cursor: default;
  }

  &__checkbox-label {
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 0 12px;
  }

  &__hidden {
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;
  }

  &__link-btn {
    display: inline-flex;
    align-items: center;
    margin: 0 0 0 10px;
    font-size: 14px;

    &:hover {
      cursor: pointer;
    }
  }

  &__places {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 20px;
  }

  &__place-add {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    border-radius: 4px;
    background: rgba($color-black, 0.05);
    margin: 0 0 3px;
    padding: 0 5px;

    g-icon {
      line-height: 0;
      margin-top: 2px;
    }

    &-label {
      font-size: 13px;
      font-weight: 500;
      line-height: 0;
      margin: 0 3px;
    }

    &:hover {
      background: rgba($color-black, 0.03);
      cursor: pointer;
    }
  }
}
