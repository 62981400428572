.report-crosstabs {
  &__zero {
    text-align: center;

    &-text {
      padding: 40px 0;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    background-color: #e9e9ee;
    padding: 10px 0 0;
    position: sticky;
    top: 0;
    z-index: 1;

    &-btn {
      margin: 0 8px 10px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &-gap {
      flex-grow: 1;

      @media all and (max-width: 700px) {
        display: none;
      }
    }

    .gl-btn.-icon-btn {
      min-height: 38px;
      min-width: 30px;

      .gi {
        margin: 0;
        font-size: 1.4em;
        color: black;
      }
    }

    .gl-btn.-spinner-btn {
      display: flex;
      align-items: center;

      g-spinner {
        margin-left: 10px;
      }
    }
  }
}
