.g-select {
  position: relative;

  &__control {
    border: 1px solid rgba($color-black, 0.1);
    background: $color-white;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 8px;

    &.-small {
      height: 32px;
    }

    &.-open,
    &:focus {
      border-color: $color-blue-clear;
    }

    &.-disabled {
      border-color: rgba($color-black, 0.1);
      color: rgba($color-black, 0.4);
    }
  }

  &__caret {
    color: rgba($color-black, 0.1);

    &.-open {
      transform: rotate(180deg);
      margin-top: -3px;
      color: rgba($color-black, 0.6);
    }
  }

  &__placeholder,
  &__value {
    @extend %ellipsis;
    flex-grow: 1;
    font-size: 15px;
    line-height: 1.6;
  }

  &__placeholder {
    color: rgba($color-black, 0.4);

    // used to maintain the same inline alignment levels
    &.-blank {
      opacity: 0;
    }
  }

  &__list {
    // position: absolute;
    // left: 1px;
    // right: 1px;
    padding: 8px 1px;
    box-shadow: 0 2px 10px rgba($color-black, 0.2);
    background: $color-white;
    z-index: 1;
    overflow-y: auto;
    max-height: 184px;
  }

  &__option {
    $option: &;

    padding: 10px 8px;
    display: flex;
    align-items: center;

    &-checkbox {
      margin: 0 0 0 8px;
    }

    &-label {
      font-size: 15px;
      flex-grow: 1;
      margin: 0 0 0 8px;
      color: rgba($color-black, 0.6);
      line-height: 1.3;

      &.-selected {
        font-weight: 500;
        color: rgba($color-black, 0.7);
      }
    }

    &:hover {
      cursor: pointer;
      background: rgba($color-black, 0.03);
    }
  }

  &__custom {
    border: 0;
    outline: 0;
    height: 39px; // match option flow height
    padding: 0 16px;
    font-size: 15px;
    width: 100%;

    &::placeholder {
      color: rgba($color-black, 0.4);
    }
  }
}
