.channel-stats-total {
  &__stat-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 0 12px;
  }

  &__stat {
    font-size: 14px;
    margin: 0 4px;
  }

  &__bar {
    display: flex;
    align-items: stretch;
    height: 12px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.05);
    overflow: hidden;

    &-section {
      &.-responses {
        background: $color-green-seafoam;
      }

      &.-exits {
        background: rgba($color-black, 0.15);
      }
    }
  }
}
