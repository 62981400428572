.g-multi-switch {
  $border-radius: 15px;

  display: inline-flex;
  align-items: center;
  height: 30px;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.05);

  &__option {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    height: 30px;

    span {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      white-space: nowrap;
    }

    &:hover:not(.-disabled) {
      cursor: pointer;
    }

    &.-selected {
      background-color: $color-orange-blaze;
      border-radius: $border-radius;
      color: $color-white;
    }

    &.-disabled {
      color: rgba($color-black, 0.2);
    }
  }
}
