.account-subscriber-creator {
  &__dialog {
    max-width: 530px;
    border: 0;
  }

  &__content-form {
    padding: 1px 20px 20px;
  }

  &__content-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    max-width: 100%;
  }

  &__icon {
    margin: 0 0 8px;
    color: rgba($color-black, 0.2);
  }

  &__message {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 18px;
  }

  &__actions {
    margin: 40px 0 0;

    > :first-child {
      margin: 0 50px 0 0;
    }
  }

  &__error-wrapper {
    margin: 24px 0;
  }

  &__error-list {
    padding: 0 18px;
    font-size: 14px;

    > li {
      list-style-type: disc;
    }

    span {
      font-weight: 500;
      color: $color-red-candy;
      margin: 16px 0;
    }
  }
}
