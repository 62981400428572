.login-form {
  padding: 30px;

  &__input {
    display: block;
    margin: 0 0 10px;
  }

  &__input-label {
    font-size: 14px;
    font-weight: 300;
  }

  &__input-input {
    border: 1px solid rgba($color-black, 0.1);
    border-radius: 4px;
    background: 0;
    outline: 0;
    box-shadow: 0;
    height: 40px;
    width: 100%;
    padding: 0 10px;
    transition: border-color 0.15s ease-out;

    &:hover,
    &:focus {
      border-color: rgba($color-black, 0.4);
    }
  }

  &__errors {
    margin: 0 0 10px;
  }

  &__forgot-password {
    font-size: 12px;
    margin: 0 0 10px;
  }
}
