.account-setup {
  display: flex;
  flex-direction: column;

  &__dialog {
    max-width: 800px;

    @media all and (max-width: 860px) {
      max-width: 90%;
    }
  }

  &__page {
    padding: 20px 40px;
  }

  &__input {
    display: flex;
    flex-direction: column;
    margin: 0 0 10px;
  }

  &__input-label {
    font-size: 14px;
    font-weight: 300;
  }

  &__input-text,
  &__input-select {
    max-width: 300px;
    width: 100%;
  }

  &__input-text {
    border: 1px solid rgba($color-black, 0.1);
    border-radius: 4px;
    background: 0;
    outline: 0;
    box-shadow: 0;
    height: 40px;
    padding: 0 10px;
    transition: border-color 0.15s ease-out;

    &:hover,
    &:focus {
      border-color: rgba($color-black, 0.4);
    }
  }

  &__input-image {
    max-width: 200px;
    border-radius: 4px;
  }
}
