.filter-set-editor {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__head {
    display: flex;
    margin: 16px 0;
    padding: 0 16px;
    flex-shrink: 0;
  }

  &__head-text {
    flex-grow: 1;
    margin-left: 8px;
    font-size: 16px;
  }

  &__label {
    display: flex;
    padding: 0 16px;
    margin: 0 0 8px;
    flex-shrink: 0;
  }

  &__label-input {
    display: block;
    width: 100%;
    border: 1px solid rgba($color-black, 0.1);
    border-radius: 4px;
    height: 36px;
    padding: 0 8px;
    outline: 0;
    font-size: 15px;
    flex-grow: 1;

    &::placeholder {
      color: rgba($color-black, 0.26);
    }

    &[disabled] {
      background-color: rgba($color-black, 0.05);
      border: 0;
    }

    &:focus {
      border-color: $color-blue-clear;
    }
  }

  &__label-btn {
    margin: 0 0 0 5px;
  }

  &__errors {
    margin: 0 16px;
  }

  &__tabs {
    // ...
  }

  &__content {
    @include gl-scroll-class-mixin;
    flex-grow: 1;
    overflow-y: auto;
  }

  &__content-inner {
    padding: 8px 0;
  }

  &__filter {
    display: block;
    border: 1px solid rgba($color-black, 0.1);
    border-radius: 4px;
    padding: 8px;
    margin: 8px 16px;

    &:hover {
      // cursor: pointer;
      // background-color: rgba($color-black, .05);
    }
  }

  &__add {
    margin: 0 16px;
  }

  &__foot {
    flex-shrink: 0;
    padding: 16px;
  }

  &__separator {
    border-bottom: 2px solid rgba($color-black, 0.1);
    margin: 8px 0 16px;
  }

  &__visibility {
    display: flex;
    align-items: center;

    &-content {
      flex-grow: 1;
    }

    &-label {
      font-size: 13px;
      font-weight: 600;
      line-height: 1;
      margin: 0 0 2px;
    }

    &-value {
      font-size: 13px;
      font-weight: 400;
      flex-grow: 1;
      color: rgba($color-black, 0.5);
    }

    &-icon {
      margin-left: 8px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
