.kit-list {
  &__item {
    cursor: pointer;
  }

  &__content {
    margin-right: auto;
    width: 400px;
  }

  &__empty-msg {
    font-size: 1rem;
    font-weight: 300;
    padding-left: 24px;
  }
}
