.projects-ad {
  background: $color-white;
  border-radius: 4px;
  padding: 20px;
  position: relative;

  &__title {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 20px;
  }

  &__sub {
    font-size: 15px;
    text-align: center;
    margin: 20px 0;
  }

  &__text {
    font-size: 13px;
    margin: 20px 0;
  }

  &__list {
    font-size: 13px;
    margin: 20px 0;
    padding-left: 17px;
  }

  &__listitem {
    list-style-type: disc;
    margin: 0 0 5px;
  }
}
