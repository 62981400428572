// mixin to use in components that make use of gl-scroll-class='-scroll'
// adds top/bottom scroll shadows based on whether you can scroll in those directions
@mixin gl-scroll-class-mixin {
  overflow-y: auto;
  transition: box-shadow 0.2s ease-in-out;

  &.-scroll-bottom {
    box-shadow: inset 0 -5px 5px -3px rgba($color-black, 0.1);
  }

  &.-scroll-top {
    box-shadow: inset 0 5px 5px -3px rgba($color-black, 0.1);
  }

  &.-scroll-top.-scroll-bottom {
    box-shadow: inset 0 -5px 5px -3px rgba($color-black, 0.1),
      inset 0 5px 5px -3px rgba($color-black, 0.1);
  }
}
