.constant-sum-filter {
  &__label {
    @extend %base-form-label;
    flex-grow: 1;
  }

  &__input {
    @extend %base-form-control;
    min-height: 28px;
    width: unset;

    &::placeholder {
      color: rgba($color-black, 0.2);
    }
  }
}
