.survey-explorer-select {
  position: relative;
  margin-right: 4px;
  &__btn {
    background: white;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    height: 40px;
    max-width: 180px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__btn-main {
    padding: 0 0 0 12px;
    display: flex;
    align-items: center;
    align-self: stretch;
    min-width: 0;
  }
  &__btn-main-content {
    min-width: 0;
  }
  &__btn-main-kind {
    font-size: 9px;
    color: rgba(0, 0, 0, 0.3);
    line-height: 1;
  }
  &__btn-main-label {
    font-size: 15px;
    line-height: 1;
    // ellipsis;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__btn-main-caret {
    line-height: 1;
    margin: 0 8px;
    color: rgba(0, 0, 0, 0.3);
  }
  &__btn-config {
    width: 30px;
    align-self: stretch;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__menu {
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    min-width: 200px;
    padding: 4px 0;
    z-index: 1;
    overflow-y: auto;
    max-height: 320px;
  }
  &__menu-option {
    display: flex;
    align-items: center;
    padding: 6px 12px;
    font-size: 15px;
    line-height: 1.3;
    &:hover {
      cursor: pointer;
      background: rgba(0, 0, 0, 0.05);
    }
  }
  &__menu-search {
    padding: 4px 8px 8px;
  }
}
