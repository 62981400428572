.estimate {
  padding: 0 30px 150px;

  &__wrapper {
    max-width: 1030px;
    margin: 0 auto;
  }

  &__content {
    display: flex;
    align-items: flex-start;
  }

  &__main {
    flex-grow: 1;
  }

  &__aside {
    position: sticky;
    top: 90px;
    flex-basis: 280px;
    flex-shrink: 0;
    margin: 0 0 0 30px;
  }

  &__title {
    margin: 45px 0 15px;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    line-height: 1;
  }

  &__subtitle {
    text-align: center;
    margin: 15px 0 45px;
  }

  &__box {
    background: $color-white;
    border-radius: 4px;
    padding: 30px;
    margin: 0 0 16px;
  }

  &__box-title {
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 16px;
    line-height: 1;
  }

  &__rows {
    // ...
  }

  &__row {
    display: flex;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid rgba($color-black, 0.05);

    &-label {
      flex-grow: 1;
    }

    &-field {
      width: 140px;
      flex-shrink: 0;
      margin: 0 0 0 16px;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  &__grid {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    flex-wrap: wrap;
    margin: 0 -4px -8px;

    &-item {
      display: flex;
      flex-basis: 50%;
      padding: 0 4px 8px;
    }
  }

  &__type {
    $root: &;
    flex: 1;
    border: 1px solid rgba($color-black, 0.05);
    border-radius: 4px;
    padding: 16px;
    position: relative;

    &-name {
      font-size: 15px;
      font-weight: 500;
      line-height: 1;
      margin: 0 0 16px;
    }

    &-desc {
      font-size: 13px;
      line-height: 1.3;
      color: rgba($color-black, 0.54);
      margin: 0;
    }

    &-icon {
      position: absolute;
      top: 9px;
      right: 9px;
      display: none;
      color: $color-white;
    }

    &:hover {
      cursor: pointer;
      border-color: rgba($color-black, 0.1);

      // #{$root}-name {
      //     color: $color-orange-tango;
      // }
    }

    &.-selected {
      border-color: $color-orange-blaze;
      background-color: $color-orange-blaze;

      #{$root}-name,
      #{$root}-desc {
        color: $color-white;
      }

      #{$root}-icon {
        display: block;
      }
    }
  }

  &__targeting {
    margin-top: 30px;
  }

  &__targeting-title {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 25px;
    line-height: 1;
  }

  &__total {
    text-align: center;
    font-size: 26px;
    font-weight: 500;
    margin-top: 20px;

    &-term {
      font-size: 12px;
      line-height: 1;
      margin: 5px 0 20px 0;
      color: rgba(0, 0, 0, 0.6);
      text-align: center;
    }
  }

  &__feasibility {
    display: flex;
    align-items: center;
    justify-content: center;

    &-hint {
      line-height: 0;
      margin: 1px 0 0 5px;
    }

    &-label {
      font-size: 14px;
      font-weight: 500;

      &.-dim {
        color: rgba($color-black, 0.3);
      }

      &.-alert {
        color: $color-red-candy;
      }

      &.-success {
        color: $color-green-seafoam;
      }

      &.-link {
        color: $color-orange-blaze;
      }

      &.-center {
        text-align: center;
      }
    }

    &-processing {
      color: rgba($color-black, 0.3);
    }
  }

  &__errors {
    list-style-type: disc;
    margin: 10px 0 0;
    padding: 0 0 0 15px;
  }

  &__error {
    font-size: 12px;
    font-weight: 500;
    color: red;
    padding: 0 0 0 10px;
  }

  &__btn-wrapper {
    margin: 30px 0 0;

    &.-email {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__next-steps {
    margin: 20px 0 0;

    g-btn {
      display: block;
      margin: 10px 0 0;
    }
  }

  &__footer {
    margin-top: 30px;

    &-title {
      font-size: 24px;
      font-weight: 400;
      line-height: 1;
    }

    &-subtitle {
      font-size: 16px;
      font-weight: 300;
      margin: 4px 0 20px 0;
    }
  }

  @media all and (max-width: 760px) {
    &__content {
      flex-direction: column;
      align-items: stretch;
    }

    &__aside {
      margin-left: 0;
    }
  }

  @media all and (max-width: 499px) {
    &__grid-item {
      flex-basis: 100%;
    }
  }
}
