// TODO: this component has styles all over the place that need to be brought here.

@import './report-stat/report-stat';
@import './restricted-analysis/restricted-analysis';
@import './filter-sets/filter-sets';
@import './report-filter-editor/report-filter-editor';
@import './counts/survey-report-counts';
@import './report-comparison/report-comparison';
@import './report-zoom/report-zoom';
@import './report-view-question/report-view-question';
@import './report-view-section/report-view-section';
@import './report-widget/report-widget';
@import './report-link/report-link-list';
@import './report-link/report-link-editor';
@import './report-crosstabs/report-crosstabs';
@import './report-crosstab/report-crosstab';
@import './report-settings/report-settings';
@import './report-mods/report-mods';
@import './report-subtype-switcher/report-subtype-switcher';
@import './report-info-mood/report-info-mood';
@import './report-info-nps/report-info-nps';
@import './report-info-rating/report-info-rating';
@import './report-info-score-definition/report-info-score-definition';
@import './report-media-btn/report-media-btn';
@import './report-loading-spinner/report-loading-spinner';

%survey-reporting-card {
  background-color: $color-white;
  border-radius: 2px;
}

.xsurvey-report {
  --leftSide: 340px;
  --rightSide: 300px;
  --sideToggleWidth: 30px;
  --sideToggleHeight: 40px;
  --sideToggleOffset: calc((var(--sideToggleWidth) - 1px) * -1);
  --zIndexSideMobile: 1;
  --zIndexSideMobileActive: 10;
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  align-items: stretch;
  &__left {
    width: var(--leftSide);
    flex-shrink: 0;
    background: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    z-index: 1;
  }
  &__left-main {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    /* hide scrollbars */
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__left-title {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 18px;
    line-height: 1.2;
  }
  &__left-toolbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -2px 20px;
  }
  &__left-toolbar-item {
    flex: 1;
    padding: 0 2px;
  }
  &__left-toolbar-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 7px;
    font-size: 14px;
    padding: 6px 0;
    cursor: pointer;
  }
  &__left-toolbar-label {
    margin-left: 2px;
  }
  &__left-view {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.7);
    padding: 6px 0;
    line-height: 1.2;
    font-size: 15px;
    position: relative;
    &-num {
      width: 38px;
      flex-shrink: 0;
      font-weight: 600;
      padding-right: 10px;
    }
    &-label {
      line-height: 1.4;
    }
    &-bar {
      position: absolute;
      top: 0;
      right: -20px;
      bottom: 0;
      width: 5px;
      background: #f60;
    }
    &.-selected {
      .xsurvey-report__left-view-num {
        color: #f60;
      }
      .xsurvey-report__left-view-label {
        color: #000;
      }
    }
  }
  &__left-foot {
    padding: 10px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  &__left-crosstabs {
    padding: 0 20px;
    font-size: 15px;
    line-height: 34px;
    height: 34px;
    cursor: pointer;
    &.-selected {
      border-right: 5px solid #f60;
    }
  }
  &__main {
    flex: 1;
    overflow-y: auto;
  }
  &__main-content {
    max-width: 1024px;
    margin: 0 auto;
    &.-crosstabs {
      padding: 25px 40px;
    }
  }
  &__right {
    width: var(--rightSide);
    flex-shrink: 0;
    background: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
  }
  &__right-main {
    flex: 1;
    overflow-y: auto;
  }
  &__right-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 16px;
  }
  &__loading {
    padding: 100px 40px;
  }
  &__zero {
    padding: 100px 40px;
    &-message {
      text-align: center;
    }
    &-actions {
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__backdrop {
    z-index: var(--zIndexSideMobile);
    transition: all 0.2s ease-in-out;

    &.ng-enter,
    &.ng-leave.ng-leave-active {
      opacity: 0;
    }
    &.ng-enter.ng-enter-active,
    &.ng-leave {
      opacity: 1;
    }
  }
  @mixin side-toggle {
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50px;
    background: white;
    padding: 6px;
    width: var(--sideToggleWidth);
    height: var(--sideToggleHeight);
    cursor: pointer;
  }
  &__left-toggle {
    @include side-toggle;
    right: var(--sideToggleOffset);
    border-radius: 0 6px 6px 0;
    box-shadow: 0 -3px 3px -3px rgba(0, 0, 0, 0.15),
      3px 0px 3px -3px rgba(0, 0, 0, 0.15), 0px 3px 3px -3px rgba(0, 0, 0, 0.15);
  }
  &__right-toggle {
    @include side-toggle;
    left: var(--sideToggleOffset);
    border-radius: 6px 0 0 6px;
    box-shadow: 0 -3px 3px -3px rgba(0, 0, 0, 0.15),
      -3px 0px 3px -3px rgba(0, 0, 0, 0.15),
      0px 3px 3px -3px rgba(0, 0, 0, 0.15);
  }
  &__legacybanner {
    background: #636363;
    padding: 8px;
    margin: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-icon {
      margin-right: 5px;
      color: white;
      line-height: 1;
    }
    &-msg {
      font-size: 14px;
      color: white;
    }
    &-btn {
      margin-left: 13px;
      color: white;
      font-size: 12px;
      font-weight: 500;
      background: #2e2e2e;
      border-radius: 4px;
      padding: 2px 6px;
      transition: transform 0.1s ease-out;
      &:hover {
        cursor: pointer;
        transform: scale(1.05);
      }
    }
  }
  @mixin side-mobile {
    position: absolute;
    height: 100%;
    max-width: calc(100% - var(--sideToggleWidth));
    transition: all $swift-ease-in-out-duration
      $swift-ease-in-out-timing-function;
    z-index: var(--zIndexSideMobile); // ensure float on top of main

    &.-mobile-active {
      z-index: var(--zIndexSideMobileActive);
      transform: translateX(0%);
    }
  }
  @media all and (max-width: 769px) {
    &__left {
      @include side-mobile;
      transform: translateX(-100%);
    }
    &__left-toggle {
      display: flex;
    }
  }
  @media all and (max-width: 1065px) {
    &__right {
      @include side-mobile;
      right: 0;
      transform: translateX(100%);
    }
    &__right-toggle {
      display: flex;
    }
  }
}

.survey-report {
  &__main {
    transition: opacity 0.15s ease-out, transform 0.15s ease-out;
    // filter .15s ease-out;
    transform-origin: top;

    &.-fade-out {
      opacity: 0.2;
      transform: scale(0.99);
      // filter: grayscale(100%);
    }
  }
  &__details {
    align-items: center;
    display: flex;
    margin: 10px 0 20px;
    overflow: hidden;
    width: 100%;

    @media all and (max-width: 415px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__details-icon {
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    height: 50px;
    margin-right: 12px;
    min-width: 50px;
    width: 50px;
  }

  &__details-title {
    font-size: 1.5rem;
    line-height: 1.2em;
  }

  &__details-info {
    flex-grow: 1;
  }

  &__filters {
    align-items: center;
    overflow: hidden;
    width: 100%;
    display: none;

    @media all and (max-width: 1065px) {
      display: flex;
    }

    @media all and (max-width: 415px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__filters-btn {
    flex-grow: 1;
  }

  &__restricted-analysis {
    display: block;
    background-color: $color-white;
    border-radius: 4px;
    padding: 16px;
    margin: 16px 0;
  }

  &__toolbar {
    margin: 12px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    &-gap {
      flex-grow: 1;

      @media all and (max-width: 700px) {
        display: none;
      }
    }

    button {
      margin: 0 5px 5px;
      min-height: 38px;
      min-width: 150px;

      .gi {
        font-size: 1.4em;
        margin-right: 8px;
        vertical-align: top;
      }

      &.active {
        background-color: $color-dodger-blue;
        color: $color-white;
      }

      &.pull-right {
        float: right;
        margin-left: 5px;
        margin-right: 0;
      }

      &.-icon-btn {
        min-width: 50px;

        .gi {
          margin: 0;
        }
      }
    }
  }

  &__zero {
    padding: 100px 40px;

    &-message {
      text-align: center;
    }

    &-actions {
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
