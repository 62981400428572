.suggestion {
  background: $color-blue-clear;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: $color-white;
  padding: 5px 0;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  &__action {
    text-decoration: underline;
    margin: 0 0 0 5px;
  }
}
