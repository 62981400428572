.rating-stars {
  display: flex;
  align-items: center;
  justify-content: center;

  &__label {
    &.-large {
      position: relative;
      top: 2px;
      font-size: 18px;
    }
  }

  &__star {
    &.-selectable {
      cursor: pointer;
    }
  }
}
