.survey-share-wrapper {
  @extend %container-white;
}

.survey-share-panel {
  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__image {
    margin: 0 40px 0 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 600px;

    &-heading {
      font-size: 24px;
    }

    &-description {
      margin: 15px 0 0;
    }

    &-get-estimate-btn {
      cursor: pointer;
      color: $color-white;
      background-color: $color-orange-glow;
      border: 2px solid $color-orange-glow;
      border-radius: 4px;
      padding: 5px 20px;
      margin: 10px 0;
    }

    &-footer {
      font-size: 12px;
    }
  }

  @media all and (max-width: 800px) {
    &__wrapper {
      flex-direction: column;
    }

    &__image {
      margin: 15px 0 0;
    }

    &__content {
      flex-basis: auto;
    }
  }
}
