.frame {
  $header-size: 60px;

  &__header {
    height: $header-size;
    padding: 0 16px;
    background: $color-white;
    box-shadow: 0 1px 7px rgba($color-black, 0.1);
    border-bottom: 1px solid rgba($color-black, 0.1);
    display: flex;
    align-items: center;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  &__logo {
    width: 36px;
    height: 36px;
    margin: 0 24px 0 -4px;
  }

  &__title {
    font-size: 22px;
    font-weight: 500;
    flex-grow: 1;
  }

  &__content {
    padding-top: $header-size;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }

  &__input-form {
    width: 350px;
    margin-top: 50px;
  }

  &__input-error > div {
    outline: 1px solid $color-red-candy;
  }

  &__input-error-text {
    color: $color-red-candy;
    margin: -24px 0 0 0;
    float: right;
    font-size: 10px;
  }

  &__iframe {
    border: 0;
    outline: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-color: $color-white;
  }
}
