.account {
  &__main {
    background: $color-white;
    border-radius: 4px;
    padding-top: 10px;
  }

  @media all and (max-width: 710px) {
    &__content-scroller {
      overflow-x: auto;

      > div {
        width: 800px;
      }
    }
  }

  &__content {
    padding: 30px;

    &-plans-responses {
      &-head {
        text-align: center;
        margin: 40px 0;
      }

      &-title {
        font-size: 20px;
        font-weight: 400;
        margin: 0;
        line-height: 1;
      }
    }
  }
}

.account-details {
  &__sidebyside {
    display: flex;
    align-items: flex-start;
    margin: -24px -5px -24px;

    > div {
      padding: 0 5px;
      flex-basis: 50%;
    }
  }

  &__actions {
    margin-top: 40px;
  }
}

.account-users {
  &__invite-container {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    > div {
      flex-basis: 50%;
    }

    > div:first-child {
      padding-right: 10px;
    }
  }

  &__invite-label .field-label {
    font-weight: 500;
    font-size: 15px;
  }

  &__invite-fields {
    display: flex;
    align-items: center;

    > div:first-child {
      padding-right: 10px;
      flex-grow: 1;
    }
  }

  &__actions {
    display: flex;
    align-items: center;

    > :not(:last-child) {
      margin: 0 10px 0 0;
    }
  }

  &__hint-icon {
    vertical-align: middle;
  }

  &__pending-invite {
    font-style: italic;
    color: rgba($color-black, 0.4);
  }

  &__table {
    td,
    th {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.account-billing {
  &__sides {
    display: flex;
    align-items: flex-start;

    &-left {
      flex-grow: 1;
    }

    &-right {
      flex-shrink: 0;
    }
  }

  &__credits {
    margin-bottom: 30px;

    &-header {
      display: flex;
      align-items: center;
      margin: 0 0 20px;

      &-left {
        flex-grow: 1;
      }

      &-right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &-balance {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &-label {
        font-size: 20px;
        line-height: 1;
        margin: 0 10px 0 0;
      }

      &-amount {
        font-size: 20px;
        font-weight: 500;
        margin: 0;
        line-height: 1;
      }

      &-info {
        line-height: 1;
      }
    }

    &-term {
      font-size: 12px;
      color: rgba($color-black, 0.6);
    }
  }

  &__payment-methods {
    padding-bottom: 10px;
  }

  &__balance {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &-label {
      font-size: 20px;
      margin: 0;
      line-height: 1;
      margin: 0 10px 0 0;
    }

    &-amount {
      font-size: 20px;
      font-weight: 500;
      margin: 0;
      line-height: 1;
    }

    &-info {
      line-height: 1;
    }
  }
}

.account-admin {
  &__header {
    margin: 20px 0;
  }

  &__checkbox {
    margin-top: 8px;
  }

  &__actions {
    margin-top: 40px;
  }
}
