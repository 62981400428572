@import './manager/beacon-manager';
@import './list-item/beacon-list-item';

.beacons {
  &__head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 0 32px;
  }

  &__title {
    display: flex;
    align-items: center;
  }

  &__sub-title {
    font-size: 16px;
  }

  &__icon {
    margin: 0 5px 0 0;
  }
}
