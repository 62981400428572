.response-filter-rule {
  display: flex;
  align-items: center;
  &:last-child {
    margin-bottom: 0;
  }

  &__fields {
    display: flex;
    align-items: center;
    flex: 1;
    margin: 0 -1px;
    min-width: 0; // overflow protection
  }

  &__field {
    padding: 0 1px;
    width: 20%; // 5
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50px;
  }

  &__action {
    color: rgba($color-black, 0.4);
    line-height: 0;
    &:hover {
      cursor: pointer;
      color: rgba($color-black, 0.7);
    }
  }
}
