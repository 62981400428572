.report-info-mood {
  margin-top: 20px;

  &__text {
    text-align: center;
    margin: 0 0 3px;
  }
  &__how {
    text-align: center;
    font-size: 11px;
    color: rgba($color-black, 0.3);
    cursor: pointer;
  }
}
