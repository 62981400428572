.project-editor {
  &__dialog {
    max-width: 500px;
  }

  &__content {
    padding: 0 24px 24px;
  }

  &__input {
    width: 100%;
    height: 34px;
    padding: 0 8px;
    border: 1px solid rgba($color-black, 0.1);
    outline: 0;
    margin: 0 0 16px;

    &:focus {
      border-color: $color-blue-clear;
    }
  }

  &__visibility-text {
    font-size: 12px;
    color: rgba($color-black, 0.4);
    margin: -20px 0 25px;
  }

  &__visibility-users {
    display: block;
    margin-top: -16px;
  }

  &__errors {
    margin: 0 0 16px;
  }
}
