.publication-country-select {
  &__country {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
    margin: 5px 0 0;
    display: flex;

    g-icon-button {
      margin: 0 0 0 5px;
    }
  }

  &__country-name {
    @extend %ellipsis;
  }
}
