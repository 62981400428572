.project-resource-editor {
  &__dialog {
    max-width: 480px;
  }

  &__content {
    padding: 1px 20px 20px;
  }

  &__types {
    display: flex;
    margin: 19px -10px 0;
  }

  &__type-container {
    margin: 0 5px;
    flex: 1;
  }

  &__type {
    border: 1px solid rgba($color-black, 0.1);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 16px;
  }

  &__type-icon {
    margin-right: 8px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: rgba($color-black, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__errors {
    margin: 0 0 16px;
  }
}
