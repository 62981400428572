@import './location-filter/location-filter';
@import './text-filter/text-filter';
@import './constant-sum-filter/constant-sum-filter';

.question-filter {
  &__profiling {
    display: flex;
    margin: 0 -10px 20px 0;
  }

  &__profiling-wrapper {
    padding-right: 10px;
  }

  &__profiling-item {
    display: block;
    border: 1px solid rgba($color-black, 0.15);
    border-radius: 4px;

    &.-selected {
      border-color: $color-blue-clear;
    }
  }
}

// TODO: is this in the wrong component?
.question-filter-options {
  max-height: 450px;

  &-icon {
    font-size: 30px;
    display: inline-block;
    vertical-align: middle;
  }
}
