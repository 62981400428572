select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
  border-bottom: 1px solid $color-grey;
  border-radius: 0;
  display: block;
  margin: 0;
  width: 100%;

  &:focus {
    border-bottom: 1px solid $color-blue-clear;
    margin-bottom: -1px;
    outline: 0;
  }

  &.set {
    color: $base-font-color;
  }
}

.gl-select {
  margin-bottom: 9px;
  position: relative;

  .gl-select-icon {
    font-size: 1.4rem;
    position: absolute;
    right: 2px;
    top: -2px;
  }
}
