.survey-template-grid {
  margin: 0 -8px;

  &__item {
    vertical-align: top;
    display: inline-block;
    padding: 0 8px 16px;
    width: 25%;
  }

  @media all and (max-width: 900px) {
    &__item {
      width: 33.3333333333%;
    }
  }

  @media all and (max-width: 680px) {
    &__item {
      width: 50%;
    }
  }

  @media all and (max-width: 400px) {
    &__item {
      width: 100%;
    }
  }
}
