@import './report-semantic-diff-text/report-semantic-diff-text';
@import './report-matrix-bar-chart/report-matrix-bar-chart';
@import './question-chart/question-chart';
@import './question-chart-resizer/question-chart-resizer';

// HACK (GLOWPORTAL-1506)
// latest version of c3 puts a rect over top of pie charts so
// tooltips/mouse-events dont work
// https://github.com/c3js/c3/issues/2354
gl-report-nps-chart,
gl-report-mood-chart,
gl-report-pie-chart {
  g.c3-chart {
    g.c3-event-rects {
      rect.c3-event-rect {
        pointer-events: none;
      }
    }
  }
}

.report-charts {
  &__container {
    position: relative;

    > svg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &__note {
    text-align: center;
    font-size: 11px;
    color: rgba($color-black, 0.3);
    cursor: pointer;
  }
}
