.publication-grid {
  $root: &;
  margin: 0 -12px;

  &__item {
    vertical-align: top;
    display: inline-block;
    padding: 0 12px 24px;
  }

  &__zero {
    text-align: center;
    padding: 30px 0;
    color: rgba($color-black, 0.24);
  }
}
