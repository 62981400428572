.export {
  padding: 0 20px;
  &__content {
    background: white;
    border-radius: 4px;
    padding: 20px;
    max-width: 400px;
    margin: 100px auto 0;
  }
  &__head {
    display: flex;
    align-items: center;
    margin: 0 0 12px;
  }
  &__title {
    font-size: 18px;
    font-weight: 600;
    flex: 1;
  }
  &__text {
    line-height: 1.4;
  }
  &__action {
    margin-top: 30px;
  }

  %info-box {
    background: rgba($color-orange-blaze, 0.1);
    margin: 20px 0 0;
    padding: 8px 5px;
    border-radius: 4px;
  }

  &__info {
    @extend %info-box;
    display: flex;
    align-items: center;

    &-text {
      font-size: 11px;
      margin: 0 0 0 5px;
    }
  }

  &__info-spss {
    @extend %info-box;

    &-header {
      display: flex;
      align-items: center;
      margin: 0 0 12px;
      font-size: 14px;
      font-weight: 500;
    }

    &-list {
      font-size: 12px;
      text-align: justify;

      ol {
        padding: 0 24px;
        li {
          margin: 0 0 5px;
        }
        &.disc {
          list-style-type: disc;
        }
        &.circle {
          list-style-type: circle;
        }
      }
    }

    &-footer {
      padding: 0 8px;
      margin: 12px 0 5px;
      font-size: 12px;
      font-weight: 500;
      text-align: justify;
    }
  }
}
