.image-gallery {
  flex-grow: 1;
  max-width: 800px;
  display: flex;
  flex-direction: column;

  &__main {
    flex-grow: 1;
  }

  &__gallery {
    display: flex;
    flex-wrap: wrap;
  }

  // Ref: https://pqina.nl/blog/presenting-images-in-an-aspect-ratio-with-css/
  /* Position child elements relative to this element */
  &__aspect-ratio-box {
    position: relative;
  }

  /* Create a pseudo element that uses padding-bottom to take up space */
  &__aspect-ratio-box::after {
    display: block;
    content: '';
    /* 1:1 aspect ratio */
    padding-bottom: 100%;
  }

  /* Child elements are positioned absolutely relative to the parent element */
  &__img,
  &__overlay {
    /* Child elements should match parent box size */
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba($color-black, 0.03);
  }

  &__img {
    background-color: $color-white;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__overlay:hover {
    background-color: rgba($color-black, 0.3);
  }

  &__btns {
    display: flex;
    margin-top: 20px;
    justify-content: center;
  }

  &__btn {
    flex-grow: 1;
    max-width: 300px;
    margin: 0 5px 0 0;
    height: 36px;
    border-radius: 4px;
    background: rgba($color-black, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba($color-black, 0.5);
    cursor: pointer;

    &:last-child {
      text-align: right;
      margin: 0 0 0 5px;
    }

    &.-disabled {
      cursor: inherit;
      color: rgba($color-black, 0.2);
    }
  }
}
