.g-form-header {
  color: $color-white;
  background-color: $color-orange-blaze;
  box-shadow: 0 2px 5px rgba($color-black, 0.2);
  padding: 16px 24px;
  height: 60px;
  line-height: 1;
  font-size: 22px;
  font-weight: 300;
  display: flex;
  align-items: center;
}
