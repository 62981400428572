.filter-set-manager {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__content {
    flex: 1;
    overflow-y: auto;
  }

  &__counts {
    display: block;
    padding: 16px 16px 0;
  }

  &__toggle {
    display: flex;
    align-items: stretch;
    background-color: #f2f2f2;
    height: 36px;

    &-option {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 8px;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;

      &.-selected {
        background: $color-blue-clear;
        color: $color-white;
        cursor: default;
      }
    }
  }
}
