.payment-method-editor {
  &__dialog {
    max-width: 370px;

    @media all and (max-width: 410px) {
      max-width: 90%;
    }
  }

  &__content {
    padding: 20px 25px;
  }
}
