html {
  background-color: $color-portal-background;
  height: 100%;
}

body {
  background: none;
  height: 100%;
  position: relative;
}

body,
.app-view,
%grow-vertical {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}

// main containers
.main,
.content-container,
%content-container {
  margin: 0 auto;
  max-width: $max-content-width;
  padding: 1rem 20px;
  width: 100%;

  &-header {
    align-items: center;
    display: flex;
    margin-bottom: 16px;
  }

  &-header-actions {
    margin-left: auto;
  }

  &-subheader {
    font-size: 16px;
    margin: -22px 0 16px;
  }
}

@media (min-width: 414px) {
  .main {
    padding: 1rem 12px;
  }
}

@media (min-width: 640px) {
  .main {
    padding: 1rem;
  }
}

// panel container mixin
%container-white {
  background-color: $color-white;
  border: 1px solid $color-grey-soft;
  border-radius: 2px;
  padding: 1rem;
}

@media (min-width: 640px) {
  %container-white {
    padding: 1rem 2rem;
  }
}

// site wide utility classes
.no-scroll {
  overflow: hidden;
}

.view-loading {
  display: block;
  margin-top: 30px;
  text-align: center;
}

.error-message {
  font-weight: 300;
  margin: 30px;
  padding: 16px 32px;
  text-align: center;
}
