.channel-form-panel {
  &__warning {
    padding: 10px;
    border-radius: 4px;
    background: $color-blue-clear;
    color: $color-white;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    margin: 0 0 16px;
  }

  &__filter {
    display: flex;
    align-items: center;
    margin: 0 0 3px;

    &:first-child {
      margin-top: 24px;
    }

    &-btn {
      // ...
    }

    &-label {
      flex: 1;
      margin-left: 4px;
    }

    &-options {
      flex: 0 270px;
      max-width: 270px;
    }

    &-range {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &-select {
      display: block;
      margin-left: 3px;
    }

    &-range-field {
      margin-left: 3px;
    }
  }

  &__summary {
    margin: 24px 0 0 0;

    &-title {
      font-size: 14px;
      font-weight: 500;
      margin: 0 0 10px;
    }

    &-text {
      line-height: 1.2;
    }

    b {
      color: $color-orange-blaze;
    }
  }

  &__id {
    font-size: 11px;
    color: rgba($color-black, 0.4);
    margin-top: 24px;
  }

  &__split {
    display: flex;
    align-items: flex-start;
    margin: -24px -5px;

    &.-two > div {
      flex-basis: 50%;
    }

    &.-three > div {
      flex-basis: 33.3333%;
    }

    &-item {
      padding: 0 5px;
      min-width: 0; // this fixes overflowed dropdown text issue
    }
  }

  &__checkbox {
    margin-top: 24px;
  }
}
