.survey {
  $side-width: 450px;
  padding-top: 60px;

  &__header {
    background: $color-white;
    box-shadow: 0 1px 4px rgba($color-black, 0.15);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 16px;
  }

  &__header-left {
    flex: 1;
    min-width: 0;
    display: flex;
  }

  &__header-middle {
    flex: 1;
    margin: 0 10px;
    display: flex;
    justify-content: center;
  }

  &__header-right {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    &-item {
      margin-left: 8px;
    }
  }

  &__title {
    @extend %ellipsis;
    margin: 0 0 0 10px;
  }
}
