.report-view-question {
  padding: 40px;
  position: relative;
  transition: all 0.15s ease-out;

  &__head {
    display: flex;
    align-items: flex-start;
    margin: 0 0 40px;
  }

  &__head-main {
    flex: 1;
  }

  &__head-actions {
    display: flex;
    align-items: center;
  }

  &__title {
    font-size: 25px;
    line-height: 1.2;
    p {
      margin: 0;
    }
  }

  &__subtitle {
    font-size: 17px;
    color: rgba($color-black, 0.4);
  }

  &__jump {
    width: 200px;
    margin: 0 0 0 12px;
  }

  &__separator {
    height: 36px;
    border-left: 1px solid rgba($color-black, 0.05);
    margin: 0 12px;
  }

  &__btn {
    margin: 0 0 0 12px;
    color: rgba($color-black, 0.6);

    &:hover:not(.-disabled):not(.-selected) {
      cursor: pointer;
      color: $color-black;
    }

    &.-disabled {
      color: rgba($color-black, 0.2);
      pointer-events: none;
    }

    &.-selected {
      color: $color-orange-blaze;
    }

    &.-rotate {
      .gi-rotate-left {
        transform: rotate(270deg);
      }
      .gi-rotate-right {
        transform: rotate(180deg);
      }
    }
  }

  &__hidden {
    position: absolute;
    margin-top: -40px;
    left: 0;
    right: 0;
    background: rgba($color-black, 0.05);
    color: rgba($color-black, 0.4);
    padding: 3px;
    text-align: center;
    font-size: 14px;
  }

  &__loop-selector {
    max-width: 600px;
    margin: 0 auto 20px;
  }

  &__content {
  }

  &__content-main {
    background: white;
    padding: 50px;
    border-radius: 6px;
  }

  &__content-info {
    margin-top: 24px;
  }

  &__chart {
    display: block;
  }

  &__no-chart {
    text-align: center;
    padding: 40px 0;
    color: rgba(0, 0, 0, 0.3);
  }

  &__semantic-text {
    padding: 0 20px;
  }

  &__stats,
  &__stats {
    display: flex;
    justify-content: center;
  }

  &__switcher-wrapper {
    max-width: 600px;
    margin: 0 auto;
    .report-subtype-switcher {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__table {
    background: white;
    border-radius: 4px;
    margin: 20px auto 0;
  }

  &__table-tabs {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba($color-black, 0.1);

    &:last-child {
      border-bottom: 0;
    }
  }

  &__table-tab {
    flex-basis: 100%;
    flex-grow: 1;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: rgba($color-black, 0.5);
    line-height: 36px;
    height: 36px;
    border-right: 1px solid rgba($color-black, 0.1);

    &:hover {
      cursor: pointer;
    }

    &:last-child {
      border-right: 0;
    }

    &.-active {
      color: rgba($color-black, 0.8);
    }
  }

  &.-loading {
    opacity: 0.5;
  }

  @media all and (max-width: 900px) {
    &__head {
      flex-direction: column;
      align-items: stretch;
    }

    &__head-main {
      order: 1;
    }

    &__head-actions {
      margin: 0 0 24px;
      justify-content: flex-end;
    }
  }

  @media all and (min-width: 901px) {
    &__content-info {
      top: 100px;
      position: sticky;
      align-self: flex-start;
    }
  }
}

@media print {
  .survey__header {
    display: none;
  }

  .no-print {
    display: none;
  }
}
