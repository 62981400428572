@import './exporter/survey-exporter';
@import './loi/survey-loi';
@import './survey-settings/survey-settings';
@import './logic-summary/logic-summary';
@import './survey-outdated-bar/survey-outdated-bar';
@import './origin/viewer/origin-viewer';
@import './omni-tag/omni-tag';
@import './loop-dialog/loop-dialog';
@import './move-dialog/move-dialog';
@import './outlink/outlink';

$survey-editor__color-error: rgba(255, 0, 0, 0.1);

.survey-editor {
  $root: &;

  &__main {
    padding: 0 30px 30px;
    max-width: 1000px;
  }

  &__status {
    display: flex;
    background: $color-white;
    border-radius: 4px;
    transition: box-shadow 0.15s ease-out;
    padding: 25px;
    margin: 20px 0 0;
  }

  &__status-text {
    flex-grow: 1;
  }

  &__errors {
    margin: 0 0 10px;
  }

  &__editor {
    display: flex;
    align-items: flex-start;
    margin: 20px 0 0;
  }

  &__editor-form {
    flex-grow: 1;
  }

  &__block {
    background: $color-white;
    border-radius: 4px;
    transition: box-shadow 0.15s ease-out;
    position: relative;

    &.-margin-bottom {
      margin-bottom: 30px;
    }

    &.-margin-top {
      margin-top: 30px;
    }

    &.-clickable {
      &:hover {
        cursor: pointer;
        box-shadow: 0 3px 15px rgba($color-black, 0.1);
      }
    }

    &.-highlight-grey {
      border-left: 4px solid rgba($color-black, 0.2);
    }

    &.-highlight-red {
      border-left: 4px solid rgba($color-red-candy, 0.7);
    }

    &.-selected {
      outline: 1px solid rgba($color-black, 0.5);
    }
  }

  &__block-heading {
    display: flex;
    align-items: flex-start;
    padding: 15px 15px 0 25px;

    h4 {
      margin: 0;
    }
  }

  &__block-title {
    flex-grow: 1;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.4;

    // clamp title to 2 lines
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    // markdown will add p elements etc
    p {
      font-size: inherit;
      margin: inherit;
    }

    &.-intro {
      font-size: 24px;
    }
  }

  &__block-action {
    margin: 0 0 0 10px;

    .field-checkbox__box {
      margin-right: 0;
    }
  }

  &__block-content {
    padding: 25px 25px 25px;
    position: relative;
  }

  &__view.ng-enter {
    opacity: 0;
    transition: all 0.4s ease-in-out;

    #{$root}__block-group {
      opacity: 0;
      transform: scale(0.5);
      transition: all 0.4s ease-in-out;
    }

    &.ng-enter-active {
      opacity: 1;

      #{$root}__block-group {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  &__view.ng-leave {
    opacity: 1;
    transition: all 0.4s ease-in-out;

    #{$root}__block-group {
      opacity: 1;
      transform: scale(1);
      transition: all 0.4s ease-in-out;
    }

    &.ng-leave-active {
      opacity: 0;

      #{$root}__block-group {
        opacity: 0;
        transform: scale(0.5);
      }
    }
  }

  &__view-errors {
    margin: 25px 0 0 0;
  }

  &__view-title {
    // text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.3;
    margin: 0 0 16px;
  }

  &__view-content {
    padding: 0 15px 15px 25px;
    position: relative;

    &.-intro {
      padding: 0 25px 25px;
    }
  }

  &__view-subtitle {
    display: flex;
    align-items: center;

    &.-intro {
      margin: 0 0 16px;
    }
  }

  &__view-subtitle-text {
    // text-align: center;
    font-size: 14px;
    color: rgba($color-black, 0.5);
  }

  &__view-subtitle-gap {
    border-right: 1px solid rgba($color-black, 0.1);
    height: 15px;
    margin: 0 10px;
  }

  &__view-subtitle-media-icon {
    margin-right: 2px;
  }

  &__view-group {
    display: flex;
    align-items: center;

    &-text {
      font-size: 14px;
      color: rgba($color-black, 0.5);
    }
  }

  &__view-image {
    border-radius: 2px;
    max-width: 160px;
  }

  &__view-image-placeholder {
    border-radius: 2px;
    max-width: 160px;
    border: 1px solid $input-border-color;
  }

  &__view-image-hint {
    position: relative;
    padding-top: 75%;

    span {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      text-align: center;
      height: 16px;
      line-height: 1;
      color: rgba($color-black, 0.4);
    }
  }

  &__logic {
    background: $color-white;
    border-radius: 4px;
    cursor: pointer;
    margin: 2px 0 0;

    &.-active {
      border-left: 4px solid $color-orange-blaze;
    }
  }

  &__error {
    display: flex;
    align-items: flex-start;
    padding: 8px 15px;
    background: $color-white;
    border-radius: 4px;
    border-left: 4px solid $color-red-candy;
    margin: 2px 0;
  }

  &__error-icon {
    margin-right: 4px;
  }

  &__error-text {
    font-size: 14px;
    flex-grow: 1;
    line-height: 1.3;
  }

  &__add {
    padding: 10px 0;
    // position: relative;
    // text-align: center;

    &.-small {
      padding: 4px 0;
    }
  }

  &__add-btn {
    // background-color: rgba($color-black, .05);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba($color-black, 0.3);
    cursor: pointer;
    // display: inline-block;
    height: 30px;
    transition: background-color 0.3s ease-out;

    &:hover {
      background-color: rgba($color-black, 0.02);
    }

    &.-small {
      color: rgba($color-black, 0.1);
      height: unset;

      &:hover {
        color: rgba($color-black, 0.3);
      }
    }
  }

  &__add-icon {
    font-size: 22px;
    height: 30px;
    line-height: 1.3;
    vertical-align: middle;
    width: 30px;

    &.-small {
      font-size: 16px;
      height: unset;
      line-height: 1;
      width: unset;
    }
  }

  &__add-label {
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    margin: 0 25px 0 0;
    text-transform: uppercase;
    vertical-align: middle;
  }

  &__processing-text {
    color: rgba($color-black, 0.4);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__processing-spinner {
    display: inline-block;
    margin: 0 5px 0 0;
    transform: scale(0.6);
    vertical-align: middle;
  }

  &__editor-lock {
    background-color: rgba($color-black, 0.4);
    bottom: 0;
    left: 0;
    padding-top: 100px;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: $z-index-header - 1;
  }

  &__editor-lock-message-wrapper {
    display: flex;
    justify-content: center;
  }

  &__editor-lock-message {
    background-color: $color-white;
    border-radius: 2px;
    color: $color-grey-indifferent;
    flex-grow: 1;
    max-width: 400px;
    padding: 10px;
    text-align: center;

    h4 {
      font-weight: 500;
    }
  }

  &__side {
    position: sticky;
    top: 80px;
    flex-basis: 250px;
    flex-shrink: 0;
    margin: 0 0 0 20px;
  }

  &__control-box {
    display: flex;
    flex-direction: column;
    background: $color-white;
    border-radius: 4px;
  }

  &__editor-title {
    padding: 20px 20px 0;
    font-weight: 500;
  }

  &__actions {
    padding: 20px;
    display: flex;
    flex-direction: column;

    g-btn:not(:first-child) {
      margin-top: 10px;
    }
  }

  &__settings {
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding: 20px;

    g-btn:not(:first-child) {
      margin-top: 10px;
    }
  }

  &__settings-icons {
    display: flex;
    flex-direction: row;
    justify-content: center;

    g-btn:not(:first-child) {
      margin-top: 0;
    }

    g-btn:not(:nth-last-child(-n + 3)) {
      margin-right: 5px;
    }

    .g-btn__content {
      padding: 0 10px;
    }

    .g-btn__icon {
      margin-right: 0;
    }
  }

  &__settings-icons-gap {
    border-right: 1px solid rgba($color-black, 0.1);
    margin: 0 5px;
  }

  &__settings-info {
    text-align: center;
    margin-top: 5px;
  }

  &__loi {
    padding: 20px 20px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }

  &__survey-length {
    padding: 5px 20px 20px;
  }

  &__survey-cta {
    padding: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);

    &-title {
      font-size: 16px;
      font-weight: 500;
    }

    &-text {
      font-size: 14px;
      line-height: 1.3;
    }
  }

  &__queue {
    margin: 20px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media all and (max-width: 600px) {
    &__editor {
      flex-direction: column;
      align-items: center;
    }

    &__side {
      order: -1;
      margin: 0 0 20px;
      position: inherit;
    }
  }
}
