.survey-response-delete {
  &__wrapper {
    padding: 8px 60px;
  }

  &__deleted {
    margin-bottom: 5px;

    label {
      border: 1px solid;
      border-radius: 4px;
      color: $color-red-candy;
      font-size: 0.75rem;
      padding: 2px 5px;
    }
  }

  &__warning-wrapper {
    margin-bottom: 5px;
    text-align: right;
  }

  &__warning {
    color: $color-red-candy;
  }

  &__form-group {
    margin-bottom: 5px;
  }

  &__form-actions {
    display: flex;
    justify-content: flex-end;

    g-btn {
      margin: 0 0 0 10px;
    }
  }
}
