gl-masonry,
gl-masonry-size,
gl-masonry-item {
  display: block;
}

gl-masonry-size,
gl-masonry-item {
  float: left;
}

gl-masonry-size {
  // This property is weird
  // It looks like it needs to be divisible by any of the gl-masonry-items displayed
  // Eg, we currently display 50% and 100% width items so 50% works but (randomly) 28% does not
  width: 50%;
}
