.page {
  $sidebar-width: 300px;
  $sidebar-width-mobile: 340px;

  display: flex;
  align-items: flex-start;

  &__content-wrapper {
    flex-grow: 1;
  }

  &__content {
    max-width: 1300px;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 7;
    background: rgba($color-black, 0.2);
  }

  &__sidebar {
    flex-basis: $sidebar-width;
    max-width: $sidebar-width;
    flex-shrink: 0;
    margin: 0 0 0 40px;
    position: sticky;
    top: 90px;

    &.-fixed {
      position: fixed;
      top: 60px;
      right: -$sidebar-width-mobile;
      bottom: 0;
      overflow-y: auto;
      z-index: 8;
      margin: 0;
      width: $sidebar-width-mobile;
      flex-basis: $sidebar-width-mobile;
      max-width: $sidebar-width-mobile;
      background: #e5e5eb;
      padding: 20px;
    }

    &.ng-enter,
    &.ng-leave {
      transition: right ease-out 0.3s;
    }

    &.-open-add,
    &.-open-remove {
      transition: right ease-out 0.3s;
    }

    &.-open,
    &.-open-add.-open-add-active {
      right: 0;
    }

    &.-open-remove.-open-remove-active {
      right: -$sidebar-width;
    }

    // &.-open {
    //   right: 0;

    // }
  }

  &__fab {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 7;
    width: 50px;
    height: 50px;
    border-radius: 30px;
    background: $color-white;
    box-shadow: 0 2px 15px rgba($color-black, 0.2);

    &:hover {
      background: darken($color-white, 1%);
      cursor: pointer;
    }
  }
}
