.beacon-manager {
  &__header {
    display: flex;
    align-items: center;
    margin: 0 0 30px;
    justify-content: space-between;
  }

  &__search {
    display: flex;
    align-items: center;
    background: $color-white;
    border-radius: 4px;
    height: 36px;
    padding: 0 5px;
    width: 100%;
    max-width: 320px;

    g-icon {
      line-height: 0;
      margin: 0 5px 0 0;
    }

    input {
      display: block;
      outline: 0;
      border: 0;
      flex-grow: 1;
      height: 100%;

      &::placeholder {
        color: rgba($color-black, 0.4);
      }
    }
  }

  &__item {
    display: block;
    margin: 0 0 10px;
  }

  &__zero {
    padding: 50px;
    text-align: center;
  }

  @media all and (max-width: 520px) {
    &__header {
      display: block;
    }

    &__search {
      max-width: inherit;
      margin: 0 0 10px;
    }
  }
}
