.report-view-section {
  padding: 40px;
  position: relative;
  transition: all 0.15s ease-out;

  &__head {
    display: flex;
    align-items: flex-start;
    margin: 0 0 40px;
  }

  &__head-main {
    flex: 1;
  }

  &__head-actions {
    display: flex;
    align-items: center;
  }

  &__title {
    font-size: 25px;
    line-height: 1.2;
    p {
      margin: 0;
    }
  }

  &__subtitle {
    font-size: 17px;
    color: rgba($color-black, 0.4);
  }

  &__content {
  }

  &__content-main {
    background: white;
    padding: 50px;
    border-radius: 6px;
  }

  &.-loading {
    opacity: 0.5;
  }

  @media all and (max-width: 900px) {
    &__head {
      flex-direction: column;
      align-items: stretch;
    }

    &__head-main {
      order: 1;
    }

    &__head-actions {
      margin: 0 0 24px;
      justify-content: flex-end;
    }
  }

  @media all and (min-width: 901px) {
    &__content-info {
      top: 100px;
      position: sticky;
      align-self: flex-start;
    }
  }
}

@media print {
  .survey__header {
    display: none;
  }

  .no-print {
    display: none;
  }
}
