.consume-invite {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 60px 0;
    max-width: 100%;
  }

  &__icon {
    margin: 0 0 8px;
    color: rgba($color-black, 0.2);
  }

  &__message {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 18px;
  }

  &__actions {
    margin: 40px 0 0;

    > :first-child {
      margin: 0 50px 0 0;
    }
  }

  &__zero {
    display: block;
    margin: 100px auto 50px;
    max-width: 300px;
  }
}
