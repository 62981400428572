.report-media-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  > span {
    margin-left: 4px;
    font-size: 15px;
  }
}
