.publication-offer {
  display: flex;
  justify-content: center;

  &__box {
    position: fixed;
    top: 75px;
    right: 15px;
    background: rgba($color-white, 0.95);
    border-radius: 4px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: flex-start;
    padding: 15px;
    max-width: 259px;
    z-index: 70;
  }

  &__content {
    // ...
  }

  &__icon {
    margin: 0 10px 0 0;
  }

  &__text {
    font-size: 14px;
  }

  &__link {
    font-size: 14px;
    color: $color-orange-glow;

    &:hover,
    &:active,
    &:focus {
      color: $color-orange-glow;
    }
  }

  &__hide {
    margin: 0 0 0 15px;
  }

  @media all and (max-width: 900px) {
    &__box {
      border-radius: 0;
      top: inherit;
      bottom: 0;
      left: 0;
      right: 0;
      max-width: inherit;
      background: $color-white;
      box-shadow: 0 1px 15px rgba($color-black, 0.2);
    }

    &__content {
      flex-grow: 1;
    }
  }
}
