$min-module-list-item-width: 100px;

gl-sp-module-list {
  position: relative;
}

.module-list {
  display: flex;
  margin: 0 auto;
  max-width: $max-content-width;
  text-align: center;

  &-item {
    border-right: 2px solid transparent;
    color: $color-white;
    display: block;
    font-size: 0.95rem;
    min-width: $min-module-list-item-width;
    padding: 10px;
    white-space: nowrap;

    .module-icon {
      font-size: em(40);
      line-height: 1;
    }

    .module-name {
      margin-top: -5px;
    }

    &:hover,
    &:focus {
      color: $color-white;
    }

    &.active {
      background-color: rgba($color-black, 0.3);
      border-bottom: 2px solid $color-green-seafoam;
      color: $color-white;
    }
  }
}
