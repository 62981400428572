$btn-border-radius: 3px !default;
$btn-padding: 7px 12px !default;

$button-disabled-color: rgba(0, 0, 0, 0.26);
$button-disabled-background-color: none;

$spinner-size: 20px;

%base-btn,
[type='submit'] {
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  padding: 0;
  user-select: none;

  &[disabled],
  &[disabled]:hover {
    background: $button-disabled-background-color;
    border-color: $color-grey-soft;
    color: $button-disabled-color;
    cursor: not-allowed;
  }
}

.gl-btn,
%gl-btn {
  @extend %base-btn;

  background-color: $color-white;
  border: 1px solid $color-grey-soft;
  border-radius: $btn-border-radius;
  color: $base-font-color;
  display: inline-block; // so we can use a.gl-btn-*
  font-size: 0.875rem;
  line-height: 20px;
  min-width: 120px;
  padding: $btn-padding;
  position: relative;
  text-align: center;
  // transition: all .2s $swift-ease-in-out-timing-function;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  // enlarge hit-box
  &::after {
    bottom: -6px;
    content: '';
    left: -4px;
    position: absolute;
    right: -4px;
    top: -6px;
  }

  &:hover,
  &:focus {
    border-color: $color-grey-silver;
  }

  &:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  &-block {
    display: block;
    width: 100%;

    .gl-form-actions & {
      margin-top: 8px;
    }
  }

  &-spinner {
    display: inline-block;
    height: $spinner-size;
    margin-top: -15px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 26px;
    top: 50%;
    width: $spinner-size;
    z-index: 2;
  }

  &.active {
    background-color: $color-blue-clear;
    border-color: $color-blue-clear;
    color: $color-white;
  }

  &.loading {
    padding-right: 56px;

    .gl-btn-spinner {
      opacity: 1;
    }
  }

  .gi {
    font-size: 1.5em;
    margin-right: 5px;
    vertical-align: middle;
  }
}

.gl-btn-primary {
  @extend %gl-btn;

  background-color: $color-green-seafoam;
  border-color: $color-green-seafoam;
  color: $color-white;

  &:hover,
  &:focus {
    background-color: darken($color-green-seafoam, 3);
    border-color: $color-green-seafoam;
    color: $color-white;
  }
}

.gl-btn-success {
  @extend %gl-btn;

  &:hover,
  &:focus {
    border-color: $color-green-seafoam;
    color: $color-green-seafoam;
  }
}

.gl-btn-danger {
  @extend %gl-btn;

  &:hover,
  &:focus {
    border-color: $color-red-candy;
    color: $color-red-candy;
  }
}

.gl-btn-none,
.gl-btn-link {
  @extend %gl-btn;

  background-color: transparent;
  border-color: transparent;

  &:hover,
  &:focus {
    border-color: transparent;
    color: $color-blue-clear;
  }

  &:active {
    box-shadow: none;
  }
}

.gl-btn-action,
%gl-btn-action {
  @extend %gl-btn;

  background-color: transparent;
  border-color: transparent;
  color: $base-font-color;
  min-width: 30px;
  padding: 7px;

  &:hover,
  &:focus {
    border-color: transparent;
    color: $color-blue-clear;
  }

  &:active {
    box-shadow: none;
  }

  &[disabled],
  &[disabled]:hover {
    border: inherit;
  }

  .gi {
    margin: 0;
  }
}

.gl-btn-link {
  &:hover {
    text-decoration: underline;
  }
}

.gl-btn-action-danger {
  @extend %gl-btn-action;

  &:hover,
  &:focus {
    color: $color-red-candy;
  }
}
