.survey-report-counts {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &__main {
    display: flex;
    align-items: center;
  }

  &__text {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    color: rgba($color-black, 0.4);
    margin-right: 6px;
  }

  &__num {
    font-size: 20px;
    font-weight: 500;
    margin-right: 6px;
    line-height: 1;
  }

  &__deleted {
    font-size: 11px;
    font-weight: 500;
    margin: 4px 0 0 0;
  }
}
