.report-matrix-bar-chart {
  &__aspect-container {
    display: flex;
    align-items: center;
    margin: 0 0 20px;
  }

  &__aspect-dropdown {
    flex-grow: 1;
    min-width: 0; // this fixes overflowed dropdown text issue
  }

  &__aspect-actions {
    display: flex;

    g-icon-button {
      margin: 0 0 0 12px;
    }
  }

  &__chart-container.-show-all {
    display: flex;
    flex-wrap: wrap;
  }

  &__chart-wrapper.-show-all {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-bottom: 1px solid rgba($color-black, 0.1);

    // selects odd children eg 1, 3, 5
    &:nth-child(odd) {
      padding: 0 30px 15px 0;
      border-right: 1px solid rgba($color-black, 0.1);
    }

    // selects even children eg 2, 4, 6
    &:nth-child(even) {
      padding: 0 0 15px 30px;
    }

    // selects last child and second to last child but only when it's odd
    &:last-child,
    &:nth-last-child(2):nth-child(odd) {
      border-bottom: none;
      padding-bottom: 0;
    }

    @media all and (max-width: 1100px) {
      width: 100%;

      // selects all children
      &:nth-child(n) {
        padding: 0 0 15px;
        border-right: none;
      }

      // select second to last child but only when it's odd
      &:nth-last-child(2):nth-child(odd) {
        padding: 0 0 15px;
        border-bottom: 1px solid rgba($color-black, 0.1);
      }

      // select last child
      &:last-child {
        padding: 0;
        border-bottom: none;
      }
    }
  }

  &__chart-title {
    text-align: center;
    padding: 30px 0 15px 0;
  }

  &__no-results {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .gi {
      color: rgba($color-black, 0.24);
      font-size: 6rem;
      line-height: 1;
    }
  }
}
