@import './cell-builder/panel-target-cell-builder';
@import './preset-editor/panel-target-preset-editor';

.panel-target-editor {
  &__zero {
    font-size: 14px;
    padding: 20px;
    color: rgba($color-black, 0.3);
  }

  &__table {
    border: 1px solid lighten($color-black, 90%);
    border-radius: 4px;
    margin: 0 0 24px;

    &-inner {
      border: 0;
      margin: 0;
      padding: 0;
    }

    &-head {
      border: 0;
    }

    &-row {
      border: 0;

      &:nth-child(odd) {
        background: rgba($color-black, 0.02);
      }
    }

    &-cell {
      border: 0;
      padding: 0 10px;
      height: 44px;
    }
  }

  &__attribute-title {
    font-size: 15px;
    font-weight: 500;
  }

  &__count-input {
    width: 97px;
    height: 28px;
    display: inline-block;

    // HACK: hijack field-text height
    .field-text {
      min-height: inherit;
      height: 28px;
    }
  }

  &__percent {
    display: inline-block;
    margin-left: 13px;
    color: rgba($color-black, 0.4);
    width: 56px;
    text-align: left;
  }

  &__interlock-select {
    display: inline-block;

    // HACK: hijack field-select height
    .field-select__control {
      height: 28px;
    }
  }

  &__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-left: 8px;
    cursor: pointer;

    &.-disabled {
      pointer-events: none;
      color: rgba($color-black, 0.3);
    }
  }

  &__btn2 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: rgba($color-black, 0.05);
    width: 28px;
    height: 28px;
    border-radius: 4px;
    cursor: pointer;

    &.-disabled {
      pointer-events: none;
      color: rgba($color-black, 0.3);
    }
  }

  &__add-btn {
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    > span {
      margin-left: 4px;
    }
  }

  &__toggle {
    $toggle: &;

    display: inline-flex;
    align-items: stretch;
    background-color: rgba($color-black, 0.05);
    border-radius: 4px;
    height: 36px;
    margin: 5px 0 24px;

    &-option {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 8px;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;

      &.-selected {
        background: $color-blue-clear;
        color: $color-white;
        border-radius: 4px;
        cursor: default;
      }
    }

    &.-full {
      width: 100%;
    }

    &.-inline {
      margin: 0;
    }

    &.-small {
      height: 28px;

      // #{$toggle}-option {
      //   padding: 0 12px;
      // }
    }

    &.-disabled {
      pointer-events: none;

      #{$toggle}-option {
        color: rgba($color-black, 0.3);

        &.-selected {
          color: rgba($color-white, 0.5);
        }
      }
    }
  }

  &__count {
    width: 97px; // same as field-text above them
    margin-right: 92px; // push left to be under field-text
    text-align: left;

    &.-compact {
      // used when there is no X in the above rows
      margin-right: 61px;
    }

    &-amount {
      // ...

      &.-error {
        font-weight: 500;
        color: $color-red-candy;
      }
    }

    &-total {
      color: rgba($color-black, 0.3);
    }
  }

  &__presets-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 16px; // matches field-label
    margin-bottom: 5px; // matches field-label
  }

  &__presets-spacer {
    flex: 1;
  }

  &__presets-action {
    margin-left: 10px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
  }

  &__line {
    border-top: 1px solid rgba($color-black, 0.1);
    margin: 20px 0;
  }
}
