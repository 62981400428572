.home {
  padding: 100px 0 0 0;

  &__inset {
    padding: 0 40px;
    &.-bg-trusted {
      background: #f5fafc;
    }
  }

  &__content {
    max-width: 968px;
    margin: 0 auto;
  }

  &__fader {
    background: linear-gradient(
      180deg,
      rgba($color-black, 0.02) 0%,
      rgba($color-black, 0) 100%
    );
  }

  &__link {
    color: $color-orange-blaze;
    font-weight: 400;

    &:hover,
    &:focus {
      color: darken($color-orange-blaze, 10%);
    }
  }

  &__anchor {
    position: relative;
    top: -60px;
  }

  &__logo {
    // ...
  }

  &__tagline {
    font-size: 50px;
    font-weight: 600;
    text-align: center;
    line-height: 1.3;
    margin: 24px 0;

    > span {
      color: $color-orange-blaze;
      font-weight: 600;
    }
  }

  &__subtagline {
    font-size: 20px;
    font-weight: 300;
    text-align: center;
  }

  &__features {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 70px -35px;
  }

  &__feature {
    flex-grow: 1;
    flex-basis: 100px;
    margin: 0 35px;

    &-name {
      font-size: 20px;
      font-weight: 500;
      margin: 16px 0;
    }

    &-desc {
      font-size: 16px;
      font-weight: 300;
      line-height: 1.3;
      margin: 16px 0;
    }
  }

  &__cta {
    background: rgba($color-black, 0.05);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
    margin: 70px 0;

    &-text {
      font-size: 25px;
      margin: 0 16px 0 0;

      > span {
        color: $color-orange-blaze;
        font-weight: 600;
      }
    }
  }

  &__pricing {
    padding: 64px 0;

    &-title {
      text-align: center;
      font-size: 40px;
      font-weight: 500;
      margin-bottom: 20px;

      &.-bolder {
        font-weight: 600;
      }
      &.-margin-top {
        margin-top: 50px;
      }
    }

    &-subtitle {
      font-size: 18px;
      font-weight: 300;
      text-align: center;
      line-height: 1.3;

      &.-margin-top {
        margin: 20px 0 0;
      }
      > b {
        font-weight: 500;
      }
    }

    &-terms {
      font-size: 16px;
      font-weight: 300;
      text-align: center;
      margin: 20px 0 40px;
    }
  }

  &__footer {
    text-align: center;
    padding: 40px 0;

    &-text {
      font-size: 25px;
      font-weight: 400;
      margin: 0 0 24px;
    }
  }

  &__trusted {
    padding: 50px;
    &-title {
      text-align: center;
      font-size: 40px;
      font-weight: 500;
      margin: 0 0 40px;
    }
    &-items {
      display: flex;
      flex-wrap: wrap;
      margin: -20px -20px 70px;
    }
    &-item {
      flex-basis: 25%;
      padding: 20px;
      text-align: center;
      img {
        display: inline-block;
        max-height: 70px;
      }
    }
    @media all and (max-width: 770px) {
      &-item {
        flex-basis: 33.333%;
      }
    }
    @media all and (max-width: 600px) {
      &-item {
        flex-basis: 50%;
      }
    }
  }

  @media all and (max-width: 710px) {
    &__features {
      flex-direction: column;
    }

    &__feature {
      margin-bottom: 35px;
    }
  }
}
