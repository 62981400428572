.invoice {
  padding: 10px 24px 24px;
  display: flex;
  flex-direction: column;
  font-size: 14px;

  &__bar {
    text-align: center;
    color: white;
    font-size: 13px;
    font-weight: 500;
    padding: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    &.-red {
      background: $color-red-candy;
    }

    &.-green {
      background: $color-green-seafoam;
    }

    &.-orange {
      background: $color-orange-glow;
    }
  }

  &__dialog {
    max-width: 600px;
  }

  &__description {
    margin: 0 0 10px;
    font-weight: 500;
    font-size: 20px;
  }

  &__header {
    display: flex;
    font-size: 12px;

    &-left {
      flex-grow: 1;
      flex-shrink: 0;
    }

    &-right {
      text-align: right;
    }
  }

  &__lines-header {
    display: flex;
    margin: 20px 0 0;
    border-bottom: 1px solid darken($color-grey-soft, 15);

    &-item {
      flex-grow: 1;
    }

    &-extra {
      flex-basis: 110px;
      text-align: right;
    }
  }

  &__lines {
    flex-basis: 200px;
    font-size: 14px;
  }

  &__line {
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid $color-grey-soft;

    &-description {
      flex-grow: 1;
    }

    &-sublabel {
      font-size: 12px;
      color: $color-grey-silver;
    }

    &-value {
      text-align: right;
      width: 110px;
      align-self: flex-end;
    }
  }

  &__footer {
    display: flex;
    border-top: 1px solid darken($color-grey-soft, 15);
    padding: 10px 0 0;

    &-left {
      font-size: 12px;
      flex-grow: 1;
    }
  }

  &__footer-row {
    display: flex;
    justify-content: flex-end;

    &-label {
      font-weight: 500;
    }

    &-value {
      text-align: right;
      min-width: 120px;
    }
  }

  &__manage {
    &-title {
      margin: 19px 0 6px;
      font-size: 14px;
      font-weight: 500;
    }

    &-row {
      display: flex;
      align-items: flex-start;
      margin: 0 -5px 10px;

      > div {
        flex-grow: 1;
        margin: 0 5px;
      }
    }

    &-actions {
      margin-top: 20px;
    }
  }
}
