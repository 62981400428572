.survey-card {
  &__front {
    // display: flex;
    // flex-direction: column;
  }

  &__square {
    position: relative;
    padding-bottom: 75%;
  }

  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba($color-blue-slate, 0.25);
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__title {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 55px;
    font-size: 17px;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;
    padding: 5px 10px;
  }

  &__subtitle {
    font-size: 12px;
    line-height: 1;
    font-weight: 400;
    text-align: center;
    margin: 0 0 12px;
  }

  &__buttons {
    border-top: 1px solid rgba($color-black, 0.1);
    display: flex;
    align-items: center;
    padding: 12px;
  }

  &__button {
    flex-grow: 1;
  }

  &__info {
    position: absolute;
    top: 8px;
    right: 8px;
    color: white;
    text-shadow: 0 1px 5px rgba($color-black, 0.4);
    z-index: 1;

    &:hover {
      cursor: pointer;
    }
  }

  &__back {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__back-close {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  &__back-info {
    font-size: 14px;
    color: rgba($color-black, 0.5);
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }

  &__action {
    margin: 0 0 8px;
    font-size: 14px;
  }
}
