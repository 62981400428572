.response-filter-group {
  &__or {
    height: 36px;
    display: flex;
    align-items: center;
    margin: 10px 0 14px;

    &-bar {
      flex: 1;
      border-top: 1px solid rgba($color-black, 0.1);
    }

    &-label {
      font-size: 14px;
      font-weight: 500;
      margin: 0 16px;

      &.-disabled {
        opacity: 0.54;
      }
    }
  }

  &__rule {
    display: flex;
    align-items: center;
    margin: 0 0 6px;
  }

  &__rule-ui {
    flex: 1;
    margin-right: 20px;
    min-width: 0; // fix overflow
  }

  &__rule-op {
    width: 64px;
    height: 36px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;

    &.-disabled {
      opacity: 0.54;
    }
  }

  &__rule-add {
    width: 64px;
  }

  &__rule-remove {
    margin-left: 16px;
    color: rgba($color-black, 0.4);
    line-height: 0;
    &:hover {
      cursor: pointer;
      color: rgba($color-black, 0.7);
    }
  }
}
