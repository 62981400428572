.g-zero {
  display: flex;
  justify-content: center;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
  }

  &__image {
    width: 100%;
    margin: 0 0 30px;
  }

  &__icon {
    margin: 0 0 8px;
    color: rgba($color-black, 0.2);
  }

  &__title {
    text-align: center;
    font-size: 21px;
    line-height: 1.1;
    margin: 0 0 8px;
  }

  &__message {
    text-align: center;
    font-size: 15px;
    line-height: 1.3;
  }
}
