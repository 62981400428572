.g-card {
  perspective: 1000;

  &__flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;

    &.-flip {
      transform: rotateY(180deg);
    }
  }

  &__front,
  &__back {
    display: flex;
    flex-direction: column;
    background: $color-white;
    border-radius: 4px;
    position: relative;
    backface-visibility: hidden;
    z-index: 1;
    overflow: hidden;
    box-shadow: 0 2px 3px rgba($color-black, 0.05);
    transition: box-shadow 0.15s ease-out;

    &.-clickable:hover {
      box-shadow: 0 4px 6px rgba($color-black, 0.05);
      cursor: pointer;
    }

    &.-border {
      border: 1px solid rgba($color-black, 0.1);
    }
  }

  &__back {
    height: 0; // height is set in the controller
    position: absolute;
    top: 0;
    width: 100%;
    transform: rotateY(180deg);
  }
}
