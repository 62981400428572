.report-legend {
  text-align: center;
  line-height: 1.2;

  &__item {
    display: inline-flex;
    align-items: center;
    margin: 0 4px;

    &:hover {
      cursor: pointer;
    }

    &.-hidden {
      opacity: 0.5;
    }
  }

  &__color {
    color-adjust: exact; // for print style
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 4px 0 0;
  }

  &__value {
    @extend %ellipsis;
    max-width: 140px;
    line-height: 1;
    font-size: 12px;
    font-weight: 500;
  }
}
