.question-chart {
  &__container {
    position: relative;
    height: 380px;

    &.-with-resizer {
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  &__settings {
    position: absolute;
    top: -22px;
    right: -13px;
  }
  &__cog {
    color: rgba(0, 0, 0, 0.15);
    line-height: 1;
    cursor: pointer;
    padding: 10px; /*hitbox*/
    &:hover {
      color: rgba(0, 0, 0, 0.3);
    }
  }
  &__settings-popover {
    padding: 0 20px 9px;
    /* labels are badly spaced, just override 'em */
    > field-label .field-label {
      margin-top: 9px;
    }
    .field-checkbox {
      margin-bottom: 9px;
    }
    .field-text {
      margin-bottom: 9px;
    }
  }
  &__settings-title {
    margin: 18px 0 9px;
    font-weight: 600;
  }

  &__cutout-labels {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -20px;
    line-height: 19px;
    text-align: center;
    pointer-events: none;
  }

  &__cutout-title {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  &__cutout-subtitle {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.3);
  }
}
