.survey-explorer-prefilter {
  position: relative;
  &__btn {
    background: white;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 6px 0 8px;
  }
  &__btn-label {
    font-size: 15px;
    line-height: 1;
  }
  &__btn-global {
    line-height: 1;
    margin: 0 0 0 0;
  }
  &__btn-caret {
    line-height: 1;
    margin: 0 0 0 0;
    color: rgba(0, 0, 0, 0.3);
  }
  &__menu {
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    min-width: 200px;
    padding: 4px 0;
    z-index: 1;
  }
  &__menu-option {
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 12px;
    &:hover {
      cursor: pointer;
      background: rgba(0, 0, 0, 0.05);
    }
  }
}
