.panel-target-cell-builder {
  padding: 24px;

  &__dialog {
    max-width: 400px;
  }

  &__range-inputs {
    display: flex;
    align-items: flex-start;
    margin: 0 -10px;

    > div {
      flex: 1;
      padding: 0 10px;
    }
  }

  &__btns {
    margin: 40px 0 0;
  }

  &__btn {
    margin: 0 8px 0 0;
  }
}
