.channel-reward-info {
  padding: 16px 24px;

  &__dialog {
    max-width: 550px;
  }

  &__section {
    display: flex;
  }

  &__text {
    margin: 0 0 10px;
  }

  &__image-wrapper {
    flex-basis: 120px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 90px;
      margin: 0 0 10px;
    }
  }

  &__table {
    margin: 0 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;

    table {
      border-collapse: separate;
      table-layout: fixed;
      text-align: center;
      border: 1px solid rgba($color-black, 0.1);
      border-radius: 4px;
    }

    thead td {
      font-weight: 500;
      border-bottom: 1px solid rgba($color-black, 0.1);
    }

    td {
      border: none;
      padding: 5px 0;

      &:not(:first-child) {
        border-left: 1px solid rgba($color-black, 0.1);
      }
    }

    tr {
      &.-highlighted {
        background-color: $color-blue-clear;
        color: $color-white;
        font-weight: 500;

        td:not(:first-child) {
          border-left: none;
        }
      }
    }
  }

  &__terms {
    margin: 10px 0 0;
  }
}
