.response-details-dialog {
  &__dialog {
    max-width: 420px;
  }

  &__content {
    padding: 24px 0 14px;
  }

  &__points {
    padding: 0 24px;
    margin: 0 0 24px;
  }

  &__point {
    display: flex;
    align-items: center;
  }

  &__point-name {
    flex: 1;
    margin-right: 8px;
    font-size: 14px;
    font-weight: 500;
  }

  &__point-value {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);
  }

  &__stats {
    padding: 0 24px;
    margin: -4px -4px 24px;
  }

  &__question {
    padding: 12px;
    margin: -6px 12px;
    border-radius: 4px;
    border: 2px solid transparent;
    &.-highlight {
      border-color: $color-blue-clear;
    }
  }

  &__question-title {
    font-weight: 500;
  }

  &__question-subtitle {
    margin: 0 0 8px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
  }

  &__question-heading {
    font-size: 13px;
    font-weight: 500;
    color: rgba($color-black, 0.7);
    margin: 20px 0 3px;
  }

  &__question-selection {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 6px 8px;
    margin: 0 0 6px;
    display: flex;
    &-label {
      flex: 1;
      font-size: 14px;
      color: rgba($color-black, 0.6);
    }
    &-info {
      margin-left: 16px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.3);
    }
  }

  &__question-text {
    font-size: 15px;
    font-style: italic;
    color: rgba($color-black, 0.6);
    margin: 0 0 6px;
  }

  &__question-image {
    line-height: 0;
    img {
      border-radius: 4px;
      max-width: 60px;
      max-height: 60px;
      cursor: pointer;
    }
  }

  &__question-empty {
    font-size: 14px;
    color: rgba($color-black, 0.3);
    margin: 0 0 6px;
  }
}
