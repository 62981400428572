.g-switch {
  $root: &;

  $pin-size: 20px;
  $inset: 2px;
  $height: $pin-size + ($inset * 2);
  $width: 40px;

  display: inline-block;
  line-height: 0;

  &__container {
    display: inline-block;
    line-height: 1;
    height: $height;
    width: $width;
    border-radius: $height / 2;
    background-color: rgba($color-black, 0.56);
    position: relative;
    cursor: pointer;
  }

  &__pin {
    position: absolute;
    top: $inset;
    left: $inset;
    background-color: $color-white;
    width: $pin-size;
    height: $pin-size;
    border-radius: $pin-size / 2;
    transition: all 0.1s ease-out;
  }

  &__container.-active {
    background-color: $color-blue-clear;

    #{$root}__pin {
      left: $width - $pin-size - $inset;
    }
  }

  &__container.-disabled {
    background-color: rgba($color-black, 0.1);

    // #{$root}__pin {
    //     left: $width - $pin-size - $inset;
    // }
  }
}
