.response-filter-dialog {
  &__dialog {
    max-width: 780px;
  }

  &__content {
    padding: 24px;
  }

  &__actions {
    border-top: 1px solid rgba($color-black, 0.1);
    padding: 20px 24px;
    display: flex;
    align-items: center;
  }

  &__action {
    margin-right: 8px;
  }
}
