.link-editor-dialog {
  &__checkbox {
    display: inline-block;
    margin: 8px 0;
  }

  &__checkbox-label {
    font-size: 14px;
    margin: 0 0 0 8px;
  }
}
