.survey-explorer-theme-dialog {
  &__dialog {
    max-width: 420px;
  }

  &__head {
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 64px;
  }

  &__title {
    flex: 1;
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
  }

  &__close {
    cursor: pointer;
  }

  &__tabs {
    border-bottom: 1px solid #eaeaea;
    padding: 0 20px;
    margin: 0 0 20px;
    display: flex;
    justify-content: flex-end;
  }

  &__tab {
    padding: 8px 20px;
    background: white;
    border: 1px solid #eaeaea;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    transform: translateY(1px);
    margin-right: -1px;
    font-size: 14px;
    cursor: pointer;
    &.-selected {
      border-bottom-color: white;
    }
  }

  &__content {
    flex: 1;
    padding: 0 24px;
  }

  &__foot {
    padding: 0 24px 24px;
    display: flex;
    align-items: center;
  }

  &__action {
    margin: 0 8px 0 0;
  }
}
