.report-settings {
  &__dialog {
    max-width: 530px;
    border: 0;
  }

  &__content {
    padding: 20px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.-block {
      align-items: center;
      justify-content: center;
    }
  }

  &__message {
    color: rgba($color-black, 0.4);
  }

  &__indent {
    margin-left: 24px;
  }

  &__split {
    display: flex;
    align-items: flex-start;
    margin: 0 -5px;

    > div {
      flex: 1;
      padding: 0 5px;
    }
  }

  &__error-wrapper {
    margin: 24px 0;
  }

  &__error-list {
    padding: 0 18px;
    font-size: 14px;

    > li {
      list-style-type: disc;
    }

    span {
      font-weight: 500;
      color: $color-red-candy;
      margin: 16px 0;
    }
  }

  &__actions {
    border-top: 1px solid rgba($color-black, 0.05);
    padding-top: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__btn {
    margin-right: 8px;
  }

  &__info {
    font-size: 14px;
    font-weight: 300;
  }
}
