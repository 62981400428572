.channel-cost-summary {
  margin: 24px 0 0 0;

  &__title {
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 10px;
  }

  &__text {
    line-height: 1.2;
  }

  b {
    color: $color-orange-blaze;
  }
}
