.survey-explorer-settings-dialog {
  &__dialog {
    max-width: 530px;
    border: 0;
  }

  &__content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
  }

  &__toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0 0 20px 0;
    line-height: 1;
    &-checkbox {
      // ...
    }
    &-label {
      margin-left: 10px;
    }
  }

  &__hint {
    margin: 0 0 0 2px;

    > g-icon {
      margin: -1px 0 0 0;
    }
  }

  &__input {
    &-label {
      display: flex;
      align-items: center;
      line-height: 1;
    }

    .field-text {
      max-width: 200px;
    }
  }

  &__indent {
    margin-left: 24px;
  }

  &__error-wrapper {
    margin: 24px 0 0;
  }

  &__error-list {
    padding: 0 18px;
    font-size: 14px;

    > li {
      list-style-type: disc;
    }

    span {
      font-weight: 500;
      color: $color-red-candy;
      margin: 16px 0;
    }
  }

  &__actions {
    margin-top: 30px;
    display: flex;
    align-items: center;
    &-gap {
      flex: 1;
    }
  }
  &__action {
    &.-mr {
      margin-right: 8px;
    }
    &.-ml {
      margin-left: 8px;
    }
  }
}
