.channel-info-places {
  &__content {
    display: flex;
    align-items: flex-start;
  }

  &__content-side {
    flex-shrink: 0;
    width: 240px;
    margin: 0 30px 0 0;
    position: relative;
  }

  &__content-main {
    flex-grow: 1;
  }

  &__register-interest {
    display: flex;
    justify-content: center;
  }

  &__stamp {
    position: absolute;
    top: 20px;
    left: 0;
    background: $color-orange-glow;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__stamp-cost {
    color: $color-white;
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
  }

  &__stamp-sub {
    color: $color-white;
    font-size: 8px;
    line-height: 1;
    margin-top: 4px;
  }
}
