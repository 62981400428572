.sub-selector {
  &__dialog {
    max-width: 400px;
  }

  &__content {
    padding: 24px;
  }

  &__input {
    width: 100%;
    margin: 0 0 20px;
    height: 36px;
    padding: 0 8px;
  }

  &__item {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 8px;
    border-radius: 4px;

    &:hover {
      background: rgba($color-black, 0.05);
      cursor: pointer;
    }
  }

  &__name {
    @extend %ellipsis;
    flex-grow: 1;
  }

  &__actions {
    line-height: 1;
  }
}
