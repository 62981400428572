.g-starburst {
  width: 90px;
  height: 87px;
  position: relative;

  g-icon {
    margin: -21px;
  }

  &__label {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 17px;
    left: 10px;
    width: 69px;
    height: 52px;
    font-weight: 500;
  }
}
