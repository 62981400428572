$checkbox-default-color: $color-grey !default;
$checkbox-highlight-color: #009688 !default;

.gl-checkbox-label-flex,
.gl-checkbox-label,
.gl-radio-label {
  align-items: center;
  cursor: pointer;
  line-height: 1.2;
  width: 100%;

  &.disabled,
  .disabled & {
    cursor: default;
    opacity: 0.3;
  }
}

.gl-checkbox-title {
  font-size: 0.8rem;
}

.gl-checkbox-subtitle {
  @extend %text-light;

  font-size: 0.7rem;
  font-weight: 300;
}

.gl-checkbox-label-flex {
  display: flex;
}

.gl-checkbox,
.gl-radio {
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  margin: 0.25rem;
  margin-right: 1.375em;
  position: relative;

  &:focus {
    outline: none;

    &::before {
      border-color: $color-blue-clear;
    }
  }

  &::before {
    border: 2px solid $checkbox-default-color;
    content: '';
    height: 1rem;
    left: 0;
    position: absolute;
    transition: $swift-ease-in-out;
    width: 1rem;
    z-index: 1;
  }

  &:checked {
    &::before {
      border-color: $checkbox-highlight-color;
      border-right-style: none;
      border-top-style: none;
      height: 0.5rem;
      transform: rotate(-45deg);
    }
  }

  &::after {
    bottom: -6px;
    content: '';
    left: -6px;
    position: absolute;
    right: -6px;
    top: -6px;
  }

  &:disabled {
    cursor: inherit;

    &::before {
      background-color: lighten($checkbox-default-color, 10%);
      border-color: lighten($checkbox-default-color, 10%);
    }

    &:checked::before {
      background-color: transparent;
      border-color: $checkbox-default-color;
    }
  }
}

.gl-radio::before {
  border-radius: 50%;
}

.gl-radio:checked::before {
  border-radius: 0;
}
