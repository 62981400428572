.gl-backdrop-wrapper {
  left: 0;
  position: absolute;
  top: 0;
}

.gl-sidenav-backdrop {
  // header should show on top as used for filter report not main nav anywayconsider refactoring z-indexes
  // consider refactoring
  z-index: 8;
  // z-index: $z-index-sidenav - 1;
}

.gl-loading-backdrop {
  z-index: $z-index-loading - 1;
}

.gl-dialog-backdrop {
  z-index: $z-index-dialog - 1;
}

.gl-backdrop {
  background-color: rgba(0, 0, 0, 0.56);
  height: 100%;
  opacity: 1;
  position: fixed;
  transition: all 0.2s ease-in-out;
  width: 100%;

  &.ng-enter,
  &.ng-leave.ng-leave-active {
    opacity: 0;
  }

  &.ng-enter.ng-enter-active,
  &.ng-leave {
    opacity: 1;
  }

  .portal-container & {
    width: calc(100% - 304px);

    @include respond-to('small') {
      width: 100%;
    }
  }
}
