.publisher-editor {
  &__dialog {
    max-width: 400px;
  }

  &__message {
    font-size: 14px;
    line-height: 1.4;
  }

  &__content {
    padding: 30px;
  }

  &__group {
    display: flex;
    align-items: flex-start;
    margin: 24px -5px;

    > div {
      margin: 0 5px;
    }

    .field-label {
      margin-top: 0;
    }

    .field-text {
      margin-bottom: 0;
    }
  }
}
