.report-questions {
  &-box {
    background: $color-white;
    border-radius: 2px;
  }

  &-number {
    color: rgba($color-black, 0.24);
    font-size: 22px;
    font-weight: 500;
    margin: 0 10px;
  }

  &-title {
    flex-grow: 1;
    font-size: 16px;
    font-weight: 500;
    padding: 0 10px;
    min-height: 67px;
  }

  &-action {
    background: 0;
    border: 0;
    font-size: 24px;
    height: 40px;
    margin: 0;
    padding: 0;
    width: 40px;
    outline: 0;
    color: rgba($color-black, 0.24);

    &[disabled] {
      color: $color-black;
    }
  }

  &__section {
    padding: 8px;
  }

  &__section-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;

    p {
      // markdown can put p elements in here
      font-size: inherit;
      margin: inherit;
    }
  }

  &__section-media-btn {
    display: flex;
    justify-content: center;
    margin-top: 4px;
  }
}

.report-questions {
  &__chart-width {
    float: left; // graceful degradation
    width: 50%;

    &.-full {
      width: 100%;
    }

    @media all and (max-width: 800px) {
      width: 100%;
    }
  }
}
