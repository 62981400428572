.color-picker {
  &-swatch {
    height: 37px;
    width: 37px;
  }

  &-input {
    border: 1px solid $input-border-color;
    height: 37px;
    outline: 0;
    padding-right: 10px;
  }
}

// requires influence to override core styles
.color-picker-wrapper .input-group .input-group-addon {
  border: 1px solid $input-border-color;
}
