.filter-set-charts {
  &__item {
    display: flex;
    align-items: center;
    padding: 16px 0;
    border-bottom: 1px solid rgba($color-black, 0.05);
    margin: 0 16px;

    &:hover {
      // cursor: pointer;
    }

    &-name {
      flex-grow: 1;
      font-size: 15px;
      margin: 0 0 0 16px;
      line-height: 1.2;
    }
  }

  &__actions {
    padding: 0 9px;
    text-align: right;
  }

  &__action {
    display: inline-block;
    margin: 0 0 0 8px;
    color: rgba($color-black, 0.5);
    font-size: 12px;
    font-weight: 500;

    &:hover {
      cursor: pointer;
      color: $color-blue-clear;
    }
  }
}
