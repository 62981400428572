.question-summary {
  $parent: &;

  text-align: center;
  height: 140px;
  padding: 25px 0 0 0;

  &__icon {
    display: inline-block;
    width: 140px;
    height: 62px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition: transform 0.1s ease-in-out;
  }

  &__text {
    text-align: center;
  }

  &.-clickable:hover {
    cursor: pointer;

    #{$parent}__icon {
      transform: scale(1.05);
    }
  }
}
