.feature-list {
  &__featurebtn {
    display: flex;
    justify-content: center;
    margin: 0 0 20px;
  }

  &__btn {
    background: #eeeeee;
    border-radius: 6px;
    height: 54px;
    padding: 0 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    cursor: pointer;
  }

  &__grid {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    margin: 0 -10px;

    &-item {
      padding: 10px;
      flex-basis: 33.3333%;
      display: flex;
    }
  }

  &__feature {
    flex: 1;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    padding: 30px;

    &-name {
      font-size: 18px;
      font-weight: 500;
      margin: 0 0 16px;
      line-height: 1.2;
    }

    &-desc {
      font-size: 14px;
      margin: 0 0 20px;
    }
  }
}
