.resource-editor {
  $root: &;

  &__dialog {
    max-width: 550px;
  }

  &__message {
    font-size: 14px;
    line-height: 1.4;
    margin: 0 0 20px;
  }

  &__content {
    padding: 30px;
  }

  &__type-grid {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    margin: 0 -10px;

    &-item {
      display: flex;
      margin: 0 10px;
      flex-basis: 50%;
    }
  }

  &__type {
    background: rgba($color-black, 0.05);
    border-radius: 4px;
    padding: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
      background: rgba($color-black, 0.07);
    }

    &-icon {
      margin: 0 0 10px;
    }

    &-label {
      font-size: 15px;
      font-weight: 500;
      margin: 0 0 10px;
      text-align: center;
    }

    &-desc {
      font-size: 14px;
      line-height: 1.3;
      text-align: center;
    }
  }

  &__categories {
    display: flex;
  }

  &__category {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;

    &:hover {
      cursor: pointer;
    }

    &.-selected {
      background-color: rgba($color-black, 0.05);
      border-radius: 4px;
    }

    &.-disabled {
      cursor: inherit;
      pointer-events: none;

      #{$root}__category-img {
        filter: grayscale(100%);
      }
    }
  }

  &__category-img {
    max-width: 50px;
  }

  &__category-label {
    text-align: center;
    font-size: 12px;
  }

  &__no-surveys {
    border-radius: 4px;
    border: 1px solid rgba($color-orange-blaze, 0.1);
    background: rgba($color-orange-blaze, 0.1);
    height: 34px;
    line-height: 34px;
    padding: 0 10px;
    font-size: 12px;
    color: $color-orange-blaze;
  }
}
