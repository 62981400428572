.publisher-store {
  padding: 0 30px;

  &__wrapper {
    max-width: 1024px;
    margin: 0 auto;
    padding: 45px 0 0;
  }

  &__title {
    margin: 10px 0 45px;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    line-height: 1;
  }

  &__content {
    display: flex;
    align-items: flex-start;
  }

  &__publications {
    flex-grow: 1;
  }

  &__extra {
    max-width: 280px;
    flex-shrink: 0;
    flex-grow: 0;
    margin-left: 20px;
  }

  &__box {
    background: $color-white;
    border-radius: 4px;
    margin: 0 0 10px;
    padding: 20px;
  }

  &__logo {
    margin: 0 0 20px;
  }

  &__about {
    font-size: 14px;
    line-height: 1.3;
    margin: 20px 0;
  }

  &__actions {
    margin: 20px 0;
  }

  &__action {
    display: block;
    margin: 10px 0;
  }

  &__newsletter {
    // ..
  }

  &__back {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    &-text {
      font-size: 14px;
    }
  }

  @media all and (max-width: 750px) {
    &__content {
      flex-direction: column;
      align-items: inherit;
    }

    &__extra {
      order: -1;
      max-width: inherit;
      margin: 0 0 10px;
      display: flex;
      flex-direction: column-reverse;
    }

    &__actions {
      display: flex;
      justify-content: flex-start;
    }

    &__action {
      margin-right: 10px;
    }
  }
}
