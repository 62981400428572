.survey-explorer-filter-dialog {
  &__dialog {
    max-width: 780px;
  }

  &__head {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 24px;
    height: 70px;
  }

  &__title {
    flex: 1;
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
  }

  &__btn {
    margin: 0 0 0 8px;
  }

  &__content {
    padding: 24px;
  }
}
