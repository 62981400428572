.place-search {
  &__wrapper {
    position: relative;
  }

  &__results-wrapper {
    position: absolute;
    width: 100%;
    z-index: $z-index-dropdown;
    margin: 15px 0 0;
  }

  &__results {
    border: 1px solid rgba($color-black, 0.15);
    background-color: $color-white;
    box-shadow: 0 2px 10px rgba($color-black, 0.15);
    border-radius: 4px;
    position: relative;
    font-size: 14px;

    &:before {
      content: '';
      position: absolute;
      top: -6px;
      left: 50%;
      background-color: $color-white;
      margin-left: -6px;
      width: 10px;
      height: 10px;
      border: 1px solid rgba($color-black, 0.15);
      border-right: 0;
      border-bottom: 0;
      transform: rotate(45deg);
    }
  }

  &__result {
    border-bottom: 1px solid rgba($color-black, 0.1);
    padding: 10px;
    display: flex;
    align-items: baseline;

    &:hover {
      cursor: pointer;
      background-color: rgba($color-black, 0.03);
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  &__result-title {
    color: rgba($color-black, 0.8);
    white-space: nowrap;
    flex-grow: 1;
  }

  &__result-desc {
    @extend %ellipsis;
    margin: 0 0 0 5px;
    font-size: 12px;
    color: rgba($color-black, 0.4);
  }

  &__result-none {
    font-style: italic;
    color: rgba($color-black, 0.5);
    text-align: center;
    padding: 10px;
  }
}
