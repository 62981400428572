.filter-set-list {
  padding: 16px 0 32px 0;

  &__block {
    margin: 0 0 16px;
  }

  &__section {
    display: flex;
    align-items: center;
    padding: 0 16px;
    margin: 0 0 8px;

    &.-clickable {
      cursor: pointer;
    }

    &-label {
      flex-grow: 1;
      font-size: 15px;
      font-weight: 500;
    }
  }

  &__add {
    margin: 0 16px;
  }
}
