.my-publications {
  padding: 0 30px 30px;

  &__wrapper {
    max-width: 1024px;
    margin: 0 auto;
  }

  &__title {
    margin: 45px 0 15px;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    line-height: 1;
  }

  &__subtitle {
    text-align: center;
    margin: 15px 0;
  }

  &__content {
    display: flex;
  }

  &__publications {
    flex-grow: 1;
  }

  &__sidebar-wrapper {
    max-width: 280px;
    flex-shrink: 0;
    flex-grow: 0;
    margin: 0 0 0 20px;
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $color-white;
    border-radius: 4px;
    padding: 20px;
  }

  &__info-btn {
    text-align: center;
  }

  &__toolbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin: 0 0 30px;

    &-btn {
      margin: 10px 0 0 10px;
    }
  }

  @media all and (max-width: 750px) {
    &__content {
      flex-direction: column-reverse;
      align-items: inherit;
    }

    &__sidebar-wrapper {
      max-width: inherit;
      margin: 0 0 20px;
    }
  }
}
