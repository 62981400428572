.report-info-rating {
  margin-top: 20px;

  &__stars {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    text-align: center;
    font-size: 14px;
  }
}
