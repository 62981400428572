.publication-type-icon {
  $root: &;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 9px;

  &__icon {
    color: $color-white;
  }

  &.-free {
    background: #2ab27b;
  }

  &.-paid {
    background: #3aa3e3;
  }

  &.-premium {
    background: $color-orange-blaze;
  }

  &.-greyscale {
    background: rgba($color-black, 0.54);
  }

  &.-large {
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }
}
