@import './detail-card/detail-card';
@import './detail-grid/detail-grid';
@import './new-views-grid/new-views-grid';
@import './omni-importer/omni-importer';
@import './import-from/import-from';

.view-picker {
  &__dialog {
    max-width: 980px;

    @media all and (max-width: 1020px) {
      max-width: 90%;
    }
  }

  &__content {
    padding: 26px 24px 17px;
    background: $color-portal-background;
  }

  &__subheading {
    font-weight: 500;
    font-size: 14px;
    margin: 24px 0 16px 0;
    color: rgba($color-black, 0.54);
  }
}
