.inline-steps {
  display: flex;

  &__step-wrapper {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    position: relative;
  }

  &__step {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  &__number {
    background-color: $color-blue-clear;
    color: $color-white;
    font-weight: 500;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__label {
    max-width: 150px;
    margin: 5px 0 0;
    font-size: 14px;
    line-height: 1;
  }

  &__line-left,
  &__line-right {
    background-color: $color-blue-clear;
    height: 4px;
    position: absolute;
    top: 18px;
    width: 50%;
    z-index: -1;
  }

  &__line-left {
    left: 0;
  }

  &__line-right {
    left: 50%;
  }

  @media all and (max-width: 450px) {
    flex-direction: column;
    align-items: flex-start;

    &__step-wrapper {
      margin: 5px 0 0;
    }

    &__step {
      flex-direction: row;
    }

    &__number {
      min-width: 30px;
      height: 30px;
      flex-basis: 30px;
      border-radius: 4px;
    }

    &__label {
      margin: 0 0 0 5px;
      text-align: left;
      max-width: unset;
    }

    &__line-left,
    &__line-right {
      display: none;
    }
  }
}
