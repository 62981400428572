.stripe-card {
  display: block;
  width: 100%;

  &__element {
    display: block;
    margin: 10px 0;
  }

  &__label {
    display: flex;
    align-items: flex-end;
  }

  &__label-text {
    font-size: 14px;
    font-weight: 400;
    flex-grow: 1;
    line-height: 1;
    margin: 0 0 5px;
  }

  &__mount {
    background: rgba($color-black, 0.05);
    border-radius: 4px;
    padding: 9px 10px;
  }

  &__card {
    max-width: 34px;
    height: auto;
    margin: 0 0 7px 4px;
    border: 1px solid rgba($color-black, 0.1);
    border-radius: 4px;
  }

  &__powered {
    text-align: right;

    img {
      display: inline-block;
      max-width: 74px;
      height: auto;
    }
  }

  &__sidebyside {
    display: flex;
    align-items: flex-start;
    margin: 0 -5px;

    > div {
      flex-basis: 50%;
      margin: 0 5px;
    }
  }
}
