@import './publisher/publication-publisher';

.publication {
  padding: 45px 30px 0;

  &__page {
    display: flex;
    align-items: flex-start;
    max-width: 1024px;
    margin: 0 auto;
  }

  &__main {
    flex-grow: 1;
  }

  &__aside {
    flex-basis: 282px;
    flex-shrink: 0;
    margin: 0 0 0 20px;
  }

  &__overview {
    background: $color-white;
    border-radius: 4px;
    padding: 1px 30px;
    margin: 0 0 20px;
  }

  &__box {
    background: $color-white;
    border-radius: 4px;
    margin: 0 0 10px;
    position: relative;

    &.-when-small {
      display: none;
    }

    @media all and (max-width: 730px) {
      &.-when-small {
        display: block;
      }

      &.-when-large {
        display: none;
      }
    }
  }

  &__name {
    font-size: 30px;
    font-weight: 500;
    line-height: 1.2;
    margin: 30px 0;
  }

  &__country-industry {
    display: flex;
    align-items: center;
    margin: -15px 0 15px;

    span {
      margin: 0 0 0 10px;
      font-size: 14px;
    }
  }

  &__subtitle {
    font-size: 19px;
    font-weight: 500;
    line-height: 1.2;
    margin: 0 0 15px;
  }

  &__text {
    font-size: 15px;
    margin: 0 0 30px;
  }

  &__rating {
    padding: 20px;
  }

  &__image-wrapper {
    padding: 5px;
  }

  &__image-box {
    display: block;
    position: relative;
    padding-bottom: 75%;
    background: rgba($color-black, 0.01);
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    // IMPORTANT:
    // - publication images are ALWAYS displayed in a 4:3 ratio box.
    // - There are no restrictions on image upload size, but 800 x 600 or 4:3 is recommended.
    // - If users want text to be visible in every situation (and not cropped) you MUST use the ratio
    // - Non-ratio images will scale up to fill the 4:3 size box, to help aesthetics.
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba($color-black, 0.01);
  }

  &__no-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba($color-black, 0.1);
  }

  &__resources {
    padding: 10px 20px 0;
  }

  &__resources-title {
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 20px;
  }

  &__resource {
    display: block;
    margin: 0 0 10px;
  }

  &__cta {
    padding: 0 20px 20px;
  }

  &__cta-btn {
    display: block;
    padding: 15px 0 0;
  }

  &__cta-msg {
    text-align: center;
    font-size: 12px;
    margin: 5px 0 0;
  }

  &__type {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 0;

    &-text {
      font-size: 14px;
      color: rgba($color-black, 0.54);
      margin: 0 0 0 10px;
    }
  }

  &__share {
    padding: 10px;
    border-top: 1px solid rgba($color-black, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__metrics {
    padding: 20px;

    &-grid {
      margin: 10px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    &-grid-item {
      flex-basis: 50%;
      padding: 0 5px;
      margin: 0 0 20px;
    }

    &-note {
      text-align: center;
      font-size: 12px;
      line-height: 1.2;
      color: rgba($color-black, 0.4);
    }
  }

  &__metric {
    &-number {
      text-align: center;
      font-size: 30px;
      line-height: 1;
      font-weight: 500;
      margin: 0 0 5px;
    }

    &-label {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
    }

    &-info {
      margin-top: 7px;
      text-align: center;
      font-size: 11px;
      font-weight: 300;
      line-height: 1;
    }
  }

  &__remove {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-red-candy;
    cursor: pointer;

    .g-icon {
      margin-right: 5px;
    }
  }

  @media all and (max-width: 730px) {
    &__page {
      flex-direction: column;
      max-width: 350px;
    }

    &__aside {
      order: -1;
      flex-basis: inherit;
      margin: 0 0 20px;
      width: 100%;
    }
  }
}
