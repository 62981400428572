@import './filters-dialog/survey-explorer-filters-dialog';
@import './filter-dialog/survey-explorer-filter-dialog';
@import './settings-dialog/survey-explorer-settings-dialog';
@import './prefilter/survey-explorer-prefilter';
@import './select/survey-explorer-select';
@import './theme-dialog/survey-explorer-theme-dialog';
@import './mods-dialog/survey-explorer-mods-dialog';
@import './cloud/survey-explorer-cloud';
@import './excluded-views-dialog/survey-explorer-excluded-views-dialog';
@import './map/survey-explorer-map';

.survey-explorer {
  $root: &;
  &__building {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    > span {
      margin-right: 20px;
    }
  }
  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }
  &__error {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }
  &__main {
    $root: &;
    position: relative;
    display: flex;
    height: calc(100vh - 60px);
    &-content {
      flex: 1;
      display: flex;
      justify-content: center;
      padding: 50px 20px;
      overflow-y: auto;
    }
    &-inner {
      max-width: 1000px;
      width: 100%;
    }
    &-sidebar {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 320px;
      background: white;
      display: flex;
      flex-shrink: 0;
      transform: translate(-100%);
      transition: transform 0.15s ease-out;
      z-index: 2;
    }
    &-shade {
      position: absolute;
      inset: 0;
      pointer-events: none;
      background: rgba(0, 0, 0, 0.2);
      opacity: 0;
      transition: opacity 0.15s ease-out;
      z-index: 1;
    }
    &.-sidebar-overlay {
      #{$root}-sidebar {
        transform: translate(0%);
      }
      #{$root}-shade {
        opacity: 1;
        pointer-events: inherit;
      }
    }
    &.-sidebar-open {
      #{$root}-sidebar {
        transform: translate(0%);
      }
      #{$root}-content {
        padding-left: calc(320px + 20px);
      }
    }
  }
  &__sidebar {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }
  &__sidebar-top {
    padding: 20px 20px 0;
    &-actions {
      display: flex;
    }
    &-gap {
      flex: 1;
    }
    &-save {
      height: 34px;
      padding: 0 12px 0 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.05);
      color: rgba(0, 0, 0, 0.2);
      font-size: 14px;
      font-weight: 500;
      border-radius: 6px;
      &.-active {
        background: #f60;
        color: white;
      }
      > span {
        margin-left: 2px;
      }
      &:hover {
        cursor: pointer;
      }
    }
    &-action {
      height: 34px;
      width: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      margin-right: 6px;
      &:hover {
        cursor: pointer;
        border-color: rgba(0, 0, 0, 0.15);
      }
    }
  }
  &__sidebar-switcher {
    margin: 0 0 5px;
    display: flex;
    align-items: center;
    &-dropdown {
      flex: 1;
      min-width: 0;
      display: flex;
      align-items: center;
      padding: 0 7px 0 10px;
      position: relative;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      height: 34px;
    }
    &-dropdown-value {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: hidden;
    }
    &-dropdown-copy {
      line-height: 1;
      margin-left: 4px;
      cursor: pointer;
    }
    &-dropdown-caret {
      line-height: 1;
      margin-left: 4px;
    }
    &-dropdown-list {
      position: absolute;
      top: calc(100% + 3px);
      left: 0;
      right: 0;
      background: white;
      border-radius: 4px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
      padding: 8px 0;
    }
    &-dropdown-listitem {
      padding: 4px 12px;
      line-height: 1.3;
      &:hover {
        cursor: pointer;
        background: rgba(0, 0, 0, 0.05);
      }
    }
    &-add {
      margin-left: 5px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      width: 34px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        cursor: pointer;
        border-color: rgba(0, 0, 0, 0.15);
      }
    }
  }
  &__sidebar-logo {
    padding: 20px 20px 0;
    display: flex;
    // justify-content: center;
    img {
      max-width: 64px;
    }
  }
  &__sidebar-head {
    display: flex;
    padding: 20px;
    &-title {
      flex: 1;
      margin-top: 5px;
      font-size: 19px;
      font-weight: 500;
      line-height: 1.2;
    }
    &-btn {
      margin-left: 8px;
      width: 34px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      &:hover {
        border-color: rgba(0, 0, 0, 0.15);
        cursor: pointer;
      }
    }
  }
  &__sidebar-tabs {
    display: flex;
  }
  &__sidebar-tab {
    flex: 1;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    &:last-child {
      border-right-color: transparent;
    }
    &.selected {
      border-bottom-color: transparent;
      border-top-color: rgba(0, 0, 0, 0.1);
    }
  }
  &__views {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
    &-list {
      flex: 1;
      padding: 20px;
      overflow-y: auto;
    }
    &-removed {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      text-align: center;
      padding: 8px;
      font-size: 14px;
      cursor: pointer;
    }
  }
  &__view {
    $root: &;
    display: flex;
    align-items: flex-start;
    margin: 0 0 12px;
    &-label {
      flex: 1;
      padding-right: 10px;
      margin-top: 2px;
      font-size: 15px;
    }
    &:hover {
      cursor: pointer;
    }
    &.selected {
      #{$root}-label {
        color: $color-orange-blaze;
      }
    }
    &.private {
      #{$root}-label {
        color: #b4b8bf;
      }
    }
    &.selected.private {
      #{$root}-label {
        color: #e8a477;
      }
    }
  }
  &__menu {
    position: relative;
    &-btn {
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      &:hover {
        border-color: rgba(0, 0, 0, 0.2);
        cursor: pointer;
      }
    }
    &-content {
      position: absolute;
      right: 0;
      top: calc(100% + 6px);
      background: white;
      z-index: 1;
      border-radius: 6px;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
      padding: 8px 0;
    }
    &-item {
      display: flex;
      align-items: center;
      padding: 6px 16px 6px 8px;
      color: rgba(0, 0, 0, 0.7);
      cursor: pointer;
      > span {
        margin-left: 4px;
        line-height: 0;
        white-space: nowrap;
      }
      &:hover {
        color: rgba(0, 0, 0, 0.9);
      }
      &.disabled {
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }
  &__filters {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
  }
  &__filter {
    $root: &;
    display: flex;
    align-items: center;
    margin: 0 0 8px;
    &-label {
      flex: 1;
      font-size: 15px;
    }
    &-btn {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        cursor: pointer;
      }
      &.dim {
        color: rgba(0, 0, 0, 0.3);
      }
    }
    &.private {
      #{$root}-label {
        color: #b4b8bf;
      }
    }
  }
  &__filters-add {
    margin: 12px 0 0 0;
    display: inline-flex;
    background: rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    align-items: center;
    height: 36px;
    padding: 0 12px;
    &:hover {
      background: rgba(0, 0, 0, 0.03);
      cursor: pointer;
    }
    > span {
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.7);
    }
  }
  &__head {
    display: flex;
    margin: 0 0 24px;
  }
  &__head-gap {
    flex: 1;
  }
  &__head-count {
    margin-left: 8px;
    height: 38px;
    background: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    &-num {
      font-size: 14px;
      font-weight: 600;
    }
    &-of {
      font-size: 14px;
      font-weight: 400;
      margin: 0 4px;
      color: rgba(0, 0, 0, 0.3);
    }
  }
  &__head-btn {
    margin-right: 4px;
    width: 38px;
    height: 38px;
    background: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-shrink: 0;
  }
  &__head-exitbeta {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    height: 38px;
    padding: 0 11px 0 8px;
    display: flex;
    align-items: center;
    > g-icon {
      margin-top: -1px;
      margin-right: 4px;
      transform: rotate(180deg);
    }
    > span {
      font-size: 15px;
    }
    &:hover {
      cursor: pointer;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
  &__prefilter {
    $root: &;
    margin: 0 4px 0 0;
    display: flex;
    align-items: center;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    height: 40px;
    padding: 0 0 0 8px;
    &-icon {
      line-height: 0;
      margin: 0 0px 0 0;
    }
    &-name {
      margin: 0 4px 0 0;
    }
    &-rm {
      line-height: 0;
      color: rgba(0, 0, 0, 0.3);
      width: 30px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        cursor: pointer;
        color: inherit;
      }
    }
    &.private {
      #{$root}-name {
        color: #b4b8bf;
      }
    }
  }
  &__add-prefilter {
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    height: 40px;
    padding: 0 13px 0 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      cursor: pointer;
    }
  }
  &__chart {
    background: white;
    border-radius: 10px;
    padding: 30px;
    margin: 0 0 24px;
    // height: 500px;
    display: flex;
    flex-direction: column;
    &-head {
      display: flex;
      margin: 0 0 20px;
    }
    &-head-title {
      width: 100%;
      textarea {
        border: 0;
        padding: 0;
        margin: 0;
        font-size: 18px;
        font-weight: 600;
        resize: none;
      }
    }
    &-head-gap {
      flex: 1;
    }
    &-head-btn {
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 0 0 0 8px;
    }
    &-main {
      flex: 1;
      position: relative;
      width: 100%;
      margin: 0 auto;
      // max-width: 0px; // set by code
      canvas {
        position: absolute;
        inset: 0;
      }
    }
  }
  &__toolbar {
    display: flex;
    align-items: center;
    margin: 0 0 8px;
    &-gap {
      flex: 1;
    }
    &-btn {
      flex-shrink: 0;
      margin: 0 0 0 4px;
      padding: 0 6px 0 10px;
      height: 38px;
      background: white;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &-label {
        font-size: 15px;
        margin-right: 2px;
      }
      &-icon {
        line-height: 1;
      }
    }
    &-count {
      background: white;
      border-radius: 10px;
      padding: 0 12px;
      height: 40px;
      display: flex;
      align-items: center;
      &-label {
        text-align: right;
        font-size: 9px;
        color: rgba(0, 0, 0, 0.3);
        line-height: 1;
        margin: 0 0 2px;
      }
      &-value {
        text-align: right;
        font-size: 15px;
        line-height: 1;
      }
    }
  }
  &__table {
    $root: &;
    background: white;
    border-radius: 10px;
    overflow-x: auto;
    table {
      table-layout: auto;
      width: 100%;
      border-collapse: separate;
    }
    tr {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      &:last-child {
        border-bottom: 0;
      }
    }
    td {
      white-space: nowrap;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      padding: 0;
      > div {
        display: flex;
        align-items: center;
        width: auto;
        padding: 12px 12px;
        line-height: 1.2;
      }
      &:first-child {
        position: sticky;
        left: 0;
        background: white;
        white-space: normal;
        min-width: 140px;
      }
      &:nth-last-child(2) {
        border-right: 0;
      }
      &:last-child {
        border-right: 0;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        // width: 100%;
        position: sticky;
        right: 0;
        // background: white;
      }
      &.right {
        > div {
          justify-content: flex-end;
        }
        // text-align: right;
      }
      &.bg {
        background: #fafafa;
      }
      &.bold {
        font-weight: 600;
      }
      &.gap {
        width: 100%;
      }
      // highlights
      &.hl-green {
        background: lightgreen;
        color: green;
        font-weight: 500;
      }
      &.hl-red {
        background: pink;
        color: red;
        font-weight: 500;
      }
      &.hl-grey {
        background: #d9d9d9;
        font-weight: 600;
      }
    }
    .color {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      flex-shrink: 0;
      margin-right: 6px;
    }
    .globe {
      display: inline-flex;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      flex-shrink: 0;
      margin-right: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.7);
      color: white;
      .gi-public {
        vertical-align: text-bottom;
      }
    }
    .text {
      .text-label {
        &.ellipse {
          // display: -webkit-box;
          // -webkit-line-clamp: 2;
          // -webkit-box-orient: vertical;
          // overflow: hidden;
          // text-overflow: ellipsis;

          display: inline-block;
          min-width: 0;
          max-width: 120px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .filter {
      display: flex;
      align-items: center;
      .filter-name {
        cursor: pointer;

        &.ellipse {
          // min-width: 0;
          // max-width: 120px;

          // display: -webkit-box;
          // -webkit-line-clamp: 2;
          // -webkit-box-orient: vertical;
          // overflow: hidden;
          // text-overflow: ellipsis;

          display: inline-block;
          min-width: 0;
          max-width: 120px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .filter-remove {
        line-height: 0;
        color: rgba(0, 0, 0, 0.3);
        margin: 0 0 0 4px;
        &:hover {
          cursor: pointer;
          color: black;
        }
      }
      &.private {
        .filter-name {
          color: #b4b8bf;
        }
      }
    }
    .filteradd {
      display: flex;
      align-items: center;
      .filteradd-label {
      }
      .filteradd-add {
        line-height: 0;
        color: rgba(0, 0, 0, 0.3);
      }
      &:hover {
        cursor: pointer;
        .filteradd-add {
          color: black;
        }
      }
    }
  }
  &__list-head {
    display: flex;
    align-items: center;
    margin: 0 0 8px;
    &-gap {
      flex: 1;
    }
  }
  &__list {
    background: white;
    border-radius: 10px;
    padding: 30px;
  }
  &__list-item {
    padding: 6px 11px;
    border-radius: 5px;
    &:nth-child(even) {
      background: rgba(0, 0, 0, 0.02);
    }
  }
  &__cloud {
    background: white;
    border-radius: 10px;
    padding: 30px;
    margin: 0 0 24px;
    &-head {
      display: flex;
      margin: 0 0 20px;
    }
    &-head-title {
      width: 100%;
      textarea {
        border: 0;
        padding: 0;
        margin: 0;
        font-size: 18px;
        font-weight: 600;
        resize: none;
      }
    }
    &-chart {
      display: block;
      height: 500px;
    }
  }
  &__footgap {
    height: 80px;
  }
  &__images {
    background: white;
    border-radius: 10px;
    padding: 30px;
    margin: 0 0 20px;
    &-head {
      display: flex;
      margin: 0 0 20px;
    }
    &-head-title {
      width: 100%;
      textarea {
        border: 0;
        padding: 0;
        margin: 0;
        font-size: 18px;
        font-weight: 600;
        resize: none;
      }
    }
    &-grid {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      margin: 0 - 5px;
    }
    &-grid-item {
      width: 10%;
      padding: 5px;
    }
    &-item {
      border-radius: 6px;
      padding-bottom: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  &__map {
    background: white;
    border-radius: 10px;
    padding: 30px;
    margin: 0 0 24px;
    &-head {
      display: flex;
      margin: 0 0 20px;
    }
    &-head-title {
      width: 100%;
      textarea {
        border: 0;
        padding: 0;
        margin: 0;
        font-size: 18px;
        font-weight: 600;
        resize: none;
      }
    }
    &-head-gap {
      flex: 1;
    }
    &-head-btn {
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 0 0 0 8px;

      &.-selected {
        border-color: rgba(0, 0, 0, 0.5);
      }
    }
    &-main {
      height: 500px;
    }
  }
  &.shared {
    .survey-explorer__main {
      height: 100vh;
    }
  }
}
