.filter-set-block {
  padding: 0 16px;
}

.filter-set-block-item {
  $root: &;

  display: flex;
  align-items: center;
  border: 1px solid lighten($color-black, 90%);
  border-radius: 4px;
  background-color: $color-white;
  margin: 0 0 8px;
  min-height: 40px;
  cursor: pointer;

  &__label {
    padding: 8px;
    flex-grow: 1;
    font-size: 14px;
    line-height: 1.3;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    width: 40px;
    border-left: 1px solid rgba($color-black, 0.1);
    line-height: 1;
    flex-shrink: 0;

    &.-checkbox {
      border-left: none;
    }
  }

  &__checkbox {
    .g-checkbox-box {
      border-color: lighten($color-black, 90%);
      background: lighten($color-black, 90%);
      &.-selected {
        border-color: darken($color-blue-clear, 10%);
        background: darken($color-blue-clear, 10%);
      }
      &__icon > .g-icon {
        font-weight: 600;
      }
    }
  }

  &.-static,
  &.-default {
    border-color: lighten($color-black, 95%);
    background-color: lighten($color-black, 95%);
  }

  &.-editable {
    // ...
  }

  &.-selected {
    border-color: $color-blue-clear;
    background-color: $color-blue-clear;

    #{$root}__label {
      color: $color-white;
    }

    #{$root}__actions {
      border-left-color: rgba($color-white, 0.2);
    }
  }
}
