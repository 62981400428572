.move-dialog {
  &__dialog {
    max-width: 530px;
  }

  &__content {
    display: flex;
    padding: 24px 24px 48px;
  }

  &__select {
    flex-grow: 1;
    margin-right: 10px;
    min-width: 0; // prevent selected text overflow
  }
}
