.question-library-filters {
  $root: &;
  display: block;

  &__section-title {
    font-weight: 500;
    margin: 0 0 5px;
  }

  &__level {
    margin: 0 0 18px;
    position: relative;

    &:last-child #{$root}__level-arrow {
      display: none;
    }
  }

  &__path {
    $path-root: &;

    border: 1px solid rgba($color-black, 0.15);
    border-bottom-width: 0;
    padding: 10px;
    display: flex;
    align-items: center;

    &-text {
      color: rgba($color-black, 0.6);
      line-height: 1.1;
      font-size: 14px;
      flex-grow: 1;
    }

    &-count {
      height: 20px;
      line-height: 20px;
      padding: 0 5px;
      font-size: 12px;
      margin: 0 0 0 10px;
      border-radius: 2px;
      background: rgba($color-black, 0.05);
      color: rgba($color-black, 0.4);
      font-weight: 500;
    }

    &:hover {
      cursor: pointer;

      &-text {
        color: rgba($color-black, 0.8);
      }
    }

    &:nth-child(2) {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border-bottom-width: 1px;
    }

    &.-selected {
      background: $color-blue-clear;
      border-color: $color-blue-clear;

      #{$path-root}-text {
        color: $color-white;
        font-weight: 500;
      }

      #{$path-root}-count {
        background-color: rgba($color-white, 0.1);
        color: $color-white;
      }
    }
  }

  &__level-arrow {
    position: absolute;
    text-align: center;
    bottom: -24px;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 0.3;
  }
}
