.channel-form-products {
  &__instructions {
    padding: 15px 0 15px 35px;

    li {
      list-style: decimal;
    }
  }

  &__link-btn {
    display: inline-flex;
    align-items: center;
    margin: 0 0 0 10px;
    font-size: 14px;

    &:hover {
      cursor: pointer;
    }
  }

  &__products {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 20px;
  }

  &__product-add {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    border-radius: 4px;
    background: rgba($color-black, 0.05);
    margin: 0 0 3px;
    padding: 0 5px;

    g-icon {
      line-height: 0;
      margin-top: 2px;
    }

    &-label {
      font-size: 13px;
      font-weight: 500;
      line-height: 0;
      margin: 0 3px;
    }

    &:hover {
      background: rgba($color-black, 0.03);
      cursor: pointer;
    }
  }
}
