$interaction-item-height: 58px;

.interaction-group {
  margin-top: 0;
}

.interaction-list {
  &-group {
    margin-top: 8px;
  }

  &-items {
    @extend %container-white;
    padding: 0;
  }

  &-group-header {
    color: $color-grey-medium;
    line-height: 40px;
    margin: 0 auto;
    padding-left: 16px;
  }

  &-pager {
    margin: 24px 0;

    button {
      margin-right: 5px;
    }
  }

  &-buttons {
    float: right;
    margin: 5px 5px 0;

    g-btn {
      margin-left: 10px;
    }
  }
}

.interaction-list-item {
  @include clearfix();

  align-items: center;
  display: flex;
  height: $interaction-item-height;
  margin: 4px 0;
  padding: 0 1rem;
  position: relative;

  &.has-checkbox {
    padding-left: 0;
  }

  &::before {
    border-bottom: 1px solid $color-grey-gallery;
    bottom: 0;
    content: '';
    height: 1px;
    left: 1rem;
    position: absolute;
    width: calc(100% - 2rem);
  }

  &:last-child::before {
    border-bottom: 0;
  }

  &:hover {
    cursor: pointer;
  }

  &.read {
    .icon {
      opacity: 0.56;
    }

    .from,
    .summary {
      font-weight: 300;
    }

    .time {
      color: rgba($color-black, 0.26);
    }

    .tag {
      border: 1px solid rgba($color-black, 0.26);
      color: rgba($color-black, 0.26);
    }

    .info {
      color: rgba($color-black, 0.26);
    }
  }

  .icon {
    font-size: 2.5rem;
    min-width: 52px;
    width: 52px;
    display: flex;
    align-items: center;
  }

  .summary {
    display: flex;
    flex-direction: column;
    padding: 0 8px;

    .source,
    .message {
      @extend %ellipsis;

      display: inline-block; // to ensure ellipsis show - does not work with flex
    }

    .source {
      font-size: em(13);
    }

    .gi {
      font-size: 1.1rem;
      margin-left: -3px;
      margin-right: 2px;
      vertical-align: middle;
    }
  }

  .info {
    margin-left: auto;
    white-space: nowrap;

    > .gi {
      font-size: 1.8rem;
      vertical-align: middle;
    }
  }

  .from,
  .summary {
    @extend %ellipsis;
    line-height: 24px;
  }

  .from {
    min-width: 20%;
    width: 20%;
  }

  .time {
    min-width: 70px;
    text-align: right;
  }

  .status {
    display: flex;
    flex-direction: column;
  }

  .tag {
    border: 1px solid $base-font-color;
    border-radius: 4px;
    color: $base-font-color;
    font-size: 0.7rem;
    margin-right: 4px;
    padding: 2px 5px;
    text-align: center;
    vertical-align: text-bottom;
  }

  .comments {
    display: inline-block;
    margin-right: 5px;

    span {
      font-size: 14px;
      font-weight: 700;
      line-height: 1;
    }

    &-icon {
      font-size: 22px;
      height: 27px;
      line-height: 1;
      margin-top: 2px;
      text-align: center;
      vertical-align: middle;
      width: 23px;
    }
  }

  .checkbox {
    width: 52px;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px 0 1rem;
  }

  .id {
    font-size: 11px;
    color: rgba($color-black, 0.4);
  }
}
