.kit-sections {
  &__section-detail {
    display: flex;
    align-items: center;
  }

  &__section-industry {
    padding: 0 0 0 5px;
  }
}
