.channel-picker {
  &__dialog {
    max-width: 800px;

    @media all and (max-width: 860px) {
      max-width: 90%;
    }
  }

  &__content {
    &.-bg {
      background: $color-portal-background;
      border-top: 3px solid rgba($color-black, 0.03);
    }

    &.-inset {
      padding: 24px;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 400;
    line-height: 1;

    &.-margin-bot {
      margin-bottom: 20px;
    }

    &.-margin-top {
      margin-top: 30px;
    }
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 300;
    margin: 4px 0 20px 0;
  }

  &__other {
    cursor: pointer;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 19px 24px;
    border-top: 1px solid rgba($color-black, 0.05);

    &-aside {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-grow: 1;
    }

    &-action {
      margin-right: 10px;
    }

    &-label {
      font-size: 14px;
      font-weight: 500;
      margin: 0 10px;
    }

    &-error {
      color: $color-red-candy;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
