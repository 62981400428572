@import './question-filter/question-filter';
@import './date-filter/date-filter';
@import './segment-filter/segment-filter';

.report-filter-editor {
  &__dialog {
    max-width: 700px;
  }

  &__types {
    &-grid {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      padding: 20px 10px 10px;
    }

    &-grid-item {
      display: flex;
      align-items: stretch;
      justify-content: stretch;
      padding: 0 10px 20px 10px;
      flex-basis: 50%;
    }

    &-item {
      border: 1px solid rgba($color-black, 0.05);
      border-radius: 4px;
      padding: 20px;
      width: 100%;

      &:hover {
        cursor: pointer;
        border: 1px solid rgba($color-black, 0.1);
      }
    }

    &-name {
      font-size: 15px;
      font-weight: 500;
      line-height: 1;
      margin: 0 0 5px;
    }

    &-description {
      font-size: 13px;
      color: rgba($color-black, 0.54);
    }
  }

  &__editor {
    padding: 20px;
    min-height: 300px;
  }

  &__actions {
    padding: 20px;
    border-top: 1px solid rgba($color-black, 0.05);
  }

  &__action {
    margin: 0 10px 0 0;
  }

  // this is used by child components
  &__dropdown {
    margin: 0 0 20px;
    display: block;
  }
}

// this is used by child components
.filter-choice-options {
  max-height: 450px;
  overflow: auto;
}
