.publication-rating-form {
  padding: 0 20px 20px;

  &__dialog {
    max-width: 420px;
  }

  rating-stars {
    display: block;
    margin: 10px 0;
  }
}
