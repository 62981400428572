.date-filter {
  &__sideby {
    display: flex;
    align-items: flex-start;
  }

  &__side {
    flex-grow: 1;
    flex-basis: 50%;
    text-align: center;
  }

  &__head {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    border-bottom: 1px solid rgba($color-black, 0.1);
    padding: 0 0 20px;
    margin: 0 0 20px;
  }

  &__cal {
    padding: 16px;
  }
}
