.invoices {
  &__tabs {
    display: block;
    margin: 30px -30px;
  }
  &__controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 0 16px;
  }
  &__select {
    width: 160px;
    margin: 0 0 0 16px;
  }
  &__table {
    &-head {
      th {
        font-size: 14px;
        font-weight: 500;
        padding: 8px 8px;
        text-transform: inherit;
        border: 0;
      }
    }

    &-row {
      td {
        font-size: 14px;
        font-weight: 400;
        padding: 8px 8px;
        border: 0;
      }
    }

    &-row:nth-child(even) {
      background: rgba($color-black, 0.03);
    }

    &-row-zero {
      text-align: center;
    }
  }
}
