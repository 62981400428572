// Page Parent View
// ------------------------------------
.page-settings-view {
  @extend %container-white;
}

.view-page-btn {
  margin-left: auto;
}

// General Settings
// ------------------------------------

// Module Settings
// ------------------------------------
.page-module {
  @extend %container-white;

  margin: 12px;
  min-height: 200px;
  position: relative;
  width: 280px;

  &-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  &-header {
    line-height: 1.1;
  }

  &-icon {
    font-size: 1.5rem;
    vertical-align: middle;
  }

  &-title {
    font-size: 1.15rem;
    margin-left: 5px;
  }

  &-description {
    @extend %text-light;

    font-size: 0.875rem;
    line-height: 1.4;
  }

  &-btn {
    bottom: 12px;
    left: 10%;
    position: absolute;
    width: 80%;
  }
}

// Elements Settings
// ------------------------------------

// Link Settings
// ------------------------------------

.page-link-control {
  @extend %base-form-control;

  align-items: center;
  display: flex;
  line-height: 20px;

  &.focus {
    background-color: $input-focus-background-color;
    border-color: $input-focus-border-color;
  }

  &.invalid {
    border: 1px solid $color-red-candy;
  }

  &-label {
    padding-right: 8px;
  }

  &-input {
    border: 0;
    flex: 1;
    outline: none;
    overflow: hidden;
    transition: background 0.2s linear 0s, box-shadow 0.2s linear 0s;

    .focus & {
      background-color: $input-focus-background-color;
    }
  }
}

.page-dim-group {
  display: flex;
  margin: 6px 0;
}

.page-dim-control-left,
.page-dim-control-right {
  @extend %base-form-control;

  width: 50%;
}

.page-dim-control-left {
  border-radius: 2px 0 0 2px;
}

.page-dim-control-right {
  border-radius: 0 2px 2px 0;
}
