// positions the dialog for scrolling containers
// .gl-dialog-wrapper {
//     left: 0;
//     position: absolute;
//     top: 0;
// }

// sets the container to the content window not the browser window
.gl-dialog-container {
  bottom: 0;
  left: 0;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  position: fixed;
  right: 0;
  padding: 0 20px;
  top: 0;
  z-index: $z-index-dialog;
}

gl-dialog {
  background-color: $color-white;
  display: block;
  margin: 5% auto;
  max-width: 80%;
  min-width: 240px;
  opacity: 0;
  padding: 0;
  position: relative;
  border-radius: 4px;

  &.transition-in {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
    transition: $swift-ease-out;
  }

  &.transition-out {
    transform: translate3d(0, 100%, 0) scale(0.2);
    transition: $swift-ease-out;
  }

  &.alert-dialog {
    max-width: 340px;

    p {
      margin: 10px 0 0;
    }
  }

  .gl-dialog-close {
    @extend %base-btn;

    color: $color-grey-medium;
    font-size: 1.5em;
    position: absolute;
    right: 3px;
    top: 0;
    z-index: 1;
  }

  .gl-dialog-header {
    padding: 12px 16px 0;

    .dialog-title {
      margin: 0;
    }

    .dialog-info {
      color: $color-grey;
    }
  }

  .gl-dialog-content,
  .gl-dialog-content-dark {
    padding: 12px 16px;
  }

  .gl-dialog-content-dark {
    background-color: $color-white-alabaster;
  }

  .gl-dialog-actions {
    padding: 12px 16px;
    text-align: right;

    button {
      margin-left: 5px;
    }
  }
}

.dialog-error,
.dialog-loading {
  padding: 30px;
  text-align: center;
}

.gl-dialog-medium {
  max-width: 480px;
}

.gl-dialog-large {
  margin-top: 10%;
  max-width: 90%;
  width: 600px;

  @include respond-to('small') {
    max-width: 90%;
  }
}

.alert-dialog {
  max-width: 90%;
  min-width: 200px;
}
