.channel-tag-quotas {
  &__header {
    display: flex;
    align-items: center;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 0 10px;
  }

  &__sub-title {
    font-size: 14px;
    color: rgba($color-black, 0.56);
    margin: 0 0 0 10px;
  }

  &__editor {
    display: flex;
    flex-direction: column;
  }

  &__warning {
    margin-top: 30px;
  }

  &__zero {
    padding: 20px;
    color: rgba(0, 0, 0, 0.3);
  }

  &__item {
    max-width: 200px;
    margin: 0 0 -22px;
  }

  &__field {
    width: 170px;
  }
}
