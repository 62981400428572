.report-semantic-diff-text {
  font-size: 12px;
  display: flex;

  &__group {
    flex: 1;
    margin: 0 3px 0 0;
    &.-right {
      text-align: right;
      margin: 0 0 0 3px;
    }
  }

  &__label {
    font-weight: 500;
  }
}
