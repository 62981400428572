.portal-header {
  display: block;
  height: 60px;

  &__container {
    background: $color-white;
    box-shadow: 0 2px 3px rgba($color-black, 0.05);
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 16px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }

  &__logo {
    width: 36px;
    height: 36px;
    margin: 0 24px 0 -4px;
  }

  &__links {
    display: flex;
    align-items: center;
  }

  &__link {
    font-size: 16px;
    font-weight: 500;
    color: rgba($color-black, 0.7);
    margin: 0 24px 0 0;
    line-height: 1;
    position: relative;

    &:active,
    &:focus {
      color: rgba($color-black, 0.7);
    }

    &:hover {
      cursor: pointer;
      color: rgba($color-black, 0.8);
    }

    &.-ssr {
      color: rgba($color-black, 0.4);
    }

    &.-active {
      color: $color-orange-blaze;
    }
  }

  &__count {
    position: absolute;
    top: -10px;
    right: -14px;
    background: $color-orange-blaze;
    color: $color-white;
    min-width: 14px;
    height: 14px;
    border-radius: 7px;
    text-align: center;
    line-height: 14px;
    font-size: 10px;
    font-weight: 800;
    padding: 0 3px;
  }

  &__gap {
    flex-grow: 1;
  }

  &__btn {
    margin: 0 0 0 10px;
  }

  &__account {
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }

  &__account-menu {
    min-width: 210px;
  }

  &__account-info {
    padding: 13px 20px;
  }

  &__account-name {
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
    margin: 0 0 2px;
  }

  &__account-email {
    @extend %ellipsis;
    line-height: 1;
    font-size: 12px;
    color: rgba($color-black, 0.4);
  }

  &__account-image {
    height: 36px;
    width: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__account-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: rgba($color-black, 0.1);
    color: rgba($color-black, 0.4);
  }

  &__menu-zero {
    padding: 8px 20px;
    line-height: 1;
    font-style: italic;
    color: rgba($color-black, 0.5);
  }

  &__caret {
    color: rgba($color-black, 0.2);
    margin: -1px -10px 0 0;
  }

  &__experience {
    margin: 0 8px 0 0;
  }

  &__upgrade {
    background: $color-orange-blaze;
    height: 30px;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0 8px 0 0;
    padding: 0 2px;

    &-text {
      color: $color-white;
      margin: 0 9px 0 2px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  &__menu-btn {
    display: none;
    margin: 0 0 0 8px;
  }

  &__menu {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    background: white;
    z-index: 9;

    &.ng-enter,
    &.ng-leave {
      transition: all ease-out 0.3s;
    }

    &.ng-enter,
    &.ng-leave.ng-leave-active {
      opacity: 0;
      top: 40px;
    }

    &.ng-leave,
    &.ng-enter.ng-enter-active {
      opacity: 1;
      top: 60px;
    }
  }

  &__menu-links {
    margin-top: 70px;
  }

  &__menu-link {
    $l-root: &;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 0 8px;
    color: rgba($color-black, 0.7);

    &:active,
    &:focus {
      color: rgba($color-black, 0.7);
    }

    &:hover {
      cursor: pointer;
      color: rgba($color-black, 0.8);
    }

    &-text {
      font-size: 20px;
      font-weight: 500;
      cursor: pointer;
    }

    &.-active {
      #{$l-root}-text {
        color: $color-orange-blaze;
      }
    }

    &.-sub {
      margin: 8px 0;

      #{$l-root}-text {
        font-size: 15px;
      }
    }
  }

  @media all and (max-width: 1010px) {
    &__link {
      margin-right: 13px;
    }
  }

  @media all and (max-width: 936px) {
    &__experience {
      display: none;
    }
  }

  @media all and (max-width: 784px) {
    &__upgrade {
      display: none;
    }
    &__menu-btn {
      display: block;
    }
    &__links {
      display: none;
    }
  }

  @media all and (max-width: 590px) {
    &__upgrade {
      display: none;
    }
  }
}
