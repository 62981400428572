.channel-form-custom {
  &__instructions {
    padding: 15px 0 15px 35px;

    li {
      list-style: decimal;
    }
  }

  &__hidden {
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;
  }

  &__summary {
    margin: 24px 0 0 0;

    &-title {
      font-size: 14px;
      font-weight: 500;
      margin: 0 0 10px;
    }

    &-text {
      line-height: 1.2;
    }

    b {
      color: $color-orange-blaze;
    }
  }

  &__id {
    font-size: 11px;
    color: rgba($color-black, 0.4);
    margin-top: 24px;
  }
}
