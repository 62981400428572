.survey-response-batch-delete {
  &__dialog {
    max-width: 600px;
    border: 0;
  }

  &__content {
    padding: 20px;
  }

  &__warning-wrapper {
    margin-bottom: 20px;
    text-align: right;
  }

  &__warning {
    color: $color-red-candy;
  }

  &__form-group {
    margin-bottom: 5px;
  }

  &__form-actions {
    display: flex;
    justify-content: flex-end;

    g-btn {
      margin: 0 0 0 10px;
    }
  }

  &__textarea {
    height: 100px;
  }

  &__error-wrapper {
    margin: 16px 0;
  }

  &__error-message {
    margin: 0;
    font-size: 14px;
    color: $color-red-candy;
  }

  &__error-list {
    padding: 0 18px;
    font-size: 12px;

    > li {
      list-style-type: disc;
      color: $color-red-candy;
    }

    span {
      color: rgba($color-black, 0.7);
    }
  }
}
