.steadfast-dialog {
  max-width: 480px;

  &__content {
    padding: 16px 24px;
  }

  &__message {
    margin: 0 0 16px;
  }

  &__actions {
    display: flex;
  }

  .gl-btn-primary,
  .gl-btn {
    margin: 0 5px 0 0;
  }
}
