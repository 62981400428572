$label-font-color: #55606e;
$help-font-color: #a7abb1;
$input-font-color: #282323;
$input-disabled-font-color: #999;

$input-border-color: #e5e9ec;
$input-focus-border-color: #f1f1f1;

$input-background-color: $color-white;
$input-focus-background-color: #f4f5f7;
$input-disabled-background-color: #eee;

// clear fieldset styling - used only to disable forms in current state
.gl-fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

.gl-form-group,
.gl-form-checkbox-group {
  display: block;
  margin-bottom: 20px;
  position: relative;
  text-align: left;
}

.gl-form-group-right {
  text-align: right;
}

.gl-form-checkbox-group {
  display: flex;
}

.gl-form-label,
%base-form-label {
  color: $label-font-color;
  display: inline-block;
  font-size: em(14);
  line-height: 20px;
  margin: 2px 8px 3px 0;
}

.gl-form-label-help {
  color: $help-font-color;
  font-size: em(12);
}

.gl-form-control,
%base-form-control {
  -webkit-appearance: none;
  background-color: $color-white;
  border: 1px solid $input-border-color;
  border-radius: 2px;
  color: $input-font-color;
  font-size: em(13);
  height: 16px;
  line-height: 15px;
  min-height: 37px;
  outline: 0;
  padding: 6px 11px;
  transition: background 0.2s linear 0s, box-shadow 0.2s linear 0s;
  width: 100%;

  &:-webkit-autofill {
    box-shadow: 0 0 0 500px $color-white inset;
  }

  &.ng-invalid.ng-touched {
    border: 1px solid $color-red-candy;
  }

  &:focus {
    background-color: $input-focus-background-color;
    border-color: $input-focus-border-color;
  }

  &:disabled {
    background-color: $input-disabled-background-color;
    color: $input-disabled-font-color;
    cursor: not-allowed;
  }

  &-error {
    bottom: -20px;
    position: absolute;
  }
}

.gl-form-textarea {
  @extend %base-form-control;

  min-height: 74px;
}

.gl-form-control-error,
.form-error {
  color: $color-red-candy;
  display: block;
  font-size: em(12);
  margin: 0;
}

.gl-form-actions button {
  margin-right: 5px;
}

.gl-form-control-btns {
  .gi {
    color: rgba($color-black, 0.4);
    cursor: pointer;
    font-size: 20px;
    height: 37px;
    line-height: 1.8;
    text-align: center;
    width: 37px;

    &:hover {
      color: $color-black;
    }

    &[disabled] {
      color: rgba($color-black, 0.1);
      pointer-events: none;
    }
  }
}

// general help text
.gl-form-help-text {
  font-weight: 300;
  line-height: 1.25;
  margin-bottom: 20px;
}

.gl-form-select {
  -webkit-appearance: none;
  background-color: $color-white;
  border: 1px solid $input-border-color;
  border-radius: 2px;
  color: $input-font-color;
  font-size: em(13);
  height: 16px;
  line-height: 15px;
  min-height: 37px;
  outline: 0;
  padding: 6px 11px;
  position: relative;
  transition: background 0.2s linear 0s, box-shadow 0.2s linear 0s;
  width: 100%;

  &:after {
    border-left: 0.35em solid transparent;
    border-right: 0.35em solid transparent;
    border-top: 0.475em solid $color-grey;
    content: '';
    height: 0;
    margin-top: 4px;
    padding: 0;
    pointer-events: none;
    position: absolute;
    right: 1.5em;
    top: 0.75em;
    width: 0;
  }

  label {
    display: none;
  }

  select {
    border-bottom: 0;
    margin-top: 4px;
    outline: 0;

    &:focus {
      border-color: transparent;
      box-shadow: none;
      outline-style: none;
    }
  }

  &.disabled {
    background-color: $input-disabled-background-color;
    color: $input-disabled-font-color;
    cursor: not-allowed;
  }
}

.gl-static-list {
  .gl-form-group {
    margin-bottom: 4px;
  }

  .gl-btn {
    margin-bottom: 10px;
    width: 100%;
  }
}

textarea {
  background: none;
  border: 1px solid $input-border-color;
  border-radius: 2px;
  display: block;
  font-size: 14px;
  height: 44px;
  outline: none;
  padding: 10px 15px;
  width: 100%;

  &.transparent {
    background: none;
  }

  &:disabled {
    color: lighten(#000, 20);
  }

  &.elastic-input {
    height: 44px;
  }
}

input {
  &::-ms-clear {
    display: none;
  }
}
