.dashboard {
  margin: 0 auto;
  max-width: 1300px;
  padding: 30px 40px;

  &__welcome {
    background: $color-white;
    border-radius: 4px;
    padding: 20px 24px;

    &-header {
      margin: -20px -24px 10px;
      padding: 8px 24px;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      display: flex;
      align-items: center;
      background: rgba($color-orange-blaze, 0.1);

      &-icon {
        margin-right: 10px;
      }

      &-text {
        font-size: 14px;
        font-weight: 500;
      }
    }

    &-top {
      margin: 0 0 10px;
      display: flex;
      align-items: center;
    }

    &-title {
      font-size: 20px;
      font-weight: 500;
      line-height: 1;
      flex-grow: 1;
    }

    &-close {
      align-self: flex-start;
    }

    &-content {
      display: flex;
      align-items: center;

      @media all and (max-width: 600px) {
        flex-direction: column;
      }
    }

    &-text {
      font-size: 14px;
      font-weight: 400;
      flex: 1;

      ul {
        padding: 0 24px;
        li {
          list-style-type: disc;
          margin: 0 0 2px;
        }
      }
    }

    &-image {
      width: 220px;
      flex-shrink: 0;
    }
  }

  &__content {
    display: block;
  }

  &__sidebar {
    display: block;
    background: $color-white;
    border-radius: 4px;
  }

  &__sidebox {
    padding: 20px;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }

    &-head {
      display: flex;
      align-items: center;
      margin: 0 0 5px;
    }

    &-icon {
      margin-right: 5px;
    }

    &-title {
      flex: 1;
      font-size: 15px;
      font-weight: 500;
    }

    &-text {
      font-size: 14px;
      font-weight: 400;
      margin: 0;

      &.-money {
        font-size: 20px;
        font-weight: 600;
        color: rgba($color-black, 0.6);
      }

      &.-term {
        font-size: 12px;
        margin: 2px 0 15px 0;
        line-height: 1;
        color: rgba($color-black, 0.6);
      }

      ul {
        padding: 0 24px;
        li {
          list-style-type: disc;
          margin: 0 0 2px;
        }
      }
    }

    &-btn {
      margin: 10px 0 0;
    }

    &-refresh {
      margin: 0 0 0 10px;
    }
  }

  &__section {
    margin: 0 0 40px;
    transition: all 0.2s ease-in-out;

    &.ng-enter,
    &.ng-leave.ng-leave-active {
      opacity: 0;
    }

    &.ng-enter.ng-enter-active,
    &.ng-leave {
      opacity: 1;
    }
  }

  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 20px;

    &-title {
      font-size: 24px;
      font-weight: 500;
      margin-right: 16px;
    }

    &-btns {
      display: flex;
      align-items: center;
    }

    &-btn {
      margin-left: 10px;
    }

    @media all and (max-width: 500px) {
      flex-direction: column;
      align-items: flex-start;

      &-btn {
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }

  &__zero {
    background: $color-white;
    border-radius: 4px;
    padding: 20px 30px;

    &-image {
      width: 220px;
      flex-shrink: 0;
      margin-bottom: 30px;
    }

    &-content {
      // ...
    }

    &-title {
      font-size: 19px;
      font-weight: 500;
      margin: 0 0 10px;
    }

    &-text {
      font-size: 14px;
      font-weight: 400;
      margin: 0 0 20px;
    }

    &-actions {
      flex-grow: 1;
      display: flex;
      align-items: flex-end;
    }
  }
}
