.detail-card {
  &__front {
    display: flex;
    flex-direction: column;
    height: 150px;
    padding: 16px;

    &.-highlight-grey {
      border-top: 4px solid rgba($color-black, 0.2);
    }

    &.-highlight-red {
      border-top: 4px solid rgba($color-red-candy, 0.7);
    }
  }

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__label {
    text-align: center;
    font-weight: 500;
    font-size: 17px;
  }

  &__desc {
    width: 100%;
    font-size: 14px;
    line-height: 1.3;
    text-align: center;
    color: rgba($color-black, 0.54);
  }
}
