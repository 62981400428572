.channel-confirm-reward {
  padding: 24px;

  &__dialog {
    max-width: 550px;
  }

  ul {
    font-size: 14px;
    padding: 0 24px;
    margin: 0 0 24px;
    li {
      list-style-type: disc;
      margin: 0 0 8px;
    }
  }

  &__btns {
    margin: 24px 0 0;
  }

  &__btn {
    margin: 0 8px 0 0;
  }
}
