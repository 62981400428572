.outlink {
  padding-left: 36px;
  max-width: 380px;

  &__link {
    background: #f2f2f2;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    align-items: center;
  }

  &__link-text {
    flex: 1;
    font-size: 14px;
    color: rgba($color-black, 0.4);
  }

  &__link-btn {
    font-size: 14px;
    color: rgba($color-black, 0.5);
    &:hover {
      cursor: pointer;
      color: $color-black;
    }
  }
}
