.question-library-variations {
  $root: &;
  position: relative;
  max-width: 540px;

  &__header {
    padding: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba($color-black, 0.1);
  }

  &__header-text {
    flex-grow: 1;
    font-size: 18px;
    font-weight: 600;
    margin: 0 20px;
  }

  &__slider {
    @include gl-scroll-class-mixin;
    flex-grow: 1;
    border-left: 1px solid rgba($color-black, 0.1);
    border-right: 1px solid rgba($color-black, 0.1);
    max-height: 440px;
    padding: 0 70px;
    position: relative;
  }

  &__slider-btn {
    position: fixed;
    top: 0;
    bottom: 0;
    display: flex;
    width: 70px;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    & > * {
      pointer-events: auto;
    }

    &.-left {
      left: 0;
    }

    &.-right {
      right: 0;
    }
  }

  &__title {
    text-align: center;
    font-size: 24px;
    font-weight: 300;
    margin: 20px;
  }

  &__choices {
    text-align: center;
    margin: 0 0 40px;
  }

  &__choice {
    border-radius: 26px;
    background: rgba($color-black, 0.05);
    display: flex;
    align-items: center;
    padding: 20px;
    margin: 0 auto 10px;
    width: 280px;
  }

  &__choice-circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid rgba($color-black, 0.54);
    flex-shrink: 0;
  }

  &__choice-text {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.2;
    padding: 0 15px;
  }

  &__footer {
    border-top: 1px solid rgba($color-black, 0.1);
    padding: 20px;
    display: flex;
    align-items: center;
  }

  &__footer-spacer {
    flex-grow: 1;
  }

  &__footer-circle {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid rgba($color-black, 0.4);
    margin: 0 5px 0 0;

    &.-selected {
      border-color: $color-blue-clear;
      background: $color-blue-clear;
    }
  }

  &__footer-btn {
    @extend %question-library-button;
  }
}
