.g-segments {
  $border-radius: 15px;

  height: 30px;
  width: 100%;
  display: flex;
  font-size: 12px;

  &__segments {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: $border-radius;
    display: flex;
  }

  &__segment {
    padding: 0 10px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      text-align: center;
      white-space: nowrap;
    }

    &.-active {
      background-color: $color-blue-clear;
      color: $color-white;

      &:first-child {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }

      &:last-child {
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
    }
  }
}
