.panel-targets {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px 24px;

  &__target {
    display: flex;
    flex: 33%;
    min-width: 200px;
    padding: 5px;
  }

  &__box {
    display: flex;
    padding: 15px;
    background: rgba($color-black, 0.05);
    border-radius: 4px;
    border-bottom: 4px solid rgba($color-black, 0.05);
    transition: border 0.15s ease-out;

    &:hover {
      cursor: pointer;
      border-bottom-color: rgba($color-black, 0.1);
    }

    &.-selected {
      border-bottom-color: $color-blue-clear;
    }
  }

  &__left {
    flex: 0 0 52px;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }

  &__right {
    flex: 1;
    margin: 0 0 0 15px;
  }

  &__name {
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 5px;
  }

  &__desc {
    font-size: 12px;
    font-weight: 400;
    color: rgba($color-black, 0.5);
  }
}
