.question-templates {
  $root: &;
  position: relative;
  max-width: 1080px;
  display: flex;
  align-items: center;
  flex-direction: row;

  &__list-item {
    cursor: pointer;
  }

  &__list-content {
    margin-right: auto;
    width: 750px;
  }

  &__list-detail {
    align-items: center;
    display: flex;
    margin: 10px 0 20px;
    overflow: hidden;
    width: 100%;

    &__icon {
      background-position: center;
      background-size: cover;
      border-radius: 50%;
      height: 50px;
      margin-right: 12px;
      min-width: 50px;
      width: 50px;
    }

    &__name {
      font-size: 1.5rem;
      line-height: 1.2em;
    }

    &__title {
      font-size: 0.8rem;
      line-height: 1em;
    }

    &__summary {
      font-size: 0.8rem;
      line-height: 1em;
      span {
        padding-right: 3px;
      }
    }
  }

  &__list-more {
    text-align: center;
  }

  &__search {
    margin: 1px;
    padding: 20px 20px;
    background: white;
  }

  &__search-box {
    display: flex;
    align-items: center;
    padding: 0 10px;
    border: 1px solid rgba($color-black, 0.15);
    border-radius: 4px;
  }

  &__label {
    padding-right: 10px;
    align-self: center;
  }

  &__search-field {
    flex-grow: 1;
    outline: 0;
    border: 0;
    height: 38px;
    margin-left: 5px;
    min-width: 350px;
  }

  &__type-filter {
    display: flex;
    flex-direction: row;
    padding: 0 10px;
  }

  &__state-filter {
    display: flex;
    flex-direction: row;
    padding: 0 10px;
  }

  &__dropdown {
  }
}
