.account-response-pricing {
  &__dialog {
    max-width: 800px;

    .account-response-pricing__content {
      padding: 30px 30px 60px;
    }
  }

  &__scroller {
    overflow-x: auto;
  }

  &__content {
    padding: 0;

    @media all and (max-width: 730px) {
      width: 720px;
    }
  }

  &__caption {
    text-align: center;
    margin: 0 0 40px;
  }

  &__table {
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__table-column {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 2px;

    &.-type {
      min-width: 250px;

      > div {
        justify-content: flex-start;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__table-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 4px;

    &.-title {
      font-size: 16px;
      font-weight: 500;
      background-color: white !important;
    }

    &.-loi {
      color: white;

      &.-MICRO {
        background-color: $color-orange-glow;
      }
      &.-MID {
        background-color: $color-orange-neon-carrot;
      }
      &.-LONG {
        background-color: $color-orange-sea-buckthorn;
      }
      &.-MAX {
        background-color: $color-orange-blaze;
      }
    }

    &.-grey {
      color: rgba($color-black, 0.4);
    }

    &.-bold {
      font-weight: 500;
    }

    &:nth-child(odd) {
      background-color: rgba($color-black, 0.03);
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
