.publication-type-toggle {
  display: flex;
  flex-wrap: wrap;

  &__type {
    display: flex;
    align-items: center;
    border-radius: 4px;
    height: 36px;
    padding: 0 10px;
    margin: 2px;

    &:hover {
      cursor: pointer;
    }

    &.-selected {
      background: rgba($color-black, 0.05);
    }
  }

  &__icon {
    margin: 0 5px 0 0;
  }

  &__label {
    font-size: 14px;
    color: rgba($color-black, 0.54);
  }
}
