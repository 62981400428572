.channel-variables {
  &__header {
    display: flex;
    align-items: center;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 0 10px;
  }

  &__sub-title {
    font-size: 14px;
    color: rgba($color-black, 0.56);
    margin: 0 0 0 10px;
  }

  &__editor {
    padding-top: 20px;
  }

  &__item {
    display: flex;
    align-items: center;
    margin: 0 0 16px;
  }

  &__field {
    flex: 1;
    margin-right: 8px;
  }
}
