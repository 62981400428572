.place-manager {
  &__header {
    display: flex;
    align-items: center;
    margin: 0 0 30px;
    justify-content: space-between;
  }

  &__search {
    display: flex;
    align-items: center;
    background: $color-white;
    border-radius: 4px;
    height: 36px;
    padding: 0 5px;
    width: 100%;
    max-width: 320px;

    g-icon {
      line-height: 0;
      margin: 0 5px 0 0;
    }

    input {
      display: block;
      outline: 0;
      border: 0;
      flex-grow: 1;
      height: 100%;

      &::placeholder {
        color: rgba($color-black, 0.4);
      }
    }
  }

  &__item {
    display: block;
    margin: 0 0 10px;
  }

  &__controls {
    display: flex;
    align-items: center;
    margin: 0 0 10px;
  }

  &__hidden-selections {
    flex: 1;
    font-size: 12px;
  }

  &__select {
    display: flex;
    align-items: center;

    > div {
      margin-left: 8px;
      cursor: pointer;
      font-size: 14px;
    }
  }

  &__zero {
    padding: 50px;
    text-align: center;
  }

  &__quota {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    background: rgba($color-black, 0.05);
    padding: 15px 14px 15px 20px;
    margin: 0 0 10px;
    font-size: 15px;
    font-weight: 500;
  }

  @media all and (max-width: 520px) {
    &__header {
      display: block;
    }

    &__search {
      max-width: inherit;
      margin: 0 0 10px;
    }
  }
}
