.project-picker {
  &__dialog {
    max-width: 1000px;

    @media all and (max-width: 1060px) {
      max-width: 90%;
    }
  }

  &__content {
    padding: 30px 24px;
    background: $color-portal-background;
    margin-top: -3px;
    border-top: 3px solid rgba($color-black, 0.03);
  }

  &__zero {
    margin: 60px 0;
  }

  &__loading {
    text-align: center;
  }
}
