.survey-explorer-mods-dialog {
  &__dialog {
    max-width: 530px;
    border: 0;
  }

  &__content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
  }

  &__mod {
    display: flex;
    align-items: center;
    margin: 0 0 12px;
    &-toggle {
      margin-right: 12px;
    }
    &-label,
    &-weight {
      display: flex;
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      padding: 0 9px;
      > input {
        height: 36px;
        min-width: 0;
        flex: 1;
        border: 0;
        outline: 0;
        padding: 0;
        margin: 0;
        &::placeholder {
          color: rgba(0, 0, 0, 0.2);
        }
      }
    }
    &-label {
      flex: 1;
      &.-fade {
        > input {
          color: rgba(0, 0, 0, 0.3);
        }
      }
    }
    &-weight {
      width: 56px;
      margin-left: 4px;
      > input {
        text-align: right;
      }
    }
    &-info {
      color: rgba(0, 0, 0, 0.3);
      cursor: pointer;
    }
    &-btn {
      margin-left: 4px;
      width: 38px;
      height: 38px;
      border-radius: 6px;
      background: rgba(0, 0, 0, 0.05);
      color: rgba(0, 0, 0, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &.-fade {
        color: rgba(0, 0, 0, 0.5);
      }
    }
    &-switch {
      margin-left: 4px;
      padding: 0 6px;
      height: 38px;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 6px;
      display: flex;
    }
    &-switchitem {
      width: 30px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        cursor: pointer;
      }
      &.-fade {
        color: rgba(0, 0, 0, 0.1);
      }
    }
  }

  &__weighting {
    margin-top: 12px;
    &-toggle {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    &-toggle-checkbox {
      // ...
    }
    &-toggle-label {
      margin-left: 10px;
    }
    &-input {
      display: flex;
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      padding: 0 9px;
      margin: 6px 0 0 0;
      > input {
        height: 36px;
        min-width: 0;
        flex: 1;
        border: 0;
        outline: 0;
        padding: 0;
        margin: 0;
        &::placeholder {
          color: rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  &__actions {
    margin-top: 30px;
    display: flex;
    align-items: center;
    &-gap {
      flex: 1;
    }
  }
  &__action {
    &.-mr {
      margin-right: 8px;
    }
    &.-ml {
      margin-left: 8px;
    }
  }
}
