.publication-filter {
  width: 100%;

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__search {
    display: block;
  }

  &__group {
    width: 100%;
    margin: 10px 0 0;
  }

  &__group-heading {
    margin: 0 0 5px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
    font-weight: 500;
  }
}
