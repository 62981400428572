.survey-template-dialog {
  &__dialog {
    max-width: 800px;

    @media all and (max-width: 860px) {
      max-width: 90%;
    }
  }

  &__spinner {
    text-align: center;
  }

  &__info {
    display: flex;
    align-items: flex-start;
    padding: 0 24px;
    margin: 24px 0;
  }

  &__img {
    border-radius: 4px;
    margin: 0 24px 0 0;
    max-width: 260px;
  }

  &__desc-box {
    max-height: 158px;
    overflow-y: auto;
  }

  &__desc {
    margin: 0 0 16px;
  }

  &__rec {
    padding: 0 24px;
    margin: 24px 0;
  }

  &__rec-title {
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 4px;
  }

  &__rec-text {
    font-size: 13px;
    line-height: 1.3;
    margin: 0 0 16px;
  }

  &__actions {
    display: flex;
    padding: 24px;
  }

  &__action {
    margin: 0 10px 0 0;
  }

  &__projects {
    padding: 24px;
    background: $color-portal-background;
  }

  &__projects-title {
    font-weight: 500;
    margin: 24px 0 8px;
  }
}
