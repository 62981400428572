.payment-method {
  &__content {
    display: flex;
    align-items: center;
    background: rgba($color-black, 0.05);
    border-radius: 4px;
    height: 34px;
    padding: 0 5px;
  }

  &__image {
    max-width: 38px;
    border-radius: 4px;
    border: 1px solid rgba($color-black, 0.1);
    margin: 0 10px 0 0;

    img {
      display: block;
    }
  }

  &__label {
    flex-grow: 1;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 1;
  }

  &__message {
    padding: 0 0 0 20px;
    font-size: 12px;
    color: $color-red-candy;
  }

  &__actions {
    display: flex;
  }
}
