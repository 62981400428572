.report-crosstab-renamer {
  &__dialog {
    max-width: 530px;
    border: 0;
  }

  &__content {
    padding: 1px 20px 20px;
  }
}
