.survey-settings {
  &__dialog {
    max-width: 800px;
  }

  &__header {
    padding: 16px 24px 0;
    display: flex;

    &-title {
      flex-grow: 1;
      font-size: 20px;
      font-weight: 500;
      line-height: 1;
    }
  }

  &__header-actions {
    display: flex;
    align-items: center;

    g-btn {
      &:not(:last-child) {
        margin: 0 8px 0 0;
      }
    }
  }

  &__page {
    padding: 25px;
  }

  &__image-input {
    width: 160px;
  }

  &__ssr {
    border-top: 1px solid rgba($color-black, 0.05);
    padding: 5px 0 0;
  }

  &__view-groups {
    margin: 24px 0;
  }

  &__view-groups-header {
    display: flex;
    align-items: center;
    margin: 0 0 10px;
    line-height: 1;

    &-text {
      font-size: 18px;
    }

    &-hint {
      margin: 0 0 0 5px;
    }
  }

  &__view-group {
    margin: 0 -8px 15px;
  }

  &__view-group-settings {
    display: flex;
    align-items: center;
    margin: 0 8px 5px;
  }

  &__view-group-name {
    flex-grow: 1;
    margin-right: 15px;
    display: flex;
    align-items: center;

    &-label {
      display: flex;
      align-items: center;
    }

    &-edit {
      margin-left: 2px;
    }

    &-input {
      width: 50%;

      @media all and (max-width: 680px) {
        width: 100%;
      }
    }
  }

  &__view-group-actions {
    display: flex;
    align-items: center;

    &-gap {
      border-right: 1px solid rgba($color-black, 0.1);
      height: 28px;
      margin: 0 8px;
    }
  }

  &__view-group-toggle {
    display: flex;
    align-items: center;
    margin-right: 8px;
    cursor: pointer;

    g-checkbox {
      transform: scale(0.8); // bit of a hack
    }

    span {
      color: rgba($color-black, 0.6);
      font-size: 12px;
      font-weight: 500;
      margin-left: 3px;
    }
  }

  &__sample {
    &-control {
      display: flex;
      align-items: center;
      margin-right: 8px;
      cursor: pointer;
      > span {
        color: rgba($color-black, 0.6);
        font-size: 12px;
        font-weight: 500;
        margin-left: 3px;
      }
      &.-active {
        > span {
          color: rgba($color-black, 0.6);
        }
      }
    }
    &-popover {
      padding: 0 4px;
    }
  }

  &__view-group-shuffle {
    > label {
      margin-bottom: 0;
    }

    .-grey .micro-action__text {
      color: rgba($color-black, 0.6);
    }
  }

  &__view-group-select {
    margin: 0 8px;
  }

  &__view-group-loop {
    padding: 8px 10px 0px 20px;
  }

  &__view-group-add {
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    span {
      font-size: 14px;
    }
  }

  &__shuffle-groups {
    margin: 24px 0;
  }

  &__shuffle-groups-header {
    display: flex;
    align-items: center;
    margin: 0 0 10px;
    line-height: 1;

    &-text {
      font-size: 18px;
    }

    &-hint {
      margin: 0 0 0 5px;
    }
  }

  &__shuffle-group {
    margin: 0 0 10px;

    &-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 0 0 4px;
    }

    &-gap {
      height: 24px;
      border-left: 1px solid rgba($color-black, 0.1);
      margin-right: 8px;
    }

    &-remove {
      // ...
    }

    &-select {
      // ...
    }

    &-add {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      span {
        font-size: 14px;
      }
    }
  }
  // &__shuffle-group-main {
  //   display: flex;
  //   align-items: center;
  //   margin: 0 -8px 10px;

  //   &-select {
  //     width: 100%;
  //     max-width: 100%;
  //     min-width: 0;
  //   }

  //   & > * {
  //     margin: 0 8px;
  //   }
  // }

  &__theme {
    &-control {
      display: flex;
      align-items: center;
      margin-top: 5px;
    }

    &-select {
      flex: 1;
      min-width: 0;
    }

    &-btn {
      margin-left: 8px;
    }

    &-color-picker {
      display: flex;
      align-items: center;

      > span {
        margin-left: 10px;
      }
    }

    &-note {
      text-align: center;
      margin-top: 20px;
      font-size: 14px;
      color: rgba($color-black, 0.6);
    }
  }
}
