.kits {
  &__head {
    display: flex;
    flex-direction: column;
    margin: 0 0 32px;
  }

  &__title {
    flex-grow: 1;
  }

  &__sub-title {
    font-size: 16px;
  }

  &__promo {
    margin: 0 0 30px;
  }

  &__zero {
    display: block;
    margin: 100px auto 50px;
    max-width: 300px;
  }
}
