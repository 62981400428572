.account-plans {
  &__dialog {
    max-width: 800px;

    .account-plans__content {
      padding: 1px 30px 30px;
    }

    .account-plans__plans {
      justify-content: start;
      overflow-x: auto;
    }
  }

  &__toolbar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 40px;
    @media all and (max-width: 730px) {
      flex-direction: column;
      > * {
        margin-bottom: 20px !important;
      }
    }
  }

  &__currencies {
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 4px 0 4px 4px;
    display: flex;
    align-items: center;
    margin: 0 2px;
  }

  &__currency {
    background: transparent;
    border: 1px solid transparent;
    padding: 11px 18px;
    border-radius: 6px;
    margin-right: 4px;
    &:not(.-selected) {
      cursor: pointer;
      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }
    }
    &.-selected {
      background: #fd6600;
      color: white;
      // background: rgba(0, 0, 0, 0.05);
      // border-color: rgba(0, 0, 0, 0.2);
      cursor: default;
    }
  }

  &__intervals {
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 4px 0 4px 4px;
    display: flex;
    align-items: center;
    margin: 0 2px;
  }

  &__interval {
    background: transparent;
    border: 1px solid transparent;
    padding: 11px 18px;
    border-radius: 6px;
    margin-right: 4px;
    &:not(.-selected) {
      cursor: pointer;
      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }
    }
    &.-selected {
      background: #fd6600;
      color: white;
      // background: rgba(0, 0, 0, 0.05);
      // border-color: rgba(0, 0, 0, 0.2);
      cursor: default;
    }
  }

  &__content {
    padding: 0;

    g-spinner {
      margin: 20px 0 0;
    }
  }

  &__message {
    text-align: center;
    margin: 20px 0;
  }

  // ---

  &__grid {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    margin: -10px -10px 40px;
  }

  &__grid-item {
    padding: 10px;
    flex-basis: 33.33333%;
    display: flex;
    @media all and (max-width: 950px) {
      flex-basis: 50%;
    }
    @media all and (max-width: 620px) {
      flex-basis: 100%;
    }
  }

  &__card {
    flex: 1;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    // align-items: stretch;
  }

  &__card-main {
    flex: 1;
  }

  &__card-foot {
    // ...
  }

  &__card-title {
    font-size: 30px;
    font-weight: 600;
    line-height: 1;
    margin: 0 0 11px;
  }

  &__card-subtitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    margin: 0 0 24px;
    height: 16px; // don't collapse when empty

    &.-color0 {
      color: #000;
    }

    &.-color1 {
      color: #fa9a19;
    }

    &.-color2 {
      color: #fd5b00;
    }
  }

  &__card-price1 {
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
    margin: 0 0 8px;
  }

  &__card-price2 {
    font-size: 16px;
    line-height: 1;
    margin: 0 0 30px;
  }

  &__card-line {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    margin: 0 0 16px;
  }

  &__card-features {
    min-height: 148px;
    margin: 0 0 40px;
  }

  &__card-feature {
    font-size: 16px;
    margin: 0 0 2px;
  }

  &__card-terms {
    font-size: 10px;
    text-decoration: underline;
    margin: 0 0 12px;

    a {
      color: black;
    }
  }

  &__card-btn {
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;

    > span {
      font-size: 18px;
      font-weight: 500;
    }

    &.-color0 {
      background: #f2f2f2;
    }

    &.-color1 {
      background: #fa9a19;
      color: white;
    }

    &.-color2 {
      background: #fd5b00;
      color: white;
    }
  }

  &__enterprise {
    background: #ebf5fc;
    padding: 40px;
    display: flex;
    align-items: center;
    margin: 0 0 40px;
  }

  &__enterprise-main {
    flex: 1;
  }

  &__enterprise-title {
    font-size: 30px;
    font-weight: 600;
    color: #339adf;
  }

  &__enterprise-desc {
    font-size: 20px;
  }

  &__enterprise-btn {
    background: #009afb;
    border-radius: 6px;
    color: white;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
    &:hover {
      color: white;
    }
  }

  &__terms {
    font-size: 12px;
    max-width: 700px;
    margin: 0 auto 40px;
    display: flex;
    flex-direction: column;
  }

  // ----

  &__plans {
    display: flex;
    align-items: stretch;
    justify-content: center;
  }

  &__plan {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 2px;

    &.-feature {
      min-width: 240px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__plan-box {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__plan-enterprise {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 14px;
    padding: 10px 10px;
    background: rgba($color-black, 0.03);
    border-radius: 4px;
  }

  &__plan-name {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: $color-white;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    padding: 12px;
    background: $color-orange-blaze;

    &.-free {
      background: $color-orange-glow;
    }

    &.-custom {
      background: $color-blue-clear;
    }
  }

  &__plan-title {
    min-width: 110px;
    height: 65px;
    padding: 8px;
    border-radius: 4px;
    color: $color-white;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    margin-bottom: 2px;
    background: $color-orange-blaze;

    &.-free {
      background: $color-orange-glow;
    }

    &.-custom {
      background: $color-blue-clear;
    }

    &.-credit {
      height: 85px;
    }

    &-name {
      font-size: 18px;
      font-weight: 500;
    }

    &-price {
      display: flex;
      align-items: center;
      justify-content: center;

      &-amount {
        font-size: 15px;
      }

      &-sub {
        margin-left: 5px;
        font-size: 10px;
      }
    }

    &-credit {
      font-size: 12px;
    }
  }

  &__config {
    display: flex;
    align-items: center;
    height: 65px;

    &.-credit {
      height: 85px;
    }

    &-item {
      margin: 0 4px 0 0;
    }
  }

  &__plan-price {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0 4px;

    &-amount {
      font-size: 23px;
      font-weight: 500;
      margin-right: 5px;
    }

    &-sub {
      font-size: 10px;
    }
  }

  &__plan-credit {
    text-align: center;
    margin: 0 0 20px;
  }

  &__plan-section {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
    margin: 20px 0 10px;
  }

  &__plan-details {
    flex-grow: 1;
  }

  &__plan-feature {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 4px;

    &.-left {
      justify-content: flex-start;
    }

    &:nth-child(odd) {
      background-color: rgba($color-black, 0.03);
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__plan-action {
    margin-top: 5px;

    &.-free {
      > g-btn > a {
        background: $color-orange-glow;

        &:hover:not(.-disabled),
        &:active:not(.-disabled),
        &:focus:not(.-disabled) {
          background: lighten($color-orange-glow, 1%);
        }
      }
    }

    &.-custom {
      > g-btn > a {
        background: $color-blue-clear;

        &:hover:not(.-disabled),
        &:active:not(.-disabled),
        &:focus:not(.-disabled) {
          background: lighten($color-blue-clear, 1%);
        }
      }
    }
  }

  &__plan-sub {
    text-align: center;
    font-size: 11px;
    line-height: 1.2;
    height: 43px;
    padding: 0 5px;
    margin-top: 5px;
  }

  // @media all and (max-width: 540px) {
  //   &__content {
  //     padding-left: 20px;
  //     padding-right: 20px;
  //   }
  // }
}
