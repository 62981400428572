.segment-filter {
  &__head {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 8px;
  }
  &__action {
    display: flex;
    align-items: center;
    g-icon {
      color: rgba($color-black, 0.3);
    }
    > span {
      margin-left: 6px;
      font-size: 15px;
    }
    &:hover {
      cursor: pointer;
      g-icon {
        color: rgba($color-black, 0.8);
      }
    }
  }
  &__zero {
    text-align: center;
    padding: 117px 0;
    color: rgba($color-black, 0.4);
  }
}
