.import-from {
  &__block {
    background: white;
    border-radius: 4px;
    padding: 20px 20px 24px;
    margin: 0 0 16px;
  }

  &__label {
    margin: 0 0 8px;
  }
}
