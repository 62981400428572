.report-stat {
  display: inline-flex;
  border: 1px solid rgba($color-black, 0.1);
  background: white;
  border-radius: 12px;
  margin: 4px;
  padding: 5px 10px;

  &__name {
    margin-right: 3px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
  }

  &__value {
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    color: rgba($color-black, 0.5);
  }
}
