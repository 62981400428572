.place-manager-dialog {
  &__dialog {
    max-width: 800px;
  }

  &__content {
    background: $color-portal-background;
    border-top: 3px solid rgba($color-black, 0.03);
    padding: 24px;
  }

  &__actions {
    padding: 24px;
  }
}
