.password-reset {
  max-width: 850px;
  width: 100%;
  padding: 20px;
  margin: 0 auto 30px;

  &__header {
    display: flex;
    align-items: flex-end;
    margin: 25px 0;
  }

  &__logo {
    flex-grow: 1;
    max-width: 130px;
    height: auto;
  }

  &__box {
    box-shadow: 0 2px 10px rgba($color-black, 0.2);
    border-radius: 4px;
  }

  &__overview {
    border-top: 3px solid $color-orange-blaze;
    padding: 20px 0 36px;
  }

  &__section {
    padding: 20px 40px;
  }

  &__input {
    display: block;
    margin: 10px 0;
  }

  &__input-label {
    font-size: 14px;
    font-weight: 300;
  }

  &__input-input {
    border: 1px solid rgba($color-black, 0.1);
    border-radius: 4px;
    background: 0;
    outline: 0;
    box-shadow: 0;
    height: 40px;
    max-width: 300px;
    width: 100%;
    padding: 0 10px;
    transition: border-color 0.15s ease-out;

    &:hover,
    &:focus {
      border-color: rgba($color-black, 0.4);
    }
  }
}
