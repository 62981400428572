.report-link-editor {
  &__dialog {
    max-width: 530px;
    border: 0;
  }

  &__content {
    padding: 0 20px 20px;
  }

  &__view-select {
    display: block;
    margin-top: -21px;
  }

  &__error {
    font-size: 14px;
    font-weight: 500;
    color: $color-red-candy;
    margin: 16px 0;
  }

  &__btns {
    display: flex;
    align-items: center;
  }

  &__btn {
    margin-right: 8px;
  }
}
