$feature-image-height: 270px;
$feature-image-opacity: 0.5;
$logo-image-size: 170px;
$edit-button-size: 30px;

$default-background-color: $color-grey-silver;

.feature-container {
  @extend %content-container;

  height: $feature-image-height;
  position: relative;

  &-header {
    align-items: center;
    display: flex;
    margin: 12px 0 24px;
  }

  &-filter {
    padding-right: 15px;
    width: 50%;
  }
}

// Standard views
// ------------------------------------
.feature-background {
  background-color: $default-background-color;
  background-position: center;
  background-size: cover;
  height: $feature-image-height;
  opacity: $feature-image-opacity;
  width: 100%;

  &-container {
    background-color: $color-white;
    position: absolute;
    width: 100%;
  }
}

// Image edit views
// ------------------------------------
.feature-edit {
  &-container {
    position: relative;
  }

  &-background {
    background-color: $default-background-color;
    background-position: center;
    background-size: cover;
    height: $feature-image-height;
    width: 100%;
  }
}

.image-edit-wrapper {
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.image-edit {
  align-items: center;
  display: flex;
  flex-basis: $max-content-width - 16px; // to match main padding or 1rem
  flex-direction: column;
  height: $feature-image-height;
  justify-content: flex-end;
}

.image-edit-logo-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: $logo-image-size;
  width: $logo-image-size;
}

.image-edit-logo {
  // use the same positioning/scaling as UP
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
}

.image-edit-feature-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 0 12px 12px;
  width: 100%;
}

.image-edit-feature-edit,
.image-edit-logo-edit {
  @extend %gl-btn;

  font-size: em(14);
  line-height: $edit-button-size;
  max-height: $edit-button-size;
  min-width: $edit-button-size;
  padding: 0;
  text-align: center;

  .gi {
    margin: 0;
  }

  &.loading {
    background-color: transparent;
    border: 0;
    color: transparent;
    padding-right: 0;
  }
}

.image-edit-input {
  display: none;
}
