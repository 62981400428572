.survey-explorer-map {
  &__map {
    width: 100%;
    height: 100%;

    > div {
      // change the background (visible when tiles aren't loaded) from gray to white
      background-color: #fff !important; // overrides googles inline styles
    }
  }

  &__info {
    &-header {
      padding: 0 0 10px 0;
      position: sticky;
      top: 0;
      background: #fff;
    }

    &-text {
      display: flex;
      align-items: center;
      > :first-child {
        flex-grow: 1;
      }
      > :last-child {
        margin-left: 10px;
        white-space: nowrap;
      }

      font-size: 12px;
      font-weight: 400;
      line-height: 1.2;

      &.-title {
        font-size: 14px;
        font-weight: 500;
      }
    }

    &-list > div:not(:last-child) {
      margin: 0 0 5px;
    }

    &-footer {
      padding: 6px 0 0;
      position: sticky;
      bottom: 0;
      background: #fff;
    }
  }
}
