.report-comparison {
  padding: 16px;

  &__head {
    display: flex;
    align-items: center;
    padding: 24px 24px 0;
    margin: 0 0 30px;
  }

  &__title {
    font-size: 20px;
    font-weight: 400;
    flex-grow: 1;
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    margin: 0 -10px;
  }

  &__grid-item {
    flex-basis: 410px;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    margin: 0 10px 20px;
  }

  &__options {
    display: flex;
    align-items: center;
    margin: 0 -1px 5px;
    min-height: 40px;
  }

  &__select {
    flex-grow: 1;
  }

  &__remove {
    margin: 0 0 0 5px;
    background: $color-white;
    height: 38px;
    width: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__chart {
    background: $color-white;
  }

  &__zero {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-white;
    flex-basis: 100%;
    min-height: 490px;
    color: rgba($color-black, 0.4);
  }
}
