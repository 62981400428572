table {
  border-collapse: collapse;
  font-size: 0.875rem;
  width: 100%;
}

th {
  border-bottom: 1px solid darken($color-grey-soft, 15);
  font-weight: bold;
  padding: ($base-spacing / 2) 0;
  text-align: left;
  text-transform: uppercase;
}

td {
  border-bottom: 1px solid $color-grey-soft;
  padding: ($base-spacing / 2) 0;
}

tr,
td,
th {
  vertical-align: middle;
}

// Fix for c3: if table width is 100% it keeps growing to the left -> set to auto to override the above styles
.c3-tooltip {
  width: auto;
}
