.survey-outdated-bar {
  position: sticky;
  top: 60px;
  left: 0;
  right: 0;
  z-index: 10;
  background: $color-red-candy;
  color: $color-white;
  text-align: center;
  padding: 4px;
  font-size: 12px;

  a {
    font-weight: 500;
    color: white;
  }
}
