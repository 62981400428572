.logic-summary {
  &__zero {
    display: flex;
    align-items: center;
    padding: 8px 16px;

    &-text {
      margin-left: 8px;
      font-size: 14px;
      color: rgba($color-black, 0.4);
    }
  }

  &__item {
    padding: 16px;
    border-bottom: 1px solid rgba($color-black, 0.1);
  }

  &__title {
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    margin: 0 0 4px;
  }

  &__list {
    font-size: 12px;
    margin: 0 0 4px 8px;
  }

  &__list-item {
    list-style-type: disc;
    list-style-position: inside;
  }

  &__list-text {
    margin-left: -7px;
  }

  &__list-and {
    color: rgba($color-black, 0.4);
  }
}
