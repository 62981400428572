.survey-list-select__item {
  cursor: pointer;
}

.survey-list-select__content {
  margin-right: auto;
  width: 400px;
}

.survey-list-select__empty-msg {
  font-size: 1rem;
  font-weight: 300;
  padding-left: 24px;
}

.survey-list-select__responses,
.survey-list-select__questions {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  margin-left: 24px;
}

.survey-list-select__count {
  margin-right: 5px;
}
