.portal-footer {
  display: flex;
  align-items: center;
  background-color: rgba($color-black, 0.06);
  height: 54px;
  padding: 0 15px;

  &__logo {
    margin: 0 10px 0 0;
  }

  &__copyright {
    font-size: 14px;
    color: rgba($color-black, 0.54);
  }

  &__gap {
    flex-grow: 1;
  }

  &__link {
    font-size: 14px;
    color: rgba($color-black, 0.7);
    margin: 0 0 0 15px;

    &:hover,
    &:active,
    &:focus {
      color: rgba($color-black, 0.9);
    }
  }

  @media all and (max-width: 600px) {
    flex-direction: column;
    height: inherit;
    padding: 15px;

    &__logo {
      margin: 0 0 10px;
    }

    &__copyright {
      margin: 0 0 10px;
    }

    &__link {
      margin: 0 0 10px;
    }
  }
}
