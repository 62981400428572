.account-uac-editor {
  &__dialog {
    max-width: 530px;
    border: 0;
  }

  &__content {
    padding: 1px 20px 20px;
  }

  &__label {
    margin: 24px 0;
    font-size: 15px;
  }

  &__error {
    font-size: 14px;
    font-weight: 500;
    color: $color-red-candy;
    margin: 16px 0;
  }
}
