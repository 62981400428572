.survey-loi {
  &__label {
    display: flex;
    align-items: center;

    span {
      font-size: 14px;
      font-weight: 500;
      flex-grow: 1;
    }
  }

  &__time {
    font-size: 14px;
    text-align: center;
  }
}
