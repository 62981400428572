.channel-type-card {
  display: flex;
  $root: &;

  .g-card,
  .g-card__flipper {
    display: flex;
  }

  &__front {
    display: flex;
    align-items: center;
    padding: 15px;
  }

  &__image {
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
    width: 70px;
    flex-shrink: 0;
    align-self: stretch;
    margin: 0 20px 0 0;
  }

  &__name {
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 16px;
  }

  &__desc {
    font-size: 14px;
    color: rgba($color-black, 0.54);
  }

  &__link {
    margin-top: 8px;
    font-size: 13px;
  }
}
