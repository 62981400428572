.g-ribbon {
  $root: &;

  &__mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    overflow: hidden;
    z-index: 1;
  }

  &__ribbon {
    background-color: rgba($color-black, 0.2);
    box-shadow: 0 2px 10px rgba($color-black, 0.1);
    color: $color-white;
    font-size: 12px;
    font-weight: 600;
    height: 24px;
    left: -24px;
    line-height: 24px;
    position: absolute;
    text-align: center;
    top: 14px;
    transform: rotate(-45deg);
    width: 100px;
    z-index: 1;
    text-transform: uppercase;
  }

  &.-right {
    #{$root}__mask {
      left: inherit;
      right: 0;
    }

    #{$root}__ribbon {
      transform: rotate(45deg);
      left: inherit;
      right: -24px;
    }
  }
}
