.location-filter {
  $root: &;

  display: block;
  width: 100%;

  &__box {
    border: 1px solid rgba($color-black, 0.1);
    border-radius: 4px;
    height: 340px;
    display: flex;
    flex-direction: column;
  }

  &__tags {
    border-top: 1px solid rgba($color-black, 0.1);
    padding: 10px 5px 5px 10px;
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    max-height: 97px;
    overflow-y: auto;

    &.-scrollable {
      box-shadow: inset 0 -2px 5px rgba($color-black, 0.1);
    }
  }

  &__tag {
    border-radius: 4px;
    background: $color-blue-clear;
    display: flex;
    align-items: center;
    margin: 0 5px 5px 0;
  }

  &__tag-text {
    color: $color-white;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    margin: 0 10px;
  }

  &__tag-btn {
    color: rgba($color-white, 0.5);
    width: 34px;
    height: 34px;
    text-align: center;
    line-height: 34px;
    border-left: 1px solid rgba($color-white, 0.2);
    font-size: 16px;

    &:hover {
      color: $color-white;
      cursor: pointer;
    }
  }

  &__search {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    height: 44px;
    padding: 0 10px;
  }

  &__search-icon {
    width: 26px;
    height: 26px;
    text-align: center;
    line-height: 26px;
    font-size: 24px;
    margin: 0 5px 0 -4px;
  }

  &__search-input {
    outline: 0;
    border: 0;
    background: 0;
    height: 26px;
  }

  &__items {
    border-top: 1px solid rgba($color-black, 0.1);
    flex-grow: 1;
    overflow-y: auto;

    &.-scrollable {
      box-shadow: inset 0 -2px 5px rgba($color-black, 0.1);
    }
  }

  &__item {
    border-bottom: 1px solid rgba($color-black, 0.1);
    display: flex;
    align-items: center;
    padding: 10px;

    &:hover {
      cursor: pointer;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  &__item-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    flex-grow: 1;

    &.-highlight {
      color: $color-blue-clear;
    }
  }

  &__item-type {
    font-size: 14px;
    font-weight: 500;
    color: rgba($color-black, 0.5);
    line-height: 24px;
  }
}
