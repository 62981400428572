// .boot-loader {

// }

.boot-loader-logo {
  animation: bootlogo 7s infinite ease-in-out;
  background: url('../img/glowdude.png') no-repeat center;
  background-size: contain;
  bottom: 0;
  height: 200px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform-origin: top;
  width: 200px;
  z-index: 0;
}

.boot-loader-text {
  bottom: -230px;
  color: $color-grey-medium;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 200px;
}

@keyframes bootlogo {
  0% {
    transform: scale(1) rotate(-3deg);
  }

  25% {
    transform: translate(-5px, -5px) scale(0.975) rotate(3deg);
  }

  50% {
    transform: translate(5px, 5px) scale(0.95) rotate(-3deg);
  }

  75% {
    transform: translate(-15px, -15px) scale(0.975) rotate(3deg);
  }

  100% {
    transform: scale(1) rotate(-3deg);
  }
}
