.create-page-panel {
  .gl-form-actions {
    text-align: right;
  }

  &-wrapper {
    @extend %container-white;
    margin: 0 auto;
    max-width: 500px;
  }

  &-header {
    padding: 14px 0 7px;

    h4 {
      @extend %ellipsis;

      display: inline-block;
      font-size: em(16);
      font-weight: 400;
      margin: 0;
      margin-bottom: 7px;
      padding: 0;
      width: 60%;
    }
  }
}
