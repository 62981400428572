.survey-channels {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 0 40px;

  &__chart {
    background: white;
    border-radius: 4px;
    padding: 20px;
    margin: 0 0 40px;
  }

  &__toolbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 0 40px;
  }

  &__toolbar-gap {
    flex: 1;
  }

  &__tab {
    height: 36px;
    border-radius: 4px;
    background: rgba($color-white, 0.4);
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    cursor: pointer;
    span {
      font-size: 14px;
      font-weight: 500;
    }
    &:hover {
      background: rgba($color-white, 0.55);
    }
    &.-active {
      background: white;
    }
  }

  &__action {
    margin-left: 8px;
  }

  &__loading {
    padding: 100px 0;
    display: flex;
    justify-content: center;
  }

  &__zero {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 100px 0;
  }

  &__zero-title {
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 12px;
  }

  &__zero-text {
    // ...
  }

  &__error {
    text-align: center;
    padding: 100px 0;
  }

  &__content {
    // ...
  }

  &__channel {
    margin: 0 0 20px;
  }

  &__responses {
    background: white;
    border-radius: 4px;
  }

  &__responses-toolbar {
    display: flex;
    align-items: center;
    padding: 0 16px 0 0;
    border-bottom: 1px solid rgba($color-black, 0.05);
  }

  &__responses-toolbar-toggle {
    padding: 14px 16px;
    cursor: pointer;
  }

  &__responses-toolbar-gap {
    flex: 1;
  }

  &__responses-toolbar-btn {
    display: flex;
    align-items: center;
    margin-left: 16px;
    cursor: pointer;
    &.-active {
      color: $color-blue-clear;
    }
  }

  &__responses-toolbar-btn-icon {
    margin-right: 2px;
    line-height: 0;
  }

  &__responses-toolbar-btn-label {
    font-size: 14px;
    font-weight: 500;
  }

  &__responses-toolbar-info {
    font-size: 14px;
    color: rgba($color-black, 0.4);
  }

  &__responses-loading {
    text-align: center;
    padding: 30px;
  }

  &__responses-zero {
    text-align: center;
    color: rgba(0, 0, 0, 0.3);
    padding: 30px;
  }

  &__responses-error {
    text-align: center;
    color: rgba(0, 0, 0, 0.3);
    padding: 30px;
  }

  &__response {
    display: flex;
    align-items: center;
    padding: 0 16px 0 0;
    cursor: pointer;
    border-bottom: 1px solid rgba($color-black, 0.05);
    &:last-child {
      border-bottom: 0;
    }
  }

  &__response-toggle {
    padding: 16px;
  }

  &__response-label {
    flex: 1;
  }

  &__response-id {
    color: rgba($color-black, 0.3);
    font-size: 14px;
  }

  &__quotas {
    background: white;
    border-radius: 4px;
    padding: 20px 20px 30px;

    &-zero {
      margin-top: 24px;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.3);
    }

    &-content {
      //...
    }

    &-header {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
    }

    &-subtitle {
      flex: 1;
      font-size: 16px;
      color: rgba($color-black, 0.56);
      margin-right: 8px;
    }

    &-actions {
      display: flex;
      align-items: center;
    }

    &-action {
      padding: 0 5px;

      &.-first {
        min-width: 0; // to prevent dropdown from stretching
        flex-basis: 70%;
      }
      &.-second {
        min-width: 0; // to prevent dropdown from stretching
        flex-basis: 30%;
      }
    }

    &-item {
      display: flex;
      align-items: flex-end;
    }

    &-input {
      flex: 1;
      padding: 0 5px;

      &-field {
        display: block; // needed to make max-width work
        max-width: 220px;
      }
    }

    &-stat {
      flex: 1;
      padding: 0 5px;
      max-width: 300px;

      &-title {
        font-size: 14px;
        margin: 0 0 8px;
      }

      &-bar {
        display: flex;
        align-items: stretch;
        height: 12px;
        border-radius: 4px;
        background: rgba(0, 0, 0, 0.05);
        overflow: hidden;

        &-section {
          &.-responses {
            background: $color-green-seafoam;
          }
        }
      }
    }
  }
}
