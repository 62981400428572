.filter-set-dialog {
  &__dialog {
    max-width: 780px;
  }

  &__header {
    &-top {
      display: flex;
      align-items: center;
      padding: 16px 24px;
    }

    &-title {
      flex-grow: 1;
      font-size: 20px;
      font-weight: 500;
      line-height: 1;
    }

    &-buttons {
      display: flex;
      align-items: center;

      g-btn {
        &:not(:last-child) {
          margin: 0 8px 0 0;
        }
      }
    }

    &-tabs {
      margin: -5px 0 0px 0;
    }
  }

  &__content {
    padding: 24px;
  }
}
