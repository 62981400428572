$sidenav-default-width: 304px !default;
$sidenav-min-width: 263px !default;
$sidenav-hide-at: 1065px;

// must wrap containing element with this class
.gl-sidenav-container {
  display: block;
  margin-left: $sidenav-default-width;
  position: relative;

  @media all and (max-width: $sidenav-hide-at) {
    margin-left: 0;
  }
}

.gl-sidenav {
  @include material-shadow(1);

  background-color: $color-white;
  bottom: 0;
  float: left;
  left: 0;
  max-width: $sidenav-default-width; // to ensure right spacing
  min-width: $sidenav-min-width; // ensure does not shrink too far - iphone4 supported as minimum
  overflow: auto;
  position: fixed;
  right: 56px; // to ensure right spacing
  top: 0;
  transition: all $swift-ease-in-out-duration $swift-ease-in-out-timing-function;
  z-index: $z-index-sidenav;

  @media all and (max-width: $sidenav-hide-at) {
    position: fixed; // fixed so can scroll even when body overflow set to hidden
    transform: translate(-100%, 0);

    &.gl-open {
      transform: translate(0%, 0);
    }
  }
}

.gl-sidenav-close {
  cursor: pointer;
  font-size: 1.6rem;
  margin-left: 12px;

  @media all and (min-width: $sidenav-hide-at) {
    display: none;
  }
}

.gl-btn-none.gl-sidenav-toggle {
  @media all and (min-width: $sidenav-hide-at) {
    display: none;
  }
}
