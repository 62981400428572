.project {
  &__page {
    display: block;
    padding: 30px 40px;
  }

  &__content {
    display: block;
  }

  &__sidebar {
    display: block;
    background: $color-white;
    border-radius: 4px;
  }

  &__heading {
    display: flex;
    align-items: center;
    margin: 0 0 20px;

    &-title {
      font-size: 24px;
      font-weight: 500;
      margin-right: 16px;
    }
  }

  &__sidebox {
    padding: 20px;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }

    &-head {
      display: flex;
      align-items: center;
      margin: 0 0 16px;
    }

    &-icon {
      margin-right: 5px;
    }

    &-title {
      font-size: 15px;
      font-weight: 500;
    }

    &-btns {
      // ...
    }

    &-btn:not(:last-child) {
      margin: 0 0 8px;
    }

    &-text {
      font-size: 14px;
      font-weight: 400;
      margin: 0 0 16px;
    }

    &-link {
      margin: 10px 0 0;

      > a {
        font-size: 14px;
        color: $color-orange-blaze;
      }
    }
  }

  &__resources {
    margin: 16px 0;
  }

  // ---

  &__header {
    display: flex;
    flex-direction: column;
    margin: 0 0 16px;

    &-upper {
      display: flex;
      align-items: center;
    }
  }

  &__header-btns {
    display: flex;
  }

  &__header-btn {
    margin: 0 0 0 4px;
  }

  &__header-text {
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin: 8px 0;
  }

  &__header-btn {
    margin: 0 0 0 8px;
    line-height: 1;
  }

  &__tabs {
    display: flex;
    align-items: center;
    margin: 0 0 16px;
  }

  &__tab {
    height: 36px;
    min-width: 100px;
    border-radius: 4px;
    background: rgba($color-white, 0.4);
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
    span {
      font-size: 14px;
      font-weight: 500;
    }
    &:hover {
      background: rgba($color-white, 0.55);
    }
    &.-active {
      background: white;
    }
  }

  &__grid {
    margin: 0 -8px;
  }

  &__grid-item {
    display: inline-block;
    vertical-align: top;
    padding: 0 8px 16px;
    width: 25%;
  }

  &__zero {
    margin: 64px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgba($color-black, 0.3);
  }

  &__zero-icon {
    // ...
  }

  &__zero-text {
    font-size: 18px;
    // color: rgba($color-black, .5);
  }

  &__archived-cta {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($color-black, 0.05);
    padding: 15px 25px;
    border-radius: 4px;
    margin: 20px 0;

    g-btn {
      margin: 0 0 0 5px;
    }
  }

  &__archived-message {
    flex-grow: 1;
  }

  &__archive-head {
    display: flex;
    align-items: center;
    margin: 20px 0;
  }

  &__archive-title {
    margin: 0;
    line-height: 1;
  }

  &__archive-toggle {
    margin: 0 0 0 20px;
    font-size: 14px;
  }

  @media all and (max-width: 900px) {
    &__header-upper {
      align-items: flex-start;
      flex-direction: column;
      margin: 0 0 10px;
    }

    &__header-btns {
      align-self: flex-end;
    }

    &__grid-item {
      width: 33.3333333333%;
    }
  }

  @media all and (max-width: 680px) {
    &__grid-item {
      width: 50%;
    }
  }

  @media all and (max-width: 400px) {
    &__grid-item {
      width: 100%;
    }
  }
}
