.report-subtype-switcher {
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding: 0 10px;

  &__control {
    flex: 1;
    min-width: 0;
  }

  &__btn {
    margin-left: 8px;
    width: 36px;
    height: 36px;
    border-radius: 4px;
    background: rgba($color-black, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba($color-black, 0.5);
    cursor: pointer;

    &.-disabled {
      cursor: inherit;
      color: rgba($color-black, 0.2);
    }
  }
}
