.survey-explorer-filters-dialog {
  &__dialog {
    max-width: 420px;
  }

  &__head {
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 70px;
  }

  &__title {
    flex: 1;
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
  }

  &__search {
    padding: 0 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &__list {
    flex: 1;
    padding: 24px;
    overflow-y: auto;
    height: 350px;
  }

  &__select {
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    > div {
      margin-right: 8px;
      cursor: pointer;
      font-size: 14px;
    }
  }

  &__filter {
    display: flex;
    align-items: center;
    margin: 0 0 12px;
    cursor: pointer;
    &-check {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.05);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 12px 0 0;
      .g-icon {
        display: none;
      }
    }
    &.-selected {
      .g-icon {
        display: block;
      }
    }
  }

  &__foot {
    padding: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
  }

  &__foot-gap {
    flex: 1;
  }

  &__action {
    margin: 0 8px 0 0;
    &.-right {
      margin: 0 0 0 8px;
    }
  }
}
