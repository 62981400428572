.account-auth {
  &__link {
    color: $color-orange-blaze;

    &:hover,
    &:active,
    &:focus {
      color: lighten($color-orange-blaze, 5%);
    }
  }

  &__dialog {
    max-width: 400px;
  }

  &__content {
    padding: 1px 25px 25px;
  }

  &__sidebyside {
    display: flex;
    align-items: flex-start;
    margin: 0 -5px -24px;

    > div {
      padding: 0 5px;
      flex-basis: 50%;
    }
  }

  &__invalid-email {
    margin-top: -21px;
    font-size: 14px;
    font-style: italic;
  }

  &__errors {
    margin: 0 0 20px;
  }

  &__footer {
    display: flex;
    align-items: center;

    &-aside {
      flex-grow: 1;
      text-align: right;
      font-size: 14px;
    }
  }
}
