@import './renamer/report-crosstab-renamer';

.report-crosstab {
  background: white;
  border-radius: 4px;
  margin: 0 0 16px;

  &__editor {
    padding: 20px;

    &-title {
      font-weight: 600;
    }

    &-btns > * {
      margin-right: 8px;
    }
  }

  &__data {
    padding: 10px 12px;
  }

  &__header {
    display: flex;
    align-items: center;

    &.-expand {
      margin: 0 0 16px;
    }

    &__chevron {
      margin: 0 10px 0 0;
    }

    &-title {
      flex: 1;
      cursor: pointer;
    }

    &-status {
      font-size: 12px;
      color: rgba($color-black, 0.4);
    }

    &-btn {
      margin-left: 8px;
      color: rgba($color-black, 0.6);
      cursor: pointer;

      &.-disabled {
        pointer-events: none;
        color: rgba($color-black, 0.2);
      }
    }
  }

  &__table-scroll {
    overflow-x: auto;
    padding: 0 8px 10px;
  }

  &__table {
    th {
      text-align: center;
    }

    td,
    th {
      border: 1px solid #999;
      padding: 0.5rem;
    }

    .-no-wrap {
      white-space: nowrap;
    }

    .-right {
      text-align: right;
    }
  }

  &__prefilter-text {
    margin-top: 16px;
    font-size: 13px;
  }
}
