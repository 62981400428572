%report-table-item {
  display: flex;
  align-items: center;
  padding: 5px;
  min-height: 30px;

  &.-align-right {
    justify-content: flex-end;
  }

  &.-align-center {
    justify-content: center;
  }

  &.-expand {
    flex-grow: 1;
  }

  &.-fixed-width {
    flex-shrink: 0;
  }

  &:first-child {
    padding-left: 10px;
  }

  &:last-child {
    padding-right: 10px;
  }
}

.report-table {
  @include gl-scroll-class-mixin;
  $root: &;
  display: flex;
  flex-direction: column;
  height: 100%; // FIX: IE11 flex-min-height bug

  &__loading {
    text-align: center;
    padding: 16px;
  }

  &__header {
    display: flex;
    flex-shrink: 0;
    position: sticky;
    top: 0;
  }

  &__header-item {
    @extend %report-table-item;
    background-color: white;
    border-bottom: 1px solid rgba($color-black, 0.1);
  }

  &__header-color {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    flex-shrink: 0;
  }

  &__header-text {
    font-size: 12px;
  }

  &__header-caret {
    color: rgba($color-black, 0.2);
    font-size: 12px;
    height: 12px;
    line-height: 1;
    margin: 0 0 0 3px;

    &.-active {
      color: $color-black;
    }
  }

  &__body {
    flex-grow: 1;
  }

  &__body-row {
    display: flex;

    &.-footer {
      #{$root}__body-item {
        border-top: 1px solid rgba($color-black, 0.08);
      }
    }

    &.-clickable {
      &:hover {
        #{$root}__body-item {
          cursor: pointer;
          background-color: rgba($color-black, 0.05);
        }
      }
    }

    &:last-child {
      margin: 0 0 8px;
    }

    &:nth-child(even) {
      #{$root}__body-item {
        background: rgba($color-black, 0.03);
      }
    }
  }

  &__body-item {
    @extend %report-table-item;
  }

  &__body-item-color {
    color-adjust: exact; // for print style
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 12px 0 3px; // centers with icons
    flex-shrink: 0;
  }

  &__body-item-icon {
    flex-shrink: 0;
    font-size: 24px;
    width: 24px;
    height: 18px;
    line-height: 0.8;
    margin: 0 5px 0 -4px; // centers with colors
  }

  &__body-item-text {
    font-size: 11px;
    word-break: break-word;

    &.-bold {
      font-weight: 600;
    }
  }

  &__body-item-filter {
    color: rgba($color-black, 0.3);
    margin: 0 0 0 10px;
  }

  &__body-item-rating-icon {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;

    .gi {
      color: $color-star-yellow;
    }
  }
}
