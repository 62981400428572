.channel-list-item {
  background-color: $color-white;
  border-radius: 4px;

  &__head {
    padding: 10px 20px;
    display: flex;
    align-items: center;
  }

  &__head-content {
    flex-grow: 1;
  }

  &__head-btn {
    margin: 0 0 0 8px;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &-name {
      font-size: 16px;
      font-weight: 500;
    }
  }

  &__status {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &-circle {
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background: rgba($color-black, 0.05);
      margin: 0 4px 0 0;

      &.-green {
        background: $color-green-seafoam;
      }

      &.-orange {
        background: $color-orange-glow;
      }

      &.-red {
        background: $color-red-candy;
      }
    }

    &-text {
      font-size: 11px;
      margin-right: 8px;
    }
  }

  &__dashboard {
    border-top: 1px solid rgba($color-black, 0.05);
  }

  &__form {
    padding: 20px;
    border-top: 1px solid rgba($color-black, 0.05);

    &.-text-center {
      text-align: center;
    }
  }

  &__remove {
    display: inline-flex;
    align-items: center;
    color: $color-red-candy;
    cursor: pointer;

    .g-icon {
      margin-right: 5px;
    }
  }

  &__error {
    font-size: 14px;
    margin: 0 0 0 10px;
    padding: 0 30px 11px;
    text-align: right;

    span {
      color: $color-red-candy;
    }

    a {
      margin: 0 0 0 8px;
    }
  }

  &__switch {
    display: flex;
    align-items: center;

    &-title {
      font-size: 16px;
      font-weight: 500;
      margin: 0 0 0 10px;
    }

    &-sub-title {
      font-size: 14px;
      color: rgba($color-black, 0.56);
      margin: 0 0 0 10px;
    }
  }
}
