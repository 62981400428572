.experience {
  $root: &;

  border-radius: 15px;
  background: rgba($color-black, 0.03);
  overflow: hidden;

  &__content {
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 5px 0 10px;
    transition: margin 0.15s ease-out;
  }

  &__label {
    font-size: 12px;
    font-weight: 500;
    margin: 0 5px 0 0;
    white-space: nowrap;
  }

  &__face {
    line-height: 0;
    margin: 2px -3px 0;
  }

  &__separator {
    height: 30px;
    border-left: 1px solid rgba($color-black, 0.03);
    margin: 0 5px;
  }

  &__hide {
    line-height: 0;
  }

  &.-collapsed {
    #{$root}__content {
      margin: 0 -30px 0 -153px;
    }
  }

  @media all and (max-width: 767px) {
    &__content {
      margin: 0 -30px 0 -153px;
    }
  }
}
