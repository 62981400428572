.report-loading-spinner {
  display: block;
  margin-top: 100px;
  text-align: center;

  &.-overlay {
    position: absolute;
    z-index: 2;
    width: 100%;
  }

  &__text {
    font-size: 16px;
    font-weight: 500;
    padding: 0 40px;
  }
}
