.signup {
  $mobile-break-point: 640px;
  max-width: 850px;
  width: 100%;
  padding: 20px;
  margin: 0 auto 30px;

  &__loading {
    text-align: center;
  }

  &__header {
    display: flex;
    align-items: flex-end;
    margin: 25px 0;
  }

  &__logo-wrapper {
    flex-grow: 1;
  }

  &__logo {
    max-width: 130px;
    height: auto;
  }

  &__box {
    box-shadow: 0 2px 10px rgba($color-black, 0.2);
    border-radius: 4px;
  }

  &__box-content {
    display: flex;
    align-items: flex-start;
  }

  &__box-side {
    padding: 24px 24px 24px 0;
    flex-grow: 1;
  }

  &__form {
    display: block;
    min-width: 400px;
    flex-grow: 1;
  }

  &__overview {
    display: flex;
    align-items: flex-start;
    border-top: 3px solid $color-orange-blaze;
    padding: 20px 0;
  }

  &__overview-main {
    flex-grow: 1;
  }

  &__overview-aside {
    flex-shrink: 0;
    margin-left: 30px;
  }

  &__login-cta {
    font-size: 14px;
    padding: 5px 0;
    text-align: right;
  }

  &__description {
    margin: 0 0 16px;
  }

  &__promo-info {
    font-size: 13px;
    font-style: italic;
    margin: 16px 0;
    text-align: center;
    color: rgba($color-black, 0.3);
  }

  &__forgot-password {
    font-size: 12px;
  }

  &__promo {
    flex-grow: 1;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 50px 24px;
    text-align: center;

    &-name {
      font-size: 17px;
      margin: 0 0 16px;
    }

    &-amount {
      font-size: 27px;
      font-weight: 500;
    }

    &-details {
      color: rgba($color-black, 0.3);
      font-size: 14px;
      margin: -5px 0 16px;
    }

    &-desc {
      font-size: 13px;
    }
  }

  @media all and (max-width: $mobile-break-point) {
    padding: 10px;

    &__header {
      flex-direction: column;
    }

    &__logo-wrapper {
      align-self: flex-start;
    }

    &__logo {
      max-width: 80px;
    }

    &__overview {
      flex-direction: column;
    }

    &__overview-aside {
      margin: 20px 0 0;
      align-self: center;
    }

    &__box-content {
      flex-direction: column-reverse;
    }

    &__box-side {
      padding: 24px 24px 0;
      width: 100%;
    }

    &__form {
      min-width: inherit;
      width: 100%;
    }
  }
}
