.project-resources {
  &__resource {
    display: flex;
    align-items: center;
    margin: 0 0 16px;

    &-type {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background: rgba($color-black, 0.05);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
    }

    &-info {
      flex: 1;
      margin: 0 8px;
      min-width: 0;
    }

    &-name {
      font-weight: 500;
      margin: 0 0 4px;
      line-height: 1;
      @extend %ellipsis;
    }

    &-sub {
      font-size: 12px;
      color: rgba($color-black, 0.5);
      margin: 0;
      line-height: 1;
      @extend %ellipsis;
    }

    &-btn {
      margin: 0 0 0 8px;
      color: rgba($color-black, 0.1);
    }
  }
}
