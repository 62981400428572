.tip {
  background: rgba($color-white, 0.98);
  border-radius: 4px;
  box-shadow: 0 10px 90px rgba($color-black, 0.6);
  left: 0;
  max-width: 350px;
  opacity: 0;
  padding: 20px;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease-in-out;
  z-index: $z-index-tip;

  &.visible {
    opacity: 1;
  }

  &-content {
    display: flex;
    flex-direction: column;
  }

  &-cancel {
    color: rgba($color-black, 0.3);
    font-size: 20px;
    height: 40px;
    line-height: 2;
    position: absolute;
    right: 7px;
    text-align: center;
    top: 10px;
    width: 40px;

    &:hover {
      color: $color-black;
      cursor: pointer;
    }
  }

  &-title {
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 10px;
  }

  &-action {
    align-items: center;
    display: flex;
    margin-top: 10px;
  }

  &-note {
    color: rgba($color-black, 0.3);
    font-size: 12px;
    font-weight: 500;
    line-height: 1.3;
  }

  &-done {
    margin-right: 10px;
  }
}

.tip-arrow {
  bottom: -10px;
  height: 10px;
  left: 0;
  margin-left: -7px;
  overflow: hidden;
  position: absolute;
}

.tip-arrow-visual {
  background: rgba($color-white, 0.98);
  height: 10px;
  position: relative;
  transform: rotate(45deg);
  width: 10px;
}

.tip-arrow-above {
  top: -10px;

  .tip-arrow-visual {
    bottom: -7px;
  }
}

.tip-arrow-below {
  bottom: -10px;

  .tip-arrow-visual {
    top: -7px;
  }
}

.tip-checkbox {
  bottom: 9px;
}
