.logic-editor {
  &__dialog {
    max-width: 800px;

    @media all and (max-width: 860px) {
      max-width: 90%;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid rgba($color-black, 0.05);
  }

  &__header-title {
    flex-grow: 1;
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
    margin: 0;
  }

  &__header-buttons {
    display: flex;
    align-items: center;

    g-btn {
      &:not(:last-child) {
        margin: 0 8px 0 0;
      }
    }
  }

  &__content {
    padding: 30px 24px;
  }

  &__title {
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 16px;
  }

  &__zero {
    text-align: center;
    padding: 40px 0 80px;
    color: rgba($color-black, 0.3);
  }

  &__btns-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__btns {
    // ...
  }

  &__btn {
    display: inline-block;

    &.-margin-left {
      margin-left: 8px;
    }

    &.-margin-right {
      margin-right: 8px;
    }
  }

  &__logic {
    border-bottom: 1px solid rgba($color-black, 0.1);
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  &__logic-head {
    display: flex;
    align-items: center;
    margin: 0 0 8px;

    &-title {
      flex: 1;
      font-weight: 500;
    }

    &-btn {
      margin-left: 8px;
    }
  }

  &__logic-row {
    display: flex;
    align-items: center;
  }

  &__logic-row-fields {
    flex: 1;
    // display: flex;
    // align-items: center;
    margin: 0 -2px 8px;
    max-width: 100%;
    min-width: 0;

    &.-two > div {
      width: 50%;
    }

    &.-three > div {
      width: 33.3333%;
    }

    &.-four > div {
      width: 25%;
    }

    &.-five > div {
      width: 20%;
    }

    &.-six > div {
      width: 16.6667%;
    }
  }

  &__logic-row-field {
    display: inline-block;
    // flex: 1;
    // max-width: 20%;
    // flex-basis: 20%;
    padding: 0 2px;
    margin-bottom: 5px;
  }

  &__logic-row-side {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 0 8px 8px;
    width: 75px;
  }

  &__logic-row-and {
    font-size: 14px;
    font-weight: 500;
    width: 38px;
    text-align: center;
  }

  &__logic-row-add {
    margin: 0 10px;
  }

  &__logic-row-gap {
    border-right: 1px solid rgba($color-black, 0.1);
    height: 34px;
    margin: 0 8px;
  }

  &__logic-row-remove {
    // margin: 0 0 0 8px;
  }

  &__tip {
    margin-top: 24px;
    display: flex;
    align-items: center;

    &-icon {
      margin-right: 8px;
    }

    &-text {
      font-size: 11px;
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: white;
    z-index: 1;
    position: sticky;
    top: 20px;
    border-radius: 8px;
    padding: 8px;
    margin: -15px -8px 20px;

    &-btn {
      margin: 0 4px;
      &:hover {
        cursor: pointer;
      }
      &.-disabled {
        color: rgba(0, 0, 0, 0.3);
      }
    }

    &-info {
      flex: 1;
      display: flex;
      justify-content: center;
    }
  }
}
