.gl-view {
  display: flex;
  flex-direction: column;
  height: 100vh;

  &-content {
    flex: 1 0 auto;
  }

  &-footer {
    flex-shrink: 0;
  }
}
