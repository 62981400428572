$chart-spacing: 30px;

.activity-charts {
  &-wrapper {
    display: inline-block;
    vertical-align: top;
    width: 50%;

    &:first-child {
      padding-right: $chart-spacing / 2;
    }

    &:last-child {
      padding-left: $chart-spacing / 2;
    }
  }

  &-box {
    @extend %container-white;

    padding: 10px 25px 20px;
  }

  // media queries
  @media all and (max-width: 768px) {
    &-wrapper {
      display: block;
      width: inherit;

      &:first-child {
        margin: 0 0 5px;
      }

      &:first-child,
      &:last-child {
        padding: inherit;
      }
    }
  }
}

.activity-chart {
  display: block;

  &-loading {
    bottom: 0;
    height: 20px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
  }

  &-title {
    font-weight: 500;
  }

  &-btns {
    float: right;
    margin: -6px -5px 0 0;

    span {
      cursor: pointer;
      font-size: 12px;
      font-weight: 600;
      opacity: 0.5;
      padding: 5px;
      text-transform: uppercase;
    }

    .gi {
      margin: 0 0 0 5px;
      vertical-align: middle;
    }

    .selected {
      opacity: 1;
    }
  }

  &-chartwrapper {
    height: 80px;
    position: relative;
  }

  &-chart {
    height: 100%;
    margin: 0;

    .c3-ygrid {
      stroke: lighten($color-grey, 10);
      stroke-dasharray: none;
    }

    .c3-axis-x line,
    .c3-axis-x path,
    .c3-axis-y line,
    .c3-axis-y path {
      stroke: $color-grey;
    }

    .tick text {
      fill: $color-grey-medium;
    }
  }

  &-line {
    pointer-events: none;

    line {
      stroke: rgba($color-black, 0.1);
    }
  }

  &-tooltip {
    background: rgba($color-white, 0.9);
    border: 1px solid rgba($color-black, 0.2);
    box-shadow: 0 1px 3px rgba($color-black, 0.3);

    .gi {
      border-right: 1px dashed rgba($color-black, 0.3);
      font-size: 24px;
      height: 30px;
      line-height: 1.3;
      margin: 0 10px 0 5px;
      vertical-align: middle;
      width: 30px;
    }

    .date {
      background: rgba($color-black, 0.1);
      font-size: 13px;
      font-weight: 500;
      padding: 2px 5px;
    }

    .text {
      font-size: 13px;
      line-height: 30px;
      margin: 0 10px 0 0;
      vertical-align: middle;
    }

    .-zero {
      margin-left: 10px;
    }
  }
}
