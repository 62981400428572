// Clearfix mixin
// ------------------------------------
@mixin clearfix() {
  &::before,
  &::after {
    content: '';
    display: table;
  }

  &::after {
    clear: both;
  }
}

// EM's conversion
// ------------------------------------
$browser-context: 16; // Default
@function em($pixels, $context: $browser-context) {
  @return #{$pixels / $context}em;
}

// Shadows
// ------------------------------------
@mixin material-shadow($depth: 1) {
  @if $depth == 1 {
    box-shadow: 0 1.5px 3px rgba($color-black, 0.24),
      0 3px 8px rgba($color-black, 0.05);
    // box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
  }
  @if $depth == 2 {
    box-shadow: 0 4px 7px rgba($color-black, 0.23),
      0 8px 25px rgba($color-black, 0.05);
    // box-shadow: 0 3px 6px rgba(0,0,0,.16), 0 3px 6px rgba(0,0,0,.23);
  }
  @if $depth == 3 {
    box-shadow: 0 7px 10px rgba($color-black, 0.3),
      0 10px 50px rgba($color-black, 0.12);
    // box-shadow: 0 10px 20px rgba(0,0,0,.19), 0 6px 6px rgba(0,0,0,.23);
  }
  @if $depth == 4 {
    box-shadow: 0 10px 15px rgba($color-black, 0.28),
      0 15px 60px rgba($color-black, 0.18);
    // box-shadow: 0 14px 28px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.22);
  }
  @if $depth == 5 {
    box-shadow: 0 16px 20px rgba($color-black, 0.29),
      0 20px 65px rgba($color-black, 0.18);
    // box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  }
}

// Breakpoint mixin
// ------------------------------------
@mixin respond-to($name) {
  // If the name exists
  @if map-has-key($breakpoints, $name) {
    // Print the media query based on the value
    @media #{inspect(map-get($breakpoints, $name))} {
      @content;
    }
  } @else {
    // If the name does not exist print warning
    @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`.'
            + 'Please makde sure it is defined in `$breakpoints` map.';
  }
}
