.survey-template-instructions {
  &__dialog {
    max-width: 650px;
  }

  &__inner {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__template-name {
    font-weight: 500;
    padding: 20px 0;
    text-align: center;
  }

  &__instructions {
    max-width: 500px;
  }

  &__list {
    list-style: decimal;
    padding: 0 0 0 17px;
    margin: 5px 0 0;
  }

  &__actions {
    margin: 40px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__checkbox {
    margin-top: 10px;
  }

  &__checkbox-label {
    font-size: 14px;
    margin: 0 0 0 5px;
  }

  &__btn {
    border-radius: 4px;
    font-size: 14px;
    text-transform: uppercase;
    height: 50px;
    width: 120px;
    line-height: 50px;
    margin: 10px 0 0;
    background: $color-blue-clear;
    color: $color-white;
    outline: 0;
    text-align: center;
    cursor: pointer;
  }
}
