.kit {
  &__head {
    display: flex;
    align-items: center;
    margin: 0 0 32px;
  }

  &__title {
    flex-grow: 1;
  }

  &__info {
    margin: 0 0 30px;
  }

  &__quota {
    margin: 0 0 30px;
  }

  &__zero {
    display: block;
    margin: 100px auto 50px;
    max-width: 300px;
  }
}
