.interaction-graph {
  height: 80px;
  position: relative;

  &-title {
    font-weight: 500;
  }

  &-loading {
    bottom: 0;
    height: 20px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
  }

  &-chart {
    height: 100%;
    margin: 0;

    .c3-ygrid {
      stroke: lighten($color-grey, 10);
      stroke-dasharray: none;
    }

    .c3-axis-x line,
    .c3-axis-x path,
    .c3-axis-y line,
    .c3-axis-y path {
      stroke: $color-grey;
    }

    .tick text {
      fill: $color-grey-medium;
    }
  }
}
