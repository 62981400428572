.channel-confirm-charges {
  padding: 24px;

  &__dialog {
    max-width: 550px;
  }

  h4 {
    margin: 0 0 10px;
  }

  ul {
    font-size: 14px;
    padding: 0 24px;
    margin: 0 0 24px;
    li {
      list-style-type: disc;
      margin: 0 0 8px;
    }
  }

  &__estimate {
    margin: 20px 0;

    > b {
      color: $color-green-seafoam;

      &.-red {
        color: $color-red-candy;
      }
    }
  }

  %info-box {
    margin: 20px 0;
    padding: 8px 5px;
    border-radius: 4px;
    display: flex;
    align-items: center;
  }

  &__auto-renewal {
    @extend %info-box;
    background: rgba($color-green-seafoam, 0.1);

    &-text {
      font-size: 13px;
      margin: 0 0 0 5px;
    }
  }

  &__warning {
    @extend %info-box;
    background: rgba($color-orange-blaze, 0.1);

    &-text {
      font-size: 13px;
      margin: 0 0 0 5px;

      &.-red {
        color: red;
      }
    }
  }

  &__topup-btn {
    margin: 20px 0;

    .g-btn {
      background: $color-blue-clear;

      &:hover:not(.-disabled),
      &:active:not(.-disabled),
      &:focus:not(.-disabled) {
        background: lighten($color-blue-clear, 1%);
      }
    }
  }

  &__btns {
    margin: 24px 0 0;
  }

  &__btn {
    margin: 0 8px 0 0;
  }
}
