// Dropdown list
// ------------------------------------
$dropdown-list-logo-size: 22px;
$dropdown-zero-message-color: $input-disabled-font-color;

.dropdown-list {
  @extend %container-white;

  padding: 6px 0;
  position: absolute;
  right: 0;
  top: 40px;
  z-index: 12;

  @media (max-width: 415px) {
    left: 0;
    margin: 0 2.5%;
    position: fixed;
    top: 45px;
    width: 95%;
  }

  &-zero-msg {
    color: $dropdown-zero-message-color;
    padding-left: 42px;

    &:hover {
      color: $dropdown-zero-message-color;
    }
  }

  &-item {
    align-items: center;
    color: $base-font-color;
    display: flex;
    font-size: 0.9rem;
    padding: 2px 12px;

    &:hover {
      background-color: $color-white-alabaster;
    }

    &-icon {
      border-radius: 50%;
      font-size: 1.3rem;
      height: $dropdown-list-logo-size;
      line-height: 22px;
      margin-right: 8px;
      text-align: center;
      width: $dropdown-list-logo-size;
    }

    &-title {
      @extend %ellipsis;
    }
  }

  &-subheader {
    color: $color-grey-silver;
    font-size: em(13);
    line-height: 1;
    margin: 8px 20px 0;
  }

  &-separator {
    border: 0;
    border-top: 1px solid $color-grey-soft;
    height: 1px;
    margin: 2px 0;
  }
}

// Block List
// ------------------------------------
$block-list-item-height: 70px;
$block-list-icon-size: 40px;

.block-list {
  @extend %container-white;
  padding-bottom: 0;
  padding-top: 0;

  &-item {
    align-items: center;
    border-bottom: 1px solid $color-grey-gallery;
    display: flex;
    margin: 8px 0;
    padding: 12px 0;
    position: relative;

    &:last-child {
      border: 0;
    }
  }

  &-icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    font-size: 2em;
    height: $block-list-icon-size;
    line-height: $block-list-icon-size;
    margin-right: 16px;
    text-align: center;
    width: $block-list-icon-size;
  }

  &-content {
    overflow: auto;
  }

  &-title:not(.-no-ellipsis) {
    @extend %ellipsis;
  }

  &-subtitle {
    @extend %ellipsis;

    color: $color-grey-medium;
    font-size: 0.9rem;
    font-weight: 300;
  }

  &-actions {
    margin-left: auto;
    padding-left: 10px;
  }

  &-error {
    margin-top: 30px;
    text-align: center;
  }
}
