//
// Glow-icons icon font styles
//

.gi {
  display: inline-block;

  &.purple {
    color: $color-purple-fuchia;
  }

  &.blue {
    color: $color-blue-clear;
  }

  &.green {
    color: $color-green-seafoam;
  }

  &.yellow {
    color: $color-yellow-star;
  }

  &.orange {
    color: $color-orange-neon-carrot;
  }
}

.gi-circle,
%gi-circle {
  background-color: $color-orange-neon-carrot;
  border-radius: 50%;
  color: $color-white;
  font-size: 1.875rem;
  height: 40px;
  line-height: 40px;
  position: relative;
  text-align: center;
  top: 4px;
  width: 40px;

  &.purple {
    color: $color-purple-fuchia;
  }

  &.blue {
    color: $color-blue-clear;
  }

  &.green {
    color: $color-green-seafoam;
  }
}

.gi-circle-dark {
  @extend %gi-circle;

  background-color: $color-grey-severe;
}
