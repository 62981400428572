.shared-report {
  $header-size: 60px;

  &__header {
    height: $header-size;
    padding: 0 16px;
    background: $color-white;
    box-shadow: 0 1px 7px rgba($color-black, 0.1);
    border-bottom: 1px solid rgba($color-black, 0.1);
    display: flex;
    align-items: center;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  &__logo {
    max-height: 40px;
    margin: 0 8px 0 0;
  }

  &__title {
    font-size: 22px;
    font-weight: 500;
    flex-grow: 1;
  }

  &__power {
    display: flex;
    align-items: center;
  }

  &__power-link {
    font-size: 10px;
    font-weight: 500;
  }

  &__power-text {
    font-size: 12px;
  }

  &__power-logo {
    max-width: 50px;
    height: auto;
    margin: 0 8px;
  }

  &__content {
    padding-top: $header-size;
  }

  &__error {
    text-align: center;
    margin-top: 100px;
  }
}
