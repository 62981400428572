$kit-editor__color-error: rgba(255, 0, 0, 0.1);

.kit-editor {
  $root: &;

  &__status {
    display: flex;
    padding: 25px;

    .gi {
      font-size: 28px;
      height: 28px;
      line-height: 1;
      margin: 0 5px 0 -5px;
    }

    .gl-btn-none {
      margin: -7px;
    }
  }

  &__status-text {
    flex-grow: 1;
    margin-top: 2px;
  }

  &__block {
    background: $color-white;
    border-radius: 4px;

    &.-margin-bottom {
      margin-bottom: 30px;
    }

    &.-margin-top {
      margin-top: 30px;
    }
  }

  &__block-content {
    padding: 25px;
    position: relative;
  }

  &__description {
    resize: none;
    height: 150px;
  }

  &__image {
    width: 160px;
  }

  &__errors {
    background: $kit-editor__color-error;
    border: 1px solid $kit-editor__color-error;
    border-radius: 4px;
    margin: 0 0 25px;
    padding: 10px 25px;
  }

  &__error {
    font-size: 14px;
    font-weight: 500;
    margin: 5px 0;
  }

  &__actions {
    g-btn {
      margin: 0 0 0 10px;
    }
  }

  &__processing-text {
    color: rgba($color-black, 0.4);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__processing-spinner {
    display: inline-block;
    margin: 0 5px 0 0;
    transform: scale(0.6);
    vertical-align: middle;
  }
}
