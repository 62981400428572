%kit-card-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba($color-black, 0.01);
}

%kit-card-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba($color-blue-slate, 0.25);
  background: rgba($color-black, 0.01);
}

@mixin smallify($ctx) {
  #{$ctx}__content {
    display: none;
  }

  #{$ctx}__side {
    display: block;
    width: 100%;
    flex-basis: inherit;
  }

  #{$ctx}__side-name {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  #{$ctx}__side-footer {
    display: flex;
  }

  #{$ctx}__info {
    display: block;
  }
}

.kit-card {
  $root: &;

  &__front {
    display: flex;
  }

  &__side {
    flex-basis: 300px;
    flex-shrink: 0;
    position: relative;
  }

  &__square {
    position: relative;
    padding-bottom: 75%; // enforce 4:3 ratio
    background: rgba($color-black, 0.01);
  }

  &__square-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
  }

  &__icon {
    @extend %kit-card-icon;
    flex: 1;
  }

  &__image {
    @extend %kit-card-image;
    flex: 1;
  }

  &__side-name {
    display: none;
    font-size: 17px;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;
    padding: 5px 10px;
    height: 60px;
  }

  &__side-footer {
    display: none;
    height: 44px;
    align-items: center;
    justify-content: center;
  }

  &__owned {
    font-size: 12px;
    font-style: italic;
    color: rgba($color-black, 0.5);
  }

  &__info {
    display: none;
    position: absolute;
    top: 8px;
    right: 8px;
    color: white;
    text-shadow: 0 1px 5px rgba($color-black, 0.4);
    z-index: 1;

    &:hover {
      cursor: pointer;
    }
  }

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-left: 1px solid rgba($color-black, 0.1);
    height: 225px; // hardcoded height of box image
  }

  &__head {
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 0 8px;
  }

  &__name {
    flex-grow: 1;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    margin: 0 16px 0 0;
  }

  &__desc {
    flex-grow: 1;
    overflow-y: auto;
  }

  &__footer {
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 21px;
  }

  &__footer-btns {
    flex-grow: 1;
    display: flex;
  }

  &__footer-text {
    color: rgba($color-black, 0.4);
    font-size: 12px;
    font-weight: 500;
  }

  &__back {
    display: flex;
    flex-direction: column;
    padding: 37px 0 0;
  }

  &__back-close {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
  }

  &__back-scroll {
    flex: 1;
    padding: 0 16px 16px;
    overflow-y: auto;
  }

  &__desc-text {
    font-size: 14px;
    line-height: 1.3;
    margin: 0 0 16px;
  }

  &__desc-tag {
    font-size: 14px;
    line-height: 1.3;
  }

  &.-small {
    @include smallify($root);
  }

  @media all and (max-width: 588px) {
    @include smallify(&);
  }
}
