.channel-targeting {
  &__header {
    display: flex;
    align-items: center;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 0 10px;
  }

  &__sub-title {
    font-size: 14px;
    color: rgba($color-black, 0.56);
    margin: 0 0 0 10px;
  }

  &__editor {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
}
