.beacon-list-item {
  background: $color-white;
  border-radius: 4px;

  &__view {
    display: flex;
    align-items: center;
    padding: 15px;
  }

  &__view-btns {
    display: flex;
    align-items: center;
  }

  &__view-btn {
    margin: 0 0 0 10px;
  }

  &__remove {
    padding: 20px;

    &-title {
      font-weight: 500;
      margin: 0 0 5px;
    }

    &-text {
      margin: 0 0 15px;
    }

    &-actions {
      display: flex;
      align-items: center;
    }

    &-action {
      margin: 0 10px 0 0;
    }
  }

  &__info {
    flex-grow: 1;

    &-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 1;
      margin: 0 0 4px;
    }

    &-desc {
      font-size: 14px;
      font-weight: 400;
      color: rgba($color-black, 0.4);
      line-height: 1;
    }

    &-link {
      display: flex;
      align-items: center;

      a {
        @extend %ellipsis;
        font-size: 13px;
        margin-right: 5px;

        &.-disabled {
          color: rgba($color-black, 0.5);
          pointer-events: none;
        }
      }
    }

    &-type {
      padding-right: 10px;
      font-size: 14px;
      font-weight: 400;
      color: rgba($color-black, 0.4);
    }
  }

  &__edit {
    padding: 15px;
  }

  &__edit-title {
    font-size: 20px;
    margin: 0 0 15px;
    line-height: 1;
  }

  &__edit-content {
    display: flex;
  }

  &__edit-form {
    flex: 1;
    max-width: 500px;

    &-link {
      display: flex;
      align-items: center;

      &-text {
        font-size: 14px;
        margin-right: 5px;
        color: rgba($color-black, 0.5);
      }
    }

    &-distance {
      display: flex;
      align-items: center;

      &-select {
        min-width: 0;
        width: 100px;
        margin-right: 10px;
      }

      &-input {
        flex: 1;
      }
    }
  }

  &__edit-side {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0 0 0 15px;
  }

  &__edit-logo {
    //...
  }

  &__edit-logo-label {
    text-align: center;
    margin-bottom: 5px;
  }

  &__edit-qr {
    margin-top: 30px;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-label {
      margin-bottom: 10px;
    }

    &-btn {
      margin-top: 10px;
    }
  }

  &__edit-btns {
    display: flex;
    align-items: center;
  }

  &__edit-btn {
    margin: 0 10px 0 0;
  }

  &__error {
    font-size: 14px;
    color: $color-red-candy;
    font-weight: 500;
    line-height: 1.3;
  }

  @media all and (max-width: 450px) {
    &__remove {
      flex-direction: column;
    }
  }

  @media all and (max-width: 750px) {
    &__edit-content {
      flex-direction: column;
    }

    &__edit-logo {
      height: 300px;
      min-width: unset;
      margin: 0 0 20px 0;
    }
  }
}
