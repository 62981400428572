$header-height: 60px;
$header-padding-top: 12px;
$header-padding-left: 16px;
$header-item-padding-mobile: 16px;
$header-item-padding: 24px;
$header-separator-padding: 16px;
$header-font-color: #404b55;
$header-background-color: $color-white;

// automatically create padding for fixed positioned header
gl-sp-header::after {
  content: '';
  display: block;
  padding-top: $header-height;
}

.header {
  background-color: $header-background-color;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  color: $header-font-color;
  display: flex;
  flex-direction: row;
  height: $header-height;
  line-height: $header-height - 2 * $header-padding-top;
  padding: $header-padding-top $header-padding-left;
  position: fixed;
  width: 100%;
  z-index: $z-index-header;

  .icon-btn {
    color: $header-font-color;
    font-size: 1.6em;
    line-height: $header-height - 2 * $header-padding-top;
    margin-left: 8px;
    position: relative;
  }

  &-logo {
    margin-right: 5px;

    &-img {
      height: $header-height - 2 * $header-padding-top;
      min-width: $header-height - 2 * $header-padding-top;
      width: $header-height - 2 * $header-padding-top;
    }
  }

  &-settings {
    position: relative;
  }

  &-account {
    margin-left: auto;
  }
}

.header-nav {
  align-items: center;
  display: flex;
  height: $header-height - 2 * $header-padding-top;
  margin-right: 12px;

  &-separator {
    border-left: 2px solid $color-grey-silver;
    display: none;
    height: 100%;
    margin: 0 $header-separator-padding 0 $header-separator-padding +
      $header-item-padding;
  }

  &-item {
    color: $header-font-color;
    margin-left: $header-item-padding-mobile;
    position: relative;
    user-select: none;

    &:hover,
    &:focus {
      color: $color-blue-clear;
    }

    &:active,
    &.active {
      color: $color-blue-clear;
    }
  }

  &-item-name {
    display: none;
  }

  &-item-count {
    background-color: $color-red-candy;
    border-radius: 8px;
    bottom: 10px;
    color: $color-white;
    font-size: 0.7rem;
    font-weight: 500;
    height: 16px;
    line-height: 16px;
    min-width: 16px;
    padding: 1px 4px;
    position: absolute;
    right: -8px;
    text-align: center;
    z-index: 1;
  }

  .icon {
    font-size: 2rem;
    margin-right: 4px;
    vertical-align: middle;
  }
}

@media (min-width: 820px) {
  .header-logo {
    margin-right: 8px;
  }

  .header-nav {
    .icon {
      font-size: 1.6rem;
    }

    &-separator {
      display: inline-block;
    }

    &-item {
      margin-left: $header-item-padding;
    }

    &-item-name {
      display: inline-block;
    }

    &-item-count {
      right: -16px;
    }
  }
}
