.reply-wrapper {
  background-color: $color-white-alabaster;
  border-top: 1px solid $color-grey-gallery;
  overflow: hidden;
  padding: 16px 104px 16px 64px;
  position: relative;

  textarea {
    background: none;
    border: 0;
    min-height: 48px; // avoid height change when button shows
    outline: none;
    resize: none;
    width: 100%;
  }

  .reply-actions {
    position: absolute;
    right: 16px;
    top: 16px;
  }

  .icon {
    left: 16px;
    margin: 0;
    position: absolute;
    top: 12px;
  }

  .gi-circle {
    background-color: darken($color-grey-gallery, 10);
  }

  &.active {
    .gi-circle {
      background-color: $color-green-seafoam;
    }
  }

  .closed-text {
    color: rgba($color-black, 0.56);
    margin: 0;
    text-align: center;
    text-transform: uppercase;
  }
}
