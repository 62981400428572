.g-tabs {
  $root: &;

  display: flex;
  align-items: center;
  padding: 0 20px;
  border-bottom: 3px solid rgba($color-black, 0.05);

  &__tab {
    font-weight: 400;
    font-size: 16px;
    padding: 10px;
    margin: 0 10px -3px 0;
    border-bottom: 3px solid transparent;
    color: rgba($color-black, 0.7);
    cursor: default;

    &:hover:not(.-disabled) {
      cursor: pointer;
      color: $color-black;
    }

    &.-selected {
      border-bottom: 3px solid $color-blue-clear;
      color: $color-black;
    }

    &.-disabled {
      color: rgba($color-black, 0.3);
    }
  }

  &.-edge {
    padding-left: 0;
    padding-right: 0;
  }

  &.-mini {
    padding: 0 16px;

    #{$root}__tab {
      font-size: 15px;
      padding: 8px;
      margin-right: 8px;
    }
  }

  &.-borderless {
    border-bottom-color: transparent;
  }
}
