.interaction-zero {
  color: $color-grey-medium;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
}

.interaction-dialog {
  font-size: 0.875rem; // 14px
  margin: 0 auto;
  max-width: 100%;
  min-height: 100%;
  width: 100%;

  @media (min-width: 415px) {
    height: auto;
    margin-bottom: 64px;
    margin-top: 32px;
    max-width: 90%;
    min-height: auto;
    padding-bottom: 0;
  }

  @media (min-width: 990px) {
    max-width: 900px;
  }
}

.interaction-header {
  padding: 16px 60px 0;
}

.interaction-content {
  padding: 0 60px 16px;
}

.interaction-user-icon {
  font-size: 3em;
  left: 12px;
  position: absolute;
  top: 4px;
}

.interaction-user {
  font-size: 2em;
  font-weight: 300;
}

.interaction-description {
  font-size: 1.15em;
  margin: 0;
}

.interaction-info {
  display: flex;
  align-items: center;
}

.interaction-created {
  color: $color-grey-medium;
  margin: 0;
}

.interaction-id {
  margin: 0 0 0 20px;
  font-size: 11px;
  color: rgba($color-black, 0.4);
}

.interaction-data {
  margin: 20px 0;

  &-item {
    display: inline-block;
    border: 1px solid rgba($color-black, 0.1);
    border-radius: 42px;
    margin: 0 5px 5px 0;
    padding: 4px 12px;
    font-size: 14px;
  }
}

.interaction-message {
  border: 0;
  display: inline-block;
  font-size: 1.15em;
  font-style: italic;
  margin: 0;
  padding: 24px 16px;
  position: relative;

  &::before,
  &::after {
    color: rgba($color-black, 0.16);
    font-family: Arial, Tahoma, sans-serif;
    font-size: 3.5em;
    line-height: 1;
    position: absolute;
  }

  &::before {
    content: '\201C';
    left: -8px;
    top: 18px;
  }

  &::after {
    bottom: 16px;
    content: '\201D';
    line-height: 0;
    right: -8px;
  }

  p {
    margin: 0;
  }
}

.interaction-tag {
  display: inline-block;
  min-width: 250px;
  padding: 0 16px 16px 0;
  position: relative;
  vertical-align: top;

  .label {
    font-size: 0.8125rem; // 13px
    line-height: 1.3;
    overflow: hidden;
    padding: 4px 0 0 36px;
    text-overflow: ellipsis;
    text-transform: capitalize;
    white-space: nowrap;
  }

  .gi {
    color: $color-grey;
    font-size: 1.8rem;
    left: 0;
    position: absolute;
    top: -10px;

    &-mood-1 {
      color: $color-mood-green;
    }

    &-mood-2 {
      color: $color-mood-yellow;
    }

    &-mood-3 {
      color: $color-mood-orange;
    }

    &-mood-4 {
      color: $color-mood-grey;
    }

    &-mood-5 {
      color: $color-mood-pink;
    }

    &-mood-6 {
      color: $color-mood-red;
    }
  }
}

.interaction-image {
  display: block;
  margin-bottom: 24px;
  max-height: 120px;
  max-width: 200px;
}

.interaction-comment,
.reply-wrapper {
  .icon.gi-circle {
    height: 32px;
    line-height: 32px;
    margin-top: 0;
    width: 32px;
  }
}

.interaction-comment {
  border-top: 1px solid $color-grey-gallery;
  min-height: 48px;
  padding: 8px 0 16px;
  position: relative;

  .icon {
    left: 16px;
    margin: 0;
    position: absolute;
    top: 3px;

    &.gi-circle {
      background-color: rgba($color-black, 0.24);
      font-size: 1.05rem;
    }

    &.a {
      background-color: $color-orange-neon-carrot;
    }

    &.u {
      background-color: $color-blue-clear;
    }

    &.s {
      background-color: $color-green-seafoam;
    }
  }
}

.interaction-comment-header,
.interaction-comment-content {
  padding: 0 16px 0 64px;
}

.interaction-comment-header {
  position: relative;

  .name {
    color: $color-grey-medium;
    padding-right: 80px;
    word-break: break-word;
  }

  .date {
    color: rgba($color-black, 0.26);
    font-size: 0.8125rem;
    position: absolute;
    right: 16px;
    top: 0;
  }
}

.interaction-comment-content {
  .text {
    border: 0;
    font-size: 0.9375rem;
    margin: 0;
    padding: 0;
  }

  .flag {
    bottom: 16px;
    color: rgba($color-black, 0.26);
    cursor: pointer;
    font-size: 1.1rem;
    height: 20px;
    position: absolute;
    right: 16px;

    &.highlight {
      color: $color-danger;
    }
  }
}

// SURVEY
// ---------------
.survey-response {
  display: block;
  padding: 24px 0 0;
}

.survey-questions {
  counter-reset: steps;
  font-size: 1rem;

  li {
    margin: 0 15px 20px;
    padding-left: 40px;
    position: relative;
  }

  li::before {
    color: rgba($color-black, 0.24);
    content: counter(steps);
    counter-increment: steps;
    font-size: 1.5rem;
    left: 0;
    position: absolute;
    top: 0;
  }
}

.survey-question-text {
  color: rgba($color-black, 0.64);
  font-size: 0.875em;
  margin: 0;
}

.survey-question-answer {
  font-style: italic;
}

.survey-response-image {
  max-height: 120px;
  padding: 12px 0 0;
}

.survey-response-constant-sum {
  &-statement {
    font-weight: 600;
  }

  &-choice {
    padding-left: 10px;
  }
}
