.org-selector {
  &__dialog {
    max-width: 400px;
  }

  &__content {
    padding: 16px 24px;
  }

  &__item {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 8px;
    border-radius: 4px;

    &.-selected {
      font-weight: 500;
      color: rgba($color-black, 0.7);
    }

    &:hover {
      background: rgba($color-black, 0.05);
      cursor: pointer;
    }
  }

  &__name {
    @extend %ellipsis;
  }
}
