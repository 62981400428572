.survey-explorer-excluded-views-dialog {
  &__dialog {
    max-width: 450px;
    border: 0;
  }

  &__content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
  }

  &__item {
    display: flex;
    align-items: center;
    margin: 0 0 10px;
    &-label {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-btn {
      margin-left: 16px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
      padding: 0 8px;
      cursor: pointer;
    }
  }
}
