.channel-translation {
  &__header {
    display: flex;
    align-items: center;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 0 10px;
  }

  &__sub-title {
    font-size: 14px;
    color: rgba($color-black, 0.56);
    margin: 0 0 0 10px;
  }

  &__loading {
    padding: 20px;
  }

  &__error,
  &__zero {
    font-size: 14px;
    padding: 20px;
  }

  &__editor {
    display: flex;
    flex-direction: column;
  }

  &__field {
    width: 170px;
  }
}
