.publication-publisher {
  padding: 30px 20px 1px;

  &__title {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    color: rgba($color-black, 0.3);
    margin: 0 0 10px;
  }

  &__logo {
    margin: 0 0 20px;
  }

  &__btns {
    margin: 0 0 20px;
  }

  &__btn {
    display: block;
    margin: 0 0 10px;
  }

  &__text {
    font-size: 14px;
    line-height: 1.3;
    margin: 0 0 20px;
  }

  &__newsletter {
    margin: 0 0 20px;
  }
}
