.public-translation-editor {
  // matches g-dialog-header but has button actions
  &__header {
    display: flex;
    align-items: center;
    padding: 11px 24px;
    border-bottom: 1px solid rgba($color-black, 0.05);
    background: white;

    &-title {
      font-size: 20px;
      font-weight: 500;
      line-height: 1;
      flex: 1;
    }

    &-btn {
      margin-left: 8px;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    padding: 25px;
  }

  &__content-block {
    flex: 1;
    background: white;
    border-radius: 4px;
    padding: 25px;
    max-width: 600px;
  }

  &__search {
    display: flex;
    align-items: center;
    margin: 0 0 34px;

    &-filter {
      flex: 1;
    }

    &-actions {
      display: flex;
      align-items: center;
      margin-left: 24px;
    }

    &-action-label {
      // ...
    }

    &-action-checkbox {
      margin-left: 6px;
    }
  }
}
