.survey-preview-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: #eaeaef;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__header {
    background: white;
    height: 60px;
    padding: 0 16px;
    display: flex;
    align-items: center;
  }

  &__header-side {
    width: 240px;
    display: flex;
    align-items: center;
    &.-left {
      // ...
    }
    &.-right {
      justify-content: flex-end;
    }
  }

  &__header-mid {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__size-btn {
    padding: 4px;
    color: rgba($color-black, 0.2);
    cursor: pointer;
    &.-active {
      color: rgba($color-black, 0.64);
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    g-icon {
      color: rgba($color-black, 0.64);
    }
    &.-left {
      margin-right: 12px;
    }
    &.-right {
      margin-left: 12px;
    }
  }

  &__content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__frame {
    padding: 12px;
    border-radius: 8px;
    background: rgba($color-black, 0.1);
    width: 100%;
    height: 100%;
  }

  &__iframe {
    background: $color-white;
    border: 0;
    border-radius: 4px;
    outline: 0;
    width: 100%;
    height: 100%;
  }
}
