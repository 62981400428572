@mixin btn-base($ctx) {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 12px;
  height: 46px;

  #{$ctx}-text {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1;
  }

  #{$ctx}-icon {
    line-height: 1;
  }
}

.register-interest {
  &__button {
    @include btn-base(&);
    background: $color-blue-clear;

    &-text {
      color: $color-white;
    }

    &:hover {
      cursor: pointer;
      background: lighten($color-blue-clear, 5%);
    }
  }

  &__done {
    @include btn-base(&);
    background: rgba($color-black, 0.05);
  }
}
