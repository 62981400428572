.card {
  $z-index-info-icon: 5;
  $z-index-info-wrapper: 4;
  $root: &;

  background-color: $color-white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  &__icon {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    z-index: $z-index-info-icon;

    g-button {
      position: absolute;
      right: 8px;
      top: 8px;
    }
  }

  &__info {
    background-color: darken($color-blue-clear, 45%);
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    opacity: 0.85;
    overflow-y: hidden;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.25s ease-in-out;
    z-index: $z-index-info-wrapper;

    &.closed {
      height: 0;
    }

    &.ng-animate {
      #{$root}__pack-description-wrapper {
        overflow-y: hidden;
      }
    }
  }

  &__info-header {
    color: $color-white;
    flex-basis: 34px;
    flex-shrink: 0;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 15px 0;
  }

  &__info-content-wrapper {
    flex-grow: 1;
    overflow-y: auto;
  }

  &__info-content {
    color: $color-white;
    font-size: 14px;
    font-weight: 500;
    margin: 5px 15px;
  }

  &__image-wrapper {
    flex-basis: 180px;
    position: relative;
  }

  &__image-icon {
    color: rgba($color-blue-slate, 0.25);
  }

  &__image {
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__title-wrapper {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  &__title {
    font-size: 17px;
    font-weight: 500;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__sub-title {
    font-size: 12px;
    text-align: center;
  }

  &__footer {
    border-top: 1px solid rgba($color-black, 0.05);
    display: flex;
    padding: 10px;
  }

  &__footer-action {
    flex-basis: 0;
    flex-grow: 1;
  }
}
