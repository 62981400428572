.segment-manager {
  &__dialog {
    max-width: 400px;
  }

  &__content {
    padding: 15px 24px 24px;
  }

  &__textbox textarea {
    min-height: 73px;
    max-height: 73px;
  }
}
