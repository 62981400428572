.channel-list-item-dashboard {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;

  &__item {
    flex: 1;
    min-height: 85px;
    border-radius: 4px;
    border: 1px solid rgba($color-black, 0.05);
    margin: 0 0 10px;
    padding: 10px;
    display: flex;

    &.-wide {
      width: 100%;
    }
  }

  &__item-main {
    font-size: 14px;
    max-width: 184px; // HACK: stop growing due to long links
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__item-title {
    font-size: 14px;
  }

  &__item-link {
    display: flex;
    a {
      @extend %ellipsis;
    }
  }

  &__item-aspect {
    color: rgba($color-black, 0.56);
  }

  &__item-image {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 50px;
    }
  }

  &__item-qr {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-left: 20px;
    width: 70px;
    flex: 0 0 auto; /* Don't grow, don't shrink, auto basis */

    img {
      cursor: pointer;
    }
  }

  &__item-stats {
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    div {
      text-align: center;
    }
  }

  &__link {
    &-title {
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 2px;
    }

    &-info {
      font-size: 11px;
      color: rgba($color-black, 0.4);
      margin-bottom: 10px;
    }

    &-link {
      display: flex;
      align-items: center;
    }

    &-status-circle {
      width: 8px;
      height: 8px;
      min-width: 8px;
      min-height: 8px;
      border-radius: 4px;
      flex-shrink: 0;
      flex-grow: 0;
      margin-right: 8px;

      &.-enabled {
        background: $color-green-seafoam;
      }

      &.-disabled {
        background: $color-red-candy;
      }
    }

    &-url {
      @extend %ellipsis;
      min-width: 0;
      flex: 1;
      font-size: 14px;
    }

    &-copy-btn {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      margin-left: 8px;
      color: rgba($color-black, 0.4);

      &:hover {
        cursor: pointer;
        color: rgba($color-black, 0.8);
      }
    }

    &-copy-btn-label {
      font-size: 12px;
      font-weight: 500;
    }
  }

  &__links {
    padding: 8px;
    width: 100%;

    &-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      margin: 0 0 11px;
    }

    &-desc {
      font-size: 12px;
      color: rgba($color-black, 0.64);
      margin: 0 0 16px;
    }

    &-status {
      font-size: 12px;
      color: rgba($color-black, 0.64);
      margin: 0 0 16px;
    }

    &-label {
      font-size: 12px;
      font-weight: 500;
      margin: 0 0 4px;
    }

    &-link {
      background: rgba($color-black, 0.05);
      border-radius: 4px;
      display: flex;
      align-items: center;
      height: 34px;
      padding: 0 10px;
      margin: 0 0 8px;

      &-status {
        width: 8px;
        height: 8px;
        border-radius: 4px;
        margin-right: 7px;

        &.-enabled {
          background: $color-green-seafoam;
        }

        &.-disabled {
          background: $color-red-candy;
        }
      }

      &-url {
        flex: 1;
        @extend %ellipsis;
        min-width: 0;
        font-size: 11px;
        color: rgba($color-black, 0.64);
        line-height: 1;
      }

      &-copy {
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      &-copy-text {
        font-size: 10px;
        color: rgba($color-black, 0.64);
      }
    }
  }

  &__panel {
    flex: 1;
    padding: 8px;
  }

  &__quotas {
    flex: 1;
    padding: 8px;

    &-item {
      margin: 0 0 16px;
    }
  }
}
