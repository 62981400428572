.filter-set-save {
  &__dialog {
    max-width: 420px;
  }

  &__content {
    padding: 20px;
  }

  &__msg {
    margin: 0 0 20px;
  }

  &__input {
    display: block;
    width: 100%;
    border: 1px solid rgba($color-black, 0.1);
    border-radius: 4px;
    height: 40px;
    padding: 0 8px;
    margin: 0 0 20px;
    outline: 0;
    font-size: 15px;

    &::placeholder {
      color: rgba($color-black, 0.26);
    }

    &:focus {
      border-color: $color-blue-clear;
    }
  }

  &__errors {
    margin: 0 0 20px;
  }

  &__btn {
    margin: 0 10px 0 0;
  }
}
