.channel-stats-tag-quota {
  &__title {
    font-size: 14px;
    margin: 0 0 8px;
  }

  &__bar {
    display: flex;
    align-items: stretch;
    height: 12px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.05);
    overflow: hidden;

    &-section {
      &.-responses {
        background: $color-green-seafoam;
      }
    }
  }
}
