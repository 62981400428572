.publication-editor {
  padding: 45px 30px 0;

  &__title {
    margin: 0 0 15px;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    line-height: 1;
  }

  &__subtitle {
    text-align: center;
    margin: 15px 0 45px;
  }

  &__types {
    display: flex;
    align-items: stretch;
  }

  &__type {
    display: flex;
    flex-basis: 100%;
    margin: 0 5px;
  }

  &__type-box {
    flex: 1;
    background: $color-white;
    border-radius: 4px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:hover {
      cursor: pointer;
      background: rgba($color-black, 0.02);
    }

    &.-selected {
      background: rgba($color-black, 0.05);
    }
  }

  &__type-name {
    font-weight: 500;
    margin: 10px 0 0;
  }

  &__type-info {
    font-size: 14px;
    text-align: center;
    line-height: 1.2;
  }

  &__type-footnote {
    text-align: center;
    font-size: 13px;
  }

  &__page {
    display: flex;
    align-items: flex-start;
    max-width: 1024px;
    margin: 0 auto;
  }

  &__nav {
    position: sticky;
    top: 80px;
    flex-basis: 232px;
    flex-shrink: 0;
    margin: 0 0 0 20px;
  }

  &__status-box {
    margin: 0 0 20px;
    background: $color-white;
    border-radius: 4px;
    padding: 20px 20px 10px;
  }

  &__status-title {
    font-size: 16px;
    font-weight: 500;
  }

  &__status-text {
    font-size: 14px;
    font-weight: 400;
    margin: 20px 0;
  }

  &__status-btn {
    display: block;
    margin: 0 0 10px;
  }

  &__main {
    flex-grow: 1;
  }

  &__section {
    background: $color-white;
    border-radius: 4px;
    margin: 0 0 20px;
    padding: 30px;
  }

  &__section-title {
    font-size: 19px;
    font-weight: 500;
    margin: 0 0 24px;
  }

  &__resource {
    margin: 0 0 20px;
  }
}
