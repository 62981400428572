.report-widget {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;

  &__header {
    display: flex;
    align-items: center;
    padding: 6px 13px 6px 18px;
    height: 52px;
  }

  &__body {
    border-top: 2px solid $color-portal-background;
    display: flex;
    flex-direction: column;
    padding: 15px 13px 8px;
  }

  &__number {
    flex-grow: 1;
    color: rgba($color-black, 0.24);
    font-size: 22px;
    font-weight: 500;
  }

  &__title {
    margin: 0 0 10px;
    text-align: center;
  }

  &__title-text {
    font-weight: 400;

    p {
      // markdown can put p elements in here
      font-size: inherit;
      margin: inherit;
    }
  }

  &__loop-selector {
    padding: 0 8px;
    margin: 7px 0 20px;
  }

  &__media-btn {
    display: flex;
    justify-content: center;
    margin-top: 4px;
  }

  &__sample-size {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 300;
    font-size: 12px;
    padding: 5px 10px;
    background-color: rgba(0, 0, 0, 0.05);
    margin: 8px;
    border-radius: 4px;
    border: 1px solid rgba($color-black, 0.1);

    b {
      font-weight: 400;
    }

    g-icon {
      margin: 0 0 0 5px;
    }

    div:last-child {
      display: flex;
      align-items: center;
      line-height: 1;
    }
  }

  &__actions {
    display: flex;
  }

  &__action {
    background: 0;
    border: 0;
    font-size: 24px;
    height: 40px;
    margin: 0;
    padding: 0;
    width: 40px;
    outline: 0;
    color: rgba($color-black, 0.24);

    &[disabled],
    &.-selected {
      color: $color-black;
    }

    &:hover:not([disabled]) {
      color: rgba($color-black, 0.5);
    }

    &.-rotate {
      &.gi-rotate-left {
        transform: rotate(270deg);
      }

      &.gi-rotate-right {
        transform: rotate(180deg);
      }
    }
  }

  &__action-menu {
    margin-top: 8px;
    display: block;
  }

  &__action-separator {
    height: 30px;
    border-left: 1px solid rgba($color-black, 0.05);
    margin: 5px 12px 0;

    &:first-child {
      display: none;
    }
  }

  &__hidden-question {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    width: 100%;
    max-width: 410px;
    margin: 0 auto 15px;
    background-color: #f5f6f5;
    border-radius: 4px;

    &-msg {
      flex-grow: 1;
    }
  }

  &__no-results {
    flex-grow: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;

    .gi {
      color: rgba($color-black, 0.24);
      font-size: 6rem;
      line-height: 1;
    }
  }

  &__unsupported {
    text-align: center;
    color: rgba($color-black, 0.4);
    font-weight: 500;
    font-size: 12px;
    max-width: 170px;
    margin: 50px auto;
  }

  &__loop-msg {
    flex-grow: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    padding: 140px 0;

    span {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.4);
    }
  }

  &__dimension {
    margin: 20px 0;
  }

  &__charts {
    display: flex;
    flex-direction: column;
    margin: 0 20px;
  }

  &__chart {
    flex-grow: 1;
    width: 100%;

    // remove white line between pie slices
    .c3-chart-arc path {
      stroke: transparent;
    }
  }

  &__legend {
    margin: 16px 0 0;

    &.-space-bottom {
      margin: 16px 0;
    }
  }

  &__table-tabs {
    display: flex;
    align-items: center;
    border-top: 1px solid rgba($color-black, 0.1);
    border-bottom: 1px solid rgba($color-black, 0.1);
    margin: 16px 0 0;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__table-tab {
    flex-basis: 100%;
    flex-grow: 1;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: rgba($color-black, 0.5);
    line-height: 36px;
    height: 36px;
    border-right: 1px solid rgba($color-black, 0.1);

    &:hover {
      cursor: pointer;
    }

    &:last-child {
      border-right: 0;
    }

    &.-active {
      color: rgba($color-black, 0.8);
    }
  }

  &__semantic-text {
    padding: 0 20px;
  }

  &__space {
    margin-top: 20px;
  }

  &__stats {
    text-align: center;
  }
}
