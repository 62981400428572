.image-prompt {
  margin-top: 15%;
  max-width: 330px;
}

.image-prompt-close {
  @extend %base-btn;

  color: rgba($color-black, 0.24);
  font-size: 24px;
  height: 40px;
  line-height: 1.6;
  position: absolute;
  right: 13px;
  text-align: center;
  top: 19px;
  width: 40px;

  &:hover {
    color: $color-black;
  }
}

.image-prompt-header {
  font-weight: 500;
  padding: 25px 50px 25px 25px;
}

.image-prompt-content {
  padding: 0 25px 15px;
  text-align: center;

  .gl-btn-primary,
  .gl-btn {
    margin: 0 5px 10px;
  }
}
