.view-editor {
  &__dialog {
    max-width: 800px;

    @media all and (max-width: 860px) {
      max-width: 90%;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
  }

  &__content {
    padding: 30px 24px;

    &.-bg {
      background: $color-portal-background;
      // replace tabs border above
      margin-top: -3px;
      border-top: 3px solid rgba($color-black, 0.03);
    }
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid rgba($color-black, 0.05);
  }

  &__header-title {
    flex-grow: 1;
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
    margin: 0;
  }

  &__header-group {
    display: flex;
    align-items: center;
    margin: 2px 0 0;

    &-micro-action {
      .micro-action__text {
        margin: 0;
      }

      &.-grey .micro-action__text {
        color: rgba($color-black, 0.4);
      }
    }
  }

  &__header-buttons {
    display: flex;
    align-items: center;

    g-btn {
      &:not(:last-child) {
        margin: 0 8px 0 0;
      }
    }
  }
}
